import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { SuccessToaster } from 'src/services/SweetAlerts';
import BasicInformation from './components/BasicInformation';
import Bestoffer from './components/Bestoffer';
import Listingsettings from './components/Listingsettings';
import Shipping from './components/Shipping';
import Wizardbar from './components/Wizardbar';
import './Onboardingmodal.css';

function Onboardingmodal() {
  let clientid = localStorage.getItem('cid');

  const [numberOfDots, setNumberOfDots] = useState(4);
  const [selectedDot, setSelectedDot] = useState(0);
  const [loading, isLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [storeName, setStoreName] = useState('');
  const [howDidYouKnow, setHowDidYouKnow] = useState('');
  const [whatsappNumber, setWhatsAppNumber] = useState('');
  const [timezone, setTimezone] = useState('');

  const [listingCheckBox, setListingCheckBox] = useState(false);
  const [delistingCheckbox, setDelistingCheckbox] = useState(false);
  const [crosslistingCheckBox, setCrosslistingCheckBox] = useState(false);
  const [accountingCheckBox, setAccountingCheckBox] = useState(false);

  const [services, setServices] = useState(['Listing']);

  const [priceOptions, setPriceOptions] = useState('');
  const [otherPriceOptions, setOtherPriceOptions] = useState('');

  const [chooseDollarPercentage, setChooseDollarPercentage] = useState('');
  const [incrCompPrice, setIncrCompPrice] = useState('');
  const [companyBlob, setCompanyBlob] = useState('');

  const [bestOffer, setBestOffer] = useState(true);
  const [isOfferAccept, setIsOfferAccept] = useState(false);
  const [offerAccept, setOfferAccept] = useState('');
  const [isOfferDecline, setIsOfferDecline] = useState(false);
  const [offerDecline, setOfferDecline] = useState('');

  const [ebayExists, setEbayExists] = useState(false);
  const [poshmarkExists, setPoshmarkExists] = useState(false);
  const [mercariExists, setMercariExists] = useState(false);
  const [users, setUsers] = useState([]);
  const [accountAdded, setAccountAdded] = useState(0);

  const [ebaySmartPricing, setEbaySmartPricing] = useState(false);
  const [mercariSmartPricing, setMercariSmartPricing] = useState(false);
  const [country, setCountry] = useState('');
  const [zipCode, setZipCode] = useState(0);
  const [location, setLocation] = useState('');
  const [mercariTags, setMercariTags] = useState([]);
  // Shipping and Return settings
  const [freeShipping, setFreeShipping] = useState(false);
  const [flatShippingRulesStatus, setFlatShippingRulesStatus] = useState(false);
  const [flatShippingRules, setFlatShippingRules] = useState([]);
  const [calculatedShipping, setCalculatedShipping] = useState(false);
  const [isReturnAccept, setIsReturnAccept] = useState(false);
  const [returnedWithin, setReturnedWithin] = useState('');
  const [refundAs, setRefundAs] = useState('');
  const [returnShipBy, setReturnShipBy] = useState('');
  // International Shipping settings
  const [incrByDomestic, setIncrByDomestic] = useState('');
  const [shipService, setShipService] = useState('');
  const [intlReturnAccepted, setIntlReturnAccepted] = useState(false);
  const [intlReturnedWithin, setIntlReturnedWithin] = useState('');
  const [intlRefundAs, setIntlRefundAs] = useState('');
  const [intlReturnShipBy, setIntlReturnShipBy] = useState('');
  const [useEbayGlobal, setUseEbayGlobal] = useState(false);
  const [handlingTime, setHandlingTime] = useState('');
  const [cost, setCost] = useState('');
  const [worldwideShip, setWorldwideShip] = useState(false);
  const [shipCountries, setShipCountries] = useState([]);
  const [enableEbayAutoPay, setEnableEbayAutoPay] = useState(true);
  const [allowPaypal, setAllowPaypal] = useState(false);
  const [paypalEmail, setPaypalEmail] = useState('');
  const [taxes, setTaxes] = useState('');
  const [paymentInstr, setPaymentInstr] = useState('');
  const [buyerReqs, setBuyerReqs] = useState('');

  const [cId, setcId] = useState('');
  useEffect(() => {
    Axios.get('/clientdetails/headerinfo').then(({ data }) => {
      setcId(data._id);
    });
  }, []);

  useEffect(() => {
    clientid = cId;
    Axios.get(`/listingSettings/basicInfo/${clientid}`)
      .then(({ data }) => {
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmailAddress(data.email);
        setPhoneNumber(data.phoneno);
        setWhatsAppNumber(data.whatsAppNo);
        setTimezone(data.timezone);
      })
      .catch((err) => console.log('error fetching client: ', err));

    Axios.get(`/listingSettings/${clientid}`)
      .then((res) => {
        let savedData = res.data.settings[0];

        setStoreName(savedData.storeName);
        setHowDidYouKnow(savedData.foundOutAboutUs);

        savedData = res.data.settings[0].listing[0];
        var compPriceSign = '';
        if (
          savedData.incrCompPrice[0].by !== undefined &&
          savedData.incrCompPrice[0].by === 'percent'
        ) {
          compPriceSign = '%';
        } else if (
          savedData.incrCompPrice[0].by !== undefined &&
          savedData.incrCompPrice[0].by === 'dollar'
        ) {
          compPriceSign = '$';
        }
        setChooseDollarPercentage(compPriceSign);
        if (savedData.incrCompPrice[0]) {
          setIncrCompPrice(savedData.incrCompPrice[0].value);
        }

        setServices(savedData.services);
        setPriceOptions(savedData.priceOptions);
        setOtherPriceOptions(savedData.otherPriceOptions);
        setCompanyBlob(savedData.companyBlurb);

        if (savedData.bestOffer[0]) {
          setBestOffer(savedData.bestOffer[0].enabled);
          setOfferAccept(savedData.bestOffer[0].acceptOfferOf);
          setIsOfferAccept(savedData.bestOffer[0].isOfferAccepted);
          setOfferDecline(savedData.bestOffer[0].declineOfferOf);
          setIsOfferDecline(savedData.bestOffer[0].isOfferDeclined);
        }

        // not used yet
        setEbaySmartPricing(savedData.ebay_smart);
        setMercariSmartPricing(savedData.mercari_smart);
        setCountry(savedData.country);
        setZipCode(savedData.zipCode);
        setLocation(savedData.location);
        setMercariTags(savedData.mercariTags);
        setBuyerReqs(savedData.buyerReqs);

        savedData = res.data.settings[0].shipping[0];
        setCalculatedShipping(savedData.calculatedShipping);
        setFreeShipping(savedData.freeShipping);
        if (savedData.flatShippingRules[0]) {
          setFlatShippingRulesStatus(
            savedData.flatShippingRules[0].flatShippingRulesStatus
          );
        }
        setFlatShippingRules(savedData.flatShippingRules);
        if (savedData.returns[0]) {
          setIsReturnAccept(savedData.returns[0].accepted);
          setReturnedWithin(savedData.returns[0].returnWithin);
          setRefundAs(savedData.returns[0].refundGivenAs);
          setReturnShipBy(savedData.returns[0].returnShipPaidBy);
        }
        savedData = res.data.settings[0].intlShipping[0];
        setIncrByDomestic(savedData.incrFromDomestic);
        setShipService(savedData.shippingService);
        if (savedData.returns[0]) {
          setIntlReturnAccepted(savedData.returns[0].accepted);
          setIntlReturnedWithin(savedData.returns[0].returnWithin);
          setIntlRefundAs(savedData.returns[0].refundGivenAs);
          setIntlReturnShipBy(savedData.returns[0].returnShipPaidBy);
        }
        if (savedData.globalShipping[0]) {
          setUseEbayGlobal(savedData.globalShipping[0].ebayGlobalShipEnabled);
          setHandlingTime(savedData.globalShipping[0].handlingTime);
          setCost(savedData.globalShipping[0].cost);
          setWorldwideShip(savedData.globalShipping[0].isWorldwide);
          setShipCountries(savedData.globalShipping[0].shipCountries);
        }
        savedData = res.data.settings[0].payment[0];
        setEnableEbayAutoPay(savedData.enableEbayAutoPay);
        setAllowPaypal(savedData.allowPaypal);
        setPaypalEmail(savedData.paypalEmail);
        setTaxes(savedData.taxes);
        setPaymentInstr(savedData.paymentInstr);
      })
      .catch((err) => console.log(err));
  }, [cId]);

  useEffect(() => {
    Axios.get('/password/getstatus')
      .then(({ data }) => {
        if (data.Ebay == true) {
          setEbayExists(true);
        }
        if (data.Poshmark == true) {
          setPoshmarkExists(true);
        }
        if (data.Mercari == true) {
          setMercariExists(true);
        }
      })
      .catch((err) => console.log(err));

    Axios.get('/password')
      .then(({ data }) => {
        if (data.passwords) setUsers(data.passwords);
        isLoading(false);
      })
      .catch((err) => {
        isLoading(false);
      });
  }, [accountAdded]);

  const addRule = () =>
    setFlatShippingRules((flatShippingRules) => [
      ...flatShippingRules,
      {
        itemType: flatShippingRules.itemType,
        itemPrice: flatShippingRules.itemPrice,
        itemShipService: flatShippingRules.itemShipService,
      },
    ]);
  const handleRulesChange = (e, index) => {
    let ruleSetCopy = [...flatShippingRules];
    let item = ruleSetCopy[index];
    let updatedItem = { ...item, [e.target.name]: e.target.value };
    ruleSetCopy[index] = updatedItem;
    setFlatShippingRules(ruleSetCopy);
  };

  // To enable/disable Wizardbar buttons
  const [currentModalInValid, setCurrentModalInValid] = useState(true);

  const [show, setShow] = useState(true);
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    clientid = cId;
    if (submit == true) {
      let priceOpt = priceOptions;
      if (otherPriceOptions.toLowerCase() === "i'll price") {
        priceOpt = null;
      }

      let sign = '';
      if (chooseDollarPercentage == '$') {
        sign = 'dollar';
      } else if (chooseDollarPercentage == '%') {
        sign = 'percent';
      }

      var ListSettingsObj = {
        services: services,
        priceOptions: priceOpt,
        otherPriceOptions: otherPriceOptions,
        incrCompPrice: {
          by: sign,
          value: incrCompPrice,
        },
        buyerReqs: buyerReqs,
        companyBlurb: companyBlob,
        bestOffer: {
          enabled: bestOffer,
          acceptOfferOf: offerAccept,
          isOfferAccepted: isOfferAccept,
          declineOfferOf: offerDecline,
          isOfferDeclined: isOfferDecline,
        },
        ebay_smart: ebaySmartPricing,
        mercari_smart: mercariSmartPricing,
        country: country,
        zipCode: zipCode,
        location: location,
        mercariTags: mercariTags,
      };

      var shippingSettingsObj = {
        calculatedShipping: calculatedShipping,
        freeShipping: freeShipping,
        flatShippingRulesStatus: flatShippingRulesStatus,
        flatShippingRules: flatShippingRules,
        returns: {
          accepted: isReturnAccept,
          returnWithin: returnedWithin,
          refundGivenAs: refundAs,
          returnShipPaidBy: returnShipBy,
        },
      };

      var intlShippingObj = {
        incrFromDomestic: incrByDomestic,
        shippingService: shipService,
        returns: {
          accepted: intlReturnAccepted,
          returnWithin: intlReturnedWithin,
          refundGivenAs: intlRefundAs,
          returnShipPaidBy: intlReturnShipBy,
        },
        globalShipping: {
          ebayGlobalShipEnabled: useEbayGlobal,
          handlingTime: handlingTime,
          cost: cost,
          isWorldwide: worldwideShip,
          shipCountries: shipCountries,
        },
      };

      var paymentProfObj = {
        enableEbayAutoPay: enableEbayAutoPay,
        allowPaypal: allowPaypal,
        paypalEmail: paypalEmail,
        taxes: taxes,
        paymentInstr: paymentInstr,
      };

      var configObj = {
        listing: ListSettingsObj,
        shipping: shippingSettingsObj,
        intlShipping: intlShippingObj,
        payment: paymentProfObj,
      };
      // storeName: storeName,
      // foundOutAboutUs: howDidYouKnow,
      var basicObj = {
        firstName: firstName,
        lastName: lastName,
        email: emailAddress,
        phoneno: phoneNumber,
        storeName: storeName,
        foundOutAboutUs: howDidYouKnow,
        whatsAppNo: whatsappNumber,
        timezone: timezone,
      };
      var finalObj = {
        basicSettings: basicObj,
        configSettings: configObj,
      };

      Axios.post(`/listingSettings/${clientid}`, finalObj)
        .then((res) => {
          if (
            res.data === 'Old Settings Updated' ||
            res.data === 'New Settings Created'
          ) {
            setShow(false);
            SuccessToaster.fire({ text: 'Settings Saved Succesfully!' });
          }
        })
        .catch((err) => console.log('Error: ', err));

      var notificationObj = {
        basicSettings: basicObj,
        accounts: users,
        clientid: clientid,
      };
      Axios.post('/listingSettings/emailnotifications', notificationObj)
        .then((res) => {
          console.log('Notifications sent');
        })
        .catch((err) => console.log(err));
    }
  }, [submit, cId]);

  return (
    <>
      <div className="onboarding_modal">
        <Modal size="lg" show={show} backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>Onboarding</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              {selectedDot == 0 ? (
                <BasicInformation
                  firstName={firstName}
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  emailAddress={emailAddress}
                  setEmailAddress={setEmailAddress}
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  storeName={storeName}
                  setStoreName={setStoreName}
                  howDidYouKnow={howDidYouKnow}
                  setHowDidYouKnow={setHowDidYouKnow}
                  whatsappNumber={whatsappNumber}
                  setWhatsAppNumber={setWhatsAppNumber}
                  timezone={timezone}
                  setTimezone={setTimezone}
                  currentModalInValid={currentModalInValid}
                  setCurrentModalInValid={setCurrentModalInValid}
                />
              ) : null}
              {selectedDot == 1 ? (
                <Listingsettings
                  listingCheckBox={listingCheckBox}
                  setListingCheckBox={setListingCheckBox}
                  delistingCheckbox={delistingCheckbox}
                  setDelistingCheckbox={setDelistingCheckbox}
                  crosslistingCheckBox={crosslistingCheckBox}
                  setCrosslistingCheckBox={setCrosslistingCheckBox}
                  accountingCheckBox={accountingCheckBox}
                  setAccountingCheckBox={setAccountingCheckBox}
                  companyBlob={companyBlob}
                  setCompanyBlob={setCompanyBlob}
                  chooseDollarPercentage={chooseDollarPercentage}
                  setChooseDollarPercentage={setChooseDollarPercentage}
                  incrCompPrice={incrCompPrice}
                  setIncrCompPrice={setIncrCompPrice}
                  currentModalInValid={currentModalInValid}
                  setCurrentModalInValid={setCurrentModalInValid}
                  services={services}
                  setServices={setServices}
                  priceOptions={priceOptions}
                  setPriceOptions={setPriceOptions}
                  otherPriceOptions={otherPriceOptions}
                  setOtherPriceOptions={setOtherPriceOptions}
                />
              ) : null}
              {selectedDot == 2 ? (
                <Bestoffer
                  bestOffer={bestOffer}
                  setBestOffer={setBestOffer}
                  isOfferAccept={isOfferAccept}
                  setIsOfferAccept={setIsOfferAccept}
                  offerAccept={offerAccept}
                  setOfferAccept={setOfferAccept}
                  isOfferDecline={isOfferDecline}
                  setIsOfferDecline={setIsOfferDecline}
                  offerDecline={offerDecline}
                  setOfferDecline={setOfferDecline}
                  currentModalInValid={currentModalInValid}
                  setCurrentModalInValid={setCurrentModalInValid}
                  country={country}
                  setCountry={setCountry}
                  zipCode={zipCode}
                  setZipCode={setZipCode}
                  location={location}
                  setLocation={setLocation}
                  enableEbayAutoPay={enableEbayAutoPay}
                  setEnableEbayAutoPay={setEnableEbayAutoPay}
                  mercariSmartPricing={mercariSmartPricing}
                  setMercariSmartPricing={setMercariSmartPricing}
                />
              ) : null}

              {selectedDot == 3 ? (
                <Shipping
                  currentModalInValid={currentModalInValid}
                  setCurrentModalInValid={setCurrentModalInValid}
                  freeShipping={freeShipping}
                  setFreeShipping={setFreeShipping}
                  flatShippingRulesStatus={flatShippingRulesStatus}
                  setFlatShippingRulesStatus={setFlatShippingRulesStatus}
                  flatShippingRules={flatShippingRules}
                  setFlatShippingRules={setFlatShippingRules}
                  calculatedShipping={calculatedShipping}
                  setCalculatedShipping={setCalculatedShipping}
                  isReturnAccept={isReturnAccept}
                  setIsReturnAccept={setIsReturnAccept}
                  returnedWithin={returnedWithin}
                  setReturnedWithin={setReturnedWithin}
                  refundAs={refundAs}
                  setRefundAs={setRefundAs}
                  returnShipBy={returnShipBy}
                  setReturnShipBy={setReturnShipBy}
                  addRule={addRule}
                  handleRulesChange={handleRulesChange}
                />
              ) : null}
            </div>

            <Wizardbar
              numberOfDots={numberOfDots}
              selectedDot={selectedDot}
              setSelectedDot={setSelectedDot}
              currentModalInValid={currentModalInValid}
              setCurrentModalInValid={setCurrentModalInValid}
              submit={submit}
              setSubmit={setSubmit}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Onboardingmodal;

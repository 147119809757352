import React, { Component } from 'react';
import './resetpasswordmin.css';
import Axios from '../../services/Axios';
import { Confirmer, ErrorToaster } from '../../services/SweetAlerts';

class resetpassword extends Component {
  constructor() {
    super();
    this.state = {
      oldp: '',
      newp: '',
      cnewp: '',
      hidden: true,
    };
  }

  logoutHandler = () => {
    localStorage.removeItem('token');
    window.open('/login', '_self');
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  reset = async (e) => {
    e.preventDefault();
    //const agentid = localStorage.getItem("agent");
    if (this.state.newp === this.state.cnewp) {
      Confirmer.fire({
        text: 'Press ok to confirm password change',
        showCloseButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          Axios.post('/resetpassword', {
            oldPassword: this.state.oldp,
            newPassword: this.state.newp,
            id: localStorage.getItem('cid'),
          })
            .then(({ response }) => {
              Confirmer.fire('Success, changes saved');
              this.setState({ oldp: '', newp: '', cnewp: '', hidden: true });
            })
            .catch(({ response }) => {
              let msg =
                response.data?.err ||
                'Something went wrong while resetting the password. Please, try again later.';
              ErrorToaster.fire(msg);
            });
        }
        return;
      });
    } else {
      ErrorToaster.fire('Password unmatch');
    }
  };

  show = (e) => {
    this.setState({ hidden: !this.state.hidden });
  };

  render() {
    //const { oldp, newp } = this.state;
    return (
      <div className="settingsIt">
        <form onSubmit={this.reset}>
          <div className="dataRow m-1">
            <label>
              {' '}
              Show/Hide: <input type="checkbox" onClick={this.show} />
            </label>
          </div>
          <div className="dataRow m-1">
            <i className="fa fa-user" aria-hidden="true"></i>
            <input
              type={this.state.hidden ? 'password' : 'text'}
              className="form-control dataFieldInput"
              placeholder="Old password"
              required
              name="oldp"
              onChange={this.handleChange}
              value={this.state.oldp}
            ></input>
          </div>
          <div className="dataRow m-1">
            <i className="fa fa-lock" aria-hidden="true"></i>
            <input
              type={this.state.hidden ? 'password' : 'text'}
              className="form-control dataFieldInput"
              placeholder="New Password"
              required
              name="newp"
              onChange={this.handleChange}
              value={this.state.newp}
            ></input>
          </div>
          <div className="dataRow m-1">
            <i className="fa fa-lock" aria-hidden="true"></i>
            <input
              type={this.state.hidden ? 'password' : 'text'}
              className="form-control dataFieldInput"
              placeholder="Confirm Password"
              required
              name="cnewp"
              onChange={this.handleChange}
              value={this.state.cnewp}
            ></input>
          </div>

          <button type="submit" className="btn btn-primary dataButton">
            Confirm Change
          </button>
        </form>
      </div>
    );
  }
}
export default resetpassword;

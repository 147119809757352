import React, { Component } from 'react';

import Axios from '../../services/Axios';
class AcceptUrl extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount = async (e) => {
    const url = this.props?.location?.search;

    await Axios.get('/ebay/acceptURL' + url, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return <div></div>;
  }
}

export default AcceptUrl;

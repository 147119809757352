let appEnv = process.env.NODE_ENV === 'test' ? 'test' : 'unknown';
let apiBaseURL;
let socketIoURI;
let assetsURL;
let assetsThumbnailURL;
let stripeKey;

if (typeof document === 'object') {
  if (document.location.host.endsWith('.githubpreview.dev')) {
    const apiHost = document.location.host.replace(
      /^(.*)-\d+\.(githubpreview\.dev)$/,
      '$1-8000.$2'
    );
    appEnv = 'localhost';
    apiBaseURL = `https://${apiHost}/api`;
    socketIoURI = `https://${apiHost}`;
    assetsURL = 'https://hammoq-dev-assets.storage.googleapis.com/assets/';
    assetsThumbnailURL =
      'https://hammoq-assets.storage.googleapis.com/assetsThumbnail/';
    stripeKey = 'pk_test_lC5HYE8HU7h3YulsALN8XO0Y00QcNkc02w';
  } else if (
    document.location.host.startsWith('localhost') ||
    document.location.host.startsWith('127.0.0.1')
  ) {
    appEnv = 'localhost';
    apiBaseURL = 'http://localhost:8000/api';
    socketIoURI = 'http://localhost:8000';
    assetsURL = 'https://hammoq-dev-assets.storage.googleapis.com/assets/';
    assetsThumbnailURL =
      'https://hammoq-assets.storage.googleapis.com/assetsThumbnail/';
    stripeKey = 'pk_test_lC5HYE8HU7h3YulsALN8XO0Y00QcNkc02w';
  } else if (document.location.host.startsWith('app')) {
    appEnv = 'prod';
    apiBaseURL = 'https://api.hammoq.com/api';
    socketIoURI = 'https://api.hammoq.com';
    assetsURL = 'https://hammoq-assets.storage.googleapis.com/assets/';
    assetsThumbnailURL =
      'https://hammoq-assets.storage.googleapis.com/assetsThumbnail/';
    stripeKey = 'pk_live_eHRa9qUfyNNMG2lgJVYOj9Js00hCyttVfu';
  } else if (document.location.host.startsWith('dev')) {
    appEnv = 'dev';
    apiBaseURL = 'https://devapi.hammoq.com/api';
    socketIoURI = 'https://devapi.hammoq.com';
    assetsURL = 'https://hammoq-dev-assets.storage.googleapis.com/assets/';
    assetsThumbnailURL =
      'https://hammoq-assets.storage.googleapis.com/assetsThumbnail/';
    stripeKey = 'pk_test_lC5HYE8HU7h3YulsALN8XO0Y00QcNkc02w';
  } else if (document.location.host.startsWith('stage')) {
    appEnv = 'staging';
    apiBaseURL = 'https://stageapi.hammoq.com/api';
    socketIoURI = 'https://stageapi.hammoq.com';
    assetsURL = 'https://hammoq-staging-assets.storage.googleapis.com/assets/';
    assetsThumbnailURL =
      'https://hammoq-assets.storage.googleapis.com/assetsThumbnail/';
    stripeKey = 'pk_test_lC5HYE8HU7h3YulsALN8XO0Y00QcNkc02w';
  }
}

if (process.env.REACT_APP_API_BASE_URL) {
  apiBaseURL = process.env.REACT_APP_API_BASE_URL;
}
if (process.env.REACT_APP_SOCKET_IO_URI) {
  socketIoURI = process.env.REACT_APP_SOCKET_IO_URI;
}
if (process.env.REACT_APP_ASSETS_STORAGE_URL) {
  assetsURL = process.env.REACT_APP_ASSETS_STORAGE_URL;
}
if (process.env.REACT_APP_ASSETS_STORAGE_URL) {
  assetsThumbnailURL = process.env.REACT_APP_ASSETS_THUMBNAIL_STORAGE_URL;
}
if (process.env.REACT_APP_STRIPE) {
  stripeKey = process.env.REACT_APP_STRIPE;
}

if (!apiBaseURL) {
  throw new Error(
    'Unable to detect environment for application and environment variable REACT_APP_API_BASE_URL was not set'
  );
}

if (!socketIoURI) {
  throw new Error(
    'Unable to detect environment for application and environment variable REACT_APP_SOCKET_IO_URI was not set'
  );
}

if (!assetsURL) {
  throw new Error(
    'Unable to detect environment for application and environment variable REACT_APP_ASSETS_STORAGE_URL was not set'
  );
}

if (!assetsThumbnailURL) {
  throw new Error(
    'Unable to detect environment for application and environment variable REACT_APP_ASSETS_THUMBNAIL_STORAGE_URL was not set'
  );
}

if (!stripeKey) {
  throw new Error(
    'Unable to detect environment for application and environment variable REACT_APP_STRIPE was not set'
  );
}

export const service_endpoint_urls = {
  appEnv,
  apiBaseURL,
  socketIoURI,
  assetsURL,
  assetsThumbnailURL,
  stripeKey,
};

import React from 'react';
import PropTypes from 'prop-types';
import ImageItem from './ImageItem';

const ImagesList = ({
  images,
  onImageClick,
  onImageRemove,
  assetsURL,
  handleChange,
  isRemoveDefaultImageBG,
}) => {
  return images.map((image, idx) => (
    <ImageItem
      image={image}
      index={idx}
      onImageClick={onImageClick}
      onImageRemove={onImageRemove}
      assetsURL={assetsURL}
      handleChange={handleChange}
      key={`image-${image.key}`}
      isRemoveDefaultImageBG={isRemoveDefaultImageBG}
    />
  ));
};

ImagesList.propTypes = {
  images: PropTypes.array.isRequired,
  onImageClick: PropTypes.func.isRequired,
  onImageRemove: PropTypes.func.isRequired,
  assetsURL: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  isRemoveDefaultImageBG: PropTypes.bool,
};

export default ImagesList;

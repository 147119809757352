import React, { Component } from 'react';
import './Login.css';
import hammock from '../../Components/images/logo.png';
import Axios from '../../services/Axios';
import { trigger } from '../../services/event';
import { connect } from 'react-redux';
import { Confirmer, ErrorToaster } from '../../services/SweetAlerts';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      isSubmitting: false,
      loginError: false,
    };
  }

  handleSubmit = async (e) => {
    try {
      e.preventDefault();
      this.setState({ isSubmitting: true });
      const { email, password } = this.state;
      if (email == '' || password == '') {
        Confirmer.fire({ text: 'Email and password is required' });
      }
      const res = await Axios.post('/signin', { email, password });
      localStorage.setItem('token', res.data.token);
      const session = res.data.session;
      trigger('fb:hammoqSession', { data: { session } });
      // this.props.history.push("/basic");
      window.open('/basic', '_self');
    } catch (err) {
      let errstr = err?.response?.data?.message || 'Invalid Credentials';
      ErrorToaster.fire(errstr);
      this.setState({ isSubmitting: false, loginError: true });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { email, password, isSubmitting, loginError } = this.state;
    return (
      <div className="customFont">
        <div className="row login-page">
          <div className="col-lg-6 m-auto">
            <div className="row">
              <img
                src={hammock}
                className="m-auto col-8"
                alt="Alt PlaceHolder"
              ></img>
            </div>

            <div className="col-lg-8 col-10 m-auto resp-col">
              <div
                className="card border-0 login-container shadow rounded-1 p-3"
                style={{ backgroundColor: '#102747' }}
              >
                <form onSubmit={this.handleSubmit}>
                  <h5
                    className="card-title text-center h3 py-2"
                    style={{ color: 'white' }}
                  >
                    Log in to your account
                  </h5>
                  {loginError && (
                    <div className="col d-flex justify-content-center">
                      <div className="row">
                        <i
                          className="fa fa-times-circle text-danger"
                          aria-hidden="true"
                          style={{ width: '50' }}
                        ></i>
                        <div style={{ color: 'red' }} className="ml-1">
                          <h5>Incorrect Email or Password</h5>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="input-group input-group-lg mt-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text bg-white border-0 rounded-1-left pr-1"
                        id="inputGroup-sizing-lg"
                      >
                        <div
                          className="fas fa-envelope"
                          aria-hidden="true"
                        ></div>
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control border-0 rounded-1-right bg-white"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-lg"
                      placeholder="Email"
                      value={email}
                      name="email"
                      onChange={this.handleChange}
                      required
                    ></input>
                  </div>
                  <div className="input-group input-group-lg mt-3">
                    <div className="input-group-prepend mb-2">
                      <span
                        className="input-group-text bg-white border-0 rounded-1-left pr-1"
                        id="inputGroup-sizing-lg"
                      >
                        <div className="fas fa-lock" aria-hidden="true"></div>
                      </span>
                    </div>
                    <input
                      type="password"
                      className="form-control border-0 rounded-1-right mb-2 align-middle pl-3 bg-white"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-lg"
                      placeholder="Password"
                      required
                      name="password"
                      onChange={this.handleChange}
                      value={password}
                    ></input>
                  </div>
                  {isSubmitting ? (
                    <button
                      className="btn bg-success text-white btn-block rounded-1 mt-3 py-2"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      />
                      LOGGING IN...
                    </button>
                  ) : (
                    <button
                      className="btn rounded-1 mt-3 py-2 btn-block"
                      style={{
                        backgroundColor: '#BE623E',
                        color: 'white',
                      }}
                      type="submit"
                    >
                      Log in
                    </button>
                  )}
                  <hr style={{ backgroundColor: 'whitesmoke' }} />
                </form>
                <a href="https://www.hammoq.com/demo">
                  <button
                    className="btn rounded-1 mt-3 btn-block "
                    style={{
                      backgroundColor: '#BE623E',
                      color: 'white',
                      fontSize: '1.2rem',
                    }}
                  >
                    Schedule a call
                  </button>
                </a>
                <a href="/forgotpassword">
                  <button
                    className="btn rounded-1 mt-3 btn-block"
                    style={{
                      backgroundColor: '#DABA4A',
                      color: 'white',
                      width: '100%',
                    }}
                  >
                    Forgot password
                  </button>
                </a>
                <div className="d-flex justify-content-center mb-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(Login);

import PouchDB from 'pouchdb-browser';
import PouchdbFind from 'pouchdb-find';
PouchDB.plugin(PouchdbFind);

class Database {
  static get database() {
    return new PouchDB('hammoq');
  }

  static async createIndices() {
    try {
      const response = await Database.database.createIndex({
        index: {
          fields: ['tempId'],
        },
      });
      return response;
    } catch (error) {
      console.log('Failed creating index', error.message);
    }
  }
  static async init() {
    try {
      const info = await Database.database.info();
      const indices = await Database.createIndices();
      return { indices, info };
    } catch (error) {
      alert(
        'Hammoq requires the use of Chrome v30, Firefox v29, Opera v21, Safari v5 or above to function. Please download a new browser now and come right back!'
      );
    }
  }
}

Database.init();
export default Database.database;

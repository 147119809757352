import React, { useState, useEffect } from 'react';
import './Calendly.css';
import Axios from '../../services/Axios';
import SweetAlerts from '../../services/SweetAlerts';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const Calendly = ({ showModel, closeModel }) => {
  const [link, setLink] = useState(null);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    Axios.get('/calendly/')
      .then(({ data }) => {
        if (data.linkMeeting[0].link.includes('https://on.sprintful.com/')) {
          setInterval(() => {
            removeDuplicateIframes();
          }, 1000);
          setLink(data.linkMeeting[0].link);
        } else {
          closeModel();
          SweetAlerts.ErrorToaster.fire({
            icon: 'error',
            title: "Can't schedule meeting at a time",
          });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const removeDuplicateIframes = () => {
    if (flag) return;
    const list = document.querySelectorAll('iframe');
    let link = '';
    for (let index = 0; index < list.length; index++) {
      if (link === list[index].src) list[index].remove();
      if (!link) link = list[index].src;
    }
    if (link.includes('on.sprintful.com')) setFlag(true);
  };

  return (
    <>
      <Dialog open={showModel} style={{ width: '100%' }}>
        <DialogTitle closeButton>
          <DialogTitle>Schedule your meeting</DialogTitle>
        </DialogTitle>
        <DialogContent>
          {link && (
            <div id="schedule_form">
              <div
                class="sprintful-inline-widget"
                data-url={link}
                style={{
                  minWidth: '320px',
                  height: '50vh',
                  backgroundColor: '#fff',
                }}
              ></div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="secondary" onClick={closeModel}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Calendly;

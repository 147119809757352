import React from 'react';
import SearchCartGridList from './GridList';

interface SearchCartGridProps {
  // properties
  productStatus: string;
  products: any[];
  crossListedProducts: any[];
  showCrossListProducts: boolean;
  search: string;
  rowsPerPage: number;

  // functions
  onDateSort: () => void;
  onEditProduct: (id: string) => void;
  onDuplicateProduct: (id: string) => void;
  onDeleteProduct: (id: string) => void;
}

const SearchCartGrid: React.FC<SearchCartGridProps> = ({
  products,
  crossListedProducts,
  productStatus,
  showCrossListProducts,
  search,
  rowsPerPage,

  onDateSort,
  onEditProduct,
  onDuplicateProduct,
  onDeleteProduct,
}) => {
  return (
    <SearchCartGridList
      products={products}
      crossListedProducts={crossListedProducts}
      showCrossListProducts={showCrossListProducts}
      productStatus={productStatus}
      search={search}
      rowsPerPage={rowsPerPage}
      onDateSort={onDateSort}
      onEditProduct={onEditProduct}
      onDuplicateProduct={onDuplicateProduct}
      onDeleteProduct={onDeleteProduct}
    />
  );
};

export default SearchCartGrid;

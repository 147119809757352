import initialState from './state';

const updateProducts = (state, action) => {
  const { products, currentPage, totalPage, rowsPerPage, prodStatus } = action;

  const miniProducts = products.map((p) => {
    if (p._id) return p._id.toString();
  });

  return {
    ...state,
    minimumProducts: [...state.minimumProducts, ...miniProducts],
    currentPage,
    totalPage,
    rowsPerPage,
    prodStatus,
  };
};

const setProducts = (state, action) => {
  const { products, currentPage, totalPage, rowsPerPage, prodStatus } = action;

  const miniProducts = products.map((p) => {
    if (p._id) return p._id.toString();
  });

  return {
    ...state,
    minimumProducts: miniProducts,
    currentPage,
    totalPage,
    rowsPerPage,
    prodStatus,
  };
};

const setBalance = (state, action) => {
  const { totalBalance } = action;
  return {
    ...state,
    totalBalance: totalBalance,
  };
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_UPLOADING_IMAGES':
      return { ...state, uploading: action.uploading };

    case 'SET_EXTENSION':
      return { ...state, extension: action.extension };

    case 'SET_FB_USER':
      return { ...state, fbUser: action.fbUser };

    case 'SET_MAINTENANCE_ALERT':
      return { ...state, maintenance: action.maintenance };
    case 'DELETE_MAINTENANCE_ALERT':
      return { ...state, maintenance: [] };
    case 'SET_PHOTOROOM':
      return { ...state, photoroom: action.photoroom };

    case 'UPDATE_PRODUCTS':
      return updateProducts(state, action);
    case 'SET_PRODUCTS':
      return setProducts(state, action);
    case 'UPDATE_TOTALBALANCE':
      return setBalance(state, action);

    default:
      return state;
  }
}

export default rootReducer;

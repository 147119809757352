import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import './ConnectShopifyAccount.css';
import Axios from '../../../../services/Axios';
import SweetAlerts from '../../../../services/SweetAlerts';
import OAuth2Login from 'react-simple-oauth2-login';

export default class ConnectShopifyAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      category: '',
      accountConnected: false,
      shopName: '',
    };
  }

  componentDidMount() {
    this.fetchAccount();
  }

  fetchAccount = async () => {
    this.setState({ loading: true });
    Axios.get('/shopify/oAuth')
      .then((response) => {
        const data = response.data;

        if (!data.shop) {
          this.setState({ accountConnected: false });
        } else {
          this.setState({ accountConnected: true, shopName: data.shop });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleChange = (e) => {
    const { shopName } = this.state;
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ shopName: value });
  };

  onSuccess = async (e) => {
    if (!e.code) {
      return false;
    }
    await Axios.put('/shopify/oAuth', { ...e })
      .then(({ data }) => {
        SweetAlerts.ErrorToaster.fire({
          icon: 'success',
          title: data.result,
        });
        this.setState({ accountConnected: true, shopName: data.shop });

        // add shopify market place password
        Axios.post('/password', {
          website: 'Shopify',
          username: this.state.shopName,
          password: ' ',
        }).catch(() => {
          SweetAlerts.ErrorToaster.fire({
            icon: 'error',
            title: 'Something error happen when save marketplace',
          });
        });
      })
      .catch((err) => {
        const errMessage = err?.response?.data?.errors
          ? err?.response?.data?.errors[0].message
          : err?.response?.data?.error?.message || 'Something went wrong';

        SweetAlerts.ErrorToaster.fire({
          icon: 'error',
          title: errMessage,
        });

        this.setState({ loading: false });
      });
  };

  onFailure = (err) => {
    const errMessage =
      err.message || 'Your Shopify authentication failed. Please try again.';
    SweetAlerts.ErrorToaster.fire({
      icon: 'error',
      title: errMessage,
    });
  };

  logout = async (data) => {
    this.setState({ loading: true });
    Axios.delete('/shopify/oAuth')
      .then(async (response) => {
        SweetAlerts.ErrorToaster.fire({
          icon: 'success',
          title: response.data.result,
        });
        this.setState({
          accountConnected: false,
          shopName: '',
          loading: false,
        });
        const marketplaceName = 'Shopify';
        await Axios.delete(
          `/password/deleteByMarketplaceName/${marketplaceName}`
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  registerWebhooks = async (data) => {
    // this.setState({  });
    Axios.post('/shopify/webhook/register')
      .then((response) => {
        SweetAlerts.ErrorToaster.fire({
          icon: 'success',
          title: 'Webhook registered successfully',
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  checkShopNameValid = () => {
    const { shopName } = this.state;
    var re = new RegExp('^[a-zA-Z0-9][a-zA-Z0-9-]*.myshopify.com');
    return re.test(shopName);
  };

  render = () => {
    const { shopName, accountConnected, loading } = this.state;
    return (
      <div className="shopifyAccount">
        <span className="title">Connect Your Shopify Shop</span>

        <div className="account">
          <span className="flex-1">
            {loading ? (
              'Fetching account'
            ) : accountConnected ? (
              shopName
            ) : (
              <TextField
                id="ShopName"
                className="textField mr-2"
                variant="outlined"
                label="Enter shop name"
                defaultValue=""
                name="shopName"
                value={shopName}
                onChange={this.handleChange}
                disabled={loading}
                placeholder="example.myshopify.com"
                helperText="E.g. example.myshopify.com"
              />
            )}
          </span>
          {loading ? (
            'loading..'
          ) : accountConnected ? (
            <div>
              <button
                className="shopify_button save mr-2"
                onClick={this.registerWebhooks}
              >
                register webhooks
              </button>
              <button className="shopify_button delete" onClick={this.logout}>
                Disconnect
              </button>
            </div>
          ) : (
            <div className="oAuthButton">
              {' '}
              {this.checkShopNameValid() ? (
                <OAuth2Login
                  authorizationUrl={`https://${shopName}/admin/oauth/authorize`}
                  responseType="code"
                  clientId="655b900c5c50212541c7dd84ce0a5055"
                  scope="read_products,write_products,write_orders,read_orders,write_inventory,read_inventory"
                  redirectUri={new URL(
                    '/accounts/ebayAccounts',
                    document.location
                  ).toString()}
                  onSuccess={this.onSuccess}
                  onFailure={this.onFailure}
                  className="btn btn-success"
                  disabled={this.checkShopNameValid()}
                />
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
    );
  };
}

//temp log for new commit

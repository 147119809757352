import React, { useEffect } from 'react';
import './Listingsettings.css';

const Listingsettings = (props) => {
  useEffect(() => {
    props.setCurrentModalInValid(false);
  }, []);

  return (
    <div className="listing_settings">
      <div className="listing_settings_header">Listing Settings</div>
      <div className="listing_settings_form">
        <div
          className="listing_settings_form_left"
          style={{ marginBottom: '10px' }}
        >
          <div className="listingsettings_question_one">
            <div
              style={{
                // border: "1px solid red",
                display: 'flex',
                flexFlow: 'flex-start',
                fontWeight: '500',
                fontSize: 'small',
              }}
            >
              Choose one or more services (can always change)
            </div>
            <div
              style={{
                // border: "1px solid red",
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '50%',
              }}
            >
              <div
                style={
                  {
                    //   border: "1px solid red",
                  }
                }
              >
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'flex-start',
                    fontSize: 'small',
                  }}
                >
                  <input
                    type="checkbox"
                    value="Listing"
                    style={{ verticalAlign: 'middle' }}
                    checked={props.services.find((i) => i === 'Listing')}
                    onChange={(e) => {
                      let list = props.services;
                      if (e.target.checked) {
                        list.push(e.target.value);
                        props.setServices(list);
                      } else {
                        let newList = list.filter((i) => i !== e.target.value);
                        props.setServices(newList);
                      }
                    }}
                  />
                  {/*{(event) =>
                  props.setListingCheckBox(
                    (listingCheckBox) => !listingCheckBox
                  )
                }*/}
                  <label style={{ marginLeft: '4px', verticalAlign: 'middle' }}>
                    {' '}
                    Listing{' '}
                  </label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'flex-start',
                    fontSize: 'small',
                  }}
                >
                  <input
                    type="checkbox"
                    value="Crosslisting"
                    checked={props.services.find((i) => i === 'Crosslisting')}
                    onChange={(e) => {
                      let list = props.services;
                      if (e.target.checked) {
                        list.push(e.target.value);
                        props.setServices(list);
                      } else {
                        let newList = list.filter((i) => i !== e.target.value);
                        props.setServices(newList);
                      }
                    }}
                  />
                  {/*(event) =>
                props.setCrosslistingCheckBox(
                  (crosslistingCheckBox) => !crosslistingCheckBox
                )
              }*/}
                  <label style={{ marginLeft: '4px', verticalAlign: 'middle' }}>
                    {' '}
                    Crosslisting{' '}
                  </label>
                </div>
              </div>
              <div
                style={
                  {
                    //   border: "1px solid yellow",
                  }
                }
              >
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'flex-start',
                    fontSize: 'small',
                  }}
                >
                  <input
                    type="checkbox"
                    value="Delisting"
                    checked={props.services.find((i) => i === 'Delisting')}
                    onChange={(e) => {
                      let list = props.services;
                      if (e.target.checked) {
                        list.push(e.target.value);
                        props.setServices(list);
                      } else {
                        let newList = list.filter((i) => i !== e.target.value);
                        props.setServices(newList);
                      }
                    }}
                  />
                  {/*(event) =>
                  props.setDelistingCheckbox(
                  (delistingCheckbox) => !delistingCheckbox
                  )*/}
                  <label style={{ marginLeft: '4px', verticalAlign: 'middle' }}>
                    {' '}
                    Delisting{' '}
                  </label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'flex-start',
                    fontSize: 'small',
                  }}
                >
                  <input
                    type="checkbox"
                    value="Accounting"
                    checked={props.services.find((i) => i === 'Accounting')}
                    onChange={(e) => {
                      let list = props.services;
                      if (e.target.checked) {
                        list.push(e.target.value);
                        props.setServices(list);
                      } else {
                        let newList = list.filter((i) => i !== e.target.value);
                        props.setServices(newList);
                      }
                    }}
                  />
                  {/*(event) =>
                  props.setAccountingCheckBox(
                  (accountingCheckBox) => !accountingCheckBox
                  )*/}
                  <label style={{ marginLeft: '4px', verticalAlign: 'middle' }}>
                    {' '}
                    Accounting{' '}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="listingsettings_question_two">
            <div
              style={{
                display: 'flex',
                flexFlow: 'flex-start',
                fontWeight: '500',
                fontSize: 'small',
              }}
            >
              Choose how you would like us to price
            </div>
            {/*<div onChange={(event)=> props.setPriceOptions(event)}>*/}
            <div
              style={{
                display: 'flex',
                flexFlow: 'flex-start',
                fontSize: 'small',
              }}
            >
              <input
                type="radio"
                name="priceOptions"
                value="High"
                checked={props.priceOptions === 'High'}
                onChange={(e) => {
                  props.setPriceOptions(e.target.value);
                }}
              />
              <label style={{ marginLeft: '4px', verticalAlign: 'middle' }}>
                High sold comp (Longer Hold)
              </label>
            </div>
            <div
              style={{
                display: 'flex',
                flexFlow: 'flex-start',
                fontSize: 'small',
              }}
            >
              <input
                type="radio"
                name="priceOptions"
                value="Mid"
                checked={props.priceOptions === 'Mid'}
                onChange={(e) => {
                  props.setPriceOptions(e.target.value);
                }}
              />
              <label style={{ marginLeft: '4px', verticalAlign: 'middle' }}>
                Medium sold comp (mid hold)
              </label>
            </div>
            <div
              style={{
                display: 'flex',
                flexFlow: 'flex-start',
                fontSize: 'small',
              }}
            >
              <input type="radio" name="priceOptions" value="other" />
              <label
                style={{
                  marginLeft: '4px',
                  marginRight: '4px',
                  verticalAlign: 'middle',
                }}
              >
                Other
              </label>
              <input
                type="text"
                style={{ border: '1px solid grey', borderRadius: '4px' }}
                value={props.otherPriceOptions}
                onChange={(e) => {
                  props.setOtherPriceOptions(e.target.value);
                }}
              ></input>
            </div>
            {/*</div>*/}
          </div>
        </div>
        <div className="listing_settings_form_right">
          <div
            style={{
              //   border: "1px solid blue",
              height: '49%',
              display: 'flex',
              flexFlow: 'flex-start',
            }}
          >
            <div
              style={{
                // border: "1px solid red",

                display: 'flex',
                flexFlow: 'flex-start',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div
                style={{
                  // border: "1px solid red",
                  display: 'flex',
                  flexFlow: 'flex-start',
                  fontWeight: '500',
                  fontSize: 'small',
                }}
              >
                Increase comp price by
              </div>
              <div>
                <select
                  value={props.chooseDollarPercentage}
                  onChange={(event) =>
                    props.setChooseDollarPercentage(event.target.value)
                  }
                >
                  <option value="$">$</option>
                  <option value="%">%</option>
                </select>
              </div>
              <div>
                <input
                  type="text"
                  style={{
                    width: '60px',
                  }}
                  value={props.incrCompPrice}
                  onChange={(event) =>
                    props.setIncrCompPrice(event.target.value)
                  }
                ></input>
              </div>
            </div>
          </div>
          <div
            style={{
              //   border: "1px solid blue",
              height: '49%',
              display: 'flex',
              flexFlow: 'flex-start',
              fontSize: 'small',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                // border: "1px solid red",
                display: 'flex',
                flexFlow: 'flex-start',
                fontWeight: '500',
                fontSize: 'small',
              }}
            >
              Bottom of Description (Company Blurb)
            </div>
            <div
              style={{
                // border: "1px solid red",
                display: 'flex',
                flexFlow: 'flex-start',
                width: '100%',
                height: '100%',
              }}
            >
              <input
                type="textbox"
                style={{
                  // border: "1px solid red",
                  display: 'flex',
                  flexFlow: 'flex-start',
                  width: '95%',
                  height: '90%',
                  fontSize: 'small',
                  border: '1px solid grey',
                  borderRadius: '4px',
                  marginTop: '5px',
                }}
                value={props.companyBlob}
                onChange={(event) => props.setCompanyBlob(event.target.value)}
              ></input>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listingsettings;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { on, off, trigger } from '../../services/event';
import { sendProcessingModel } from '../../services/methods';
import { Accordion, Button, ProgressBar, CloseButton } from 'react-bootstrap';
import {
  FaSync,
  FaHourglassStart,
  FaChevronUp,
  FaChevronDown,
} from 'react-icons/fa';
import './FileUpload.css';
import { nanoid } from 'nanoid';

const UploadFiles = () => {
  const product = useSelector((state) => state.product);
  const uploading = useSelector((state) => state.uploading);
  const dispatch = useDispatch();
  const [progressInfos, setProgressInfos] = useState([]);

  const setUploading = (value) => {
    dispatch({ type: 'SET_UPLOADING_IMAGES', uploading: value });
  };

  const setPhotoRoom = (value) => {
    dispatch({ type: 'SET_PHOTOROOM', photoroom: value });
  };

  const appendFile = ({ detail }) => {
    setUploading(true);
    const id = detail.data.id;
    let file = progressInfos.find((o) => o.id === id);
    if (!file) {
      file = {
        id,
        title: id.substring(id.indexOf('-') + 1, id.length),
        percentage: 0,
      };

      setProgressInfos([...progressInfos, { ...file }]);
    } else {
      file.progress = 0;
      file.error = undefined;

      setProgressInfos([...progressInfos]);
    }
  };
  const [open, setOpen] = useState(false);

  const removeFile = ({ detail }) => {
    const infos = progressInfos.filter((o) => o.id !== detail.data.id);
    setProgressInfos([...infos]);
    if (infos.length === 0) {
      setUploading(false);
    }
  };

  const completeFile = ({ detail }) => {
    if (detail && detail.data.isDefault) setPhotoRoom(true);
    return removeFile({ detail });
  };

  const setImageError = ({ detail }) => {
    const index = progressInfos.findIndex((o) => o.id === detail.data.id);
    if (index > -1) {
      progressInfos[index] = {
        ...progressInfos[index],
        error: 'Upload failed:',
      };
      setProgressInfos([...progressInfos]);
    }
  };

  const setImageProgress = ({ detail }) => {
    const id = detail.data.id;
    const index = progressInfos.findIndex((o) => o.id === id);

    // const modules =
    //   detail.percentage % 2 === 0 || detail.percentage === 99 ? true : false;
    if (index > -1 && detail.percentage < 100) {
      if (detail.percentage === progressInfos[index].percentage) {
        return true;
      }
      progressInfos[index] = {
        id,
        percentage: detail.percentage,
        title: id.substring(id.indexOf('-') + 1, id.length),
      };
      setProgressInfos([...progressInfos]);
    }
  };

  const removeImage = (id) => {
    trigger('image:remove', { data: { id } });
  };

  const refreshFile = (id) => {
    trigger('image:refresh', { id });
  };

  useEffect(() => {
    on('image:progress', setImageProgress);
    on('image:append', appendFile);
    on('image:restart', appendFile);
    on('image:complete', completeFile);
    on('image:remove', removeFile);
    on('image:error', setImageError);
    return () => {
      off('image:progress', setImageProgress);
      off('image:append', appendFile);
      off('image:restart', appendFile);
      off('image:complete', completeFile);
      off('image:remove', removeFile);
      off('image:error', setImageError);
    };
  }, [progressInfos]);

  return (
    <div>
      {uploading && progressInfos.length > 0 && (
        <div className="fab">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1" className="card ">
              <Accordion.Button as={Button} onClick={() => setOpen(!open)}>
                <h5 className="card-header bg-c-yellow" style={{ padding: 0 }}>
                  {open ? <FaChevronDown /> : <FaChevronUp />} Uploading
                  Images..{' '}
                </h5>
              </Accordion.Button>
              <Accordion.Body in={open}>
                <div className="card-body">
                  <div className="card-text">
                    <div className="row">
                      <div className="col-12">
                        {progressInfos.map((progressInfo, index) => (
                          <div key={nanoid(3)}>
                            <label
                              className={progressInfo.error ? 'error' : ''}
                              key={'label-' + index}
                            >
                              {progressInfo.error} {progressInfo.title}
                            </label>
                            <CloseButton
                              onClick={() => removeImage(progressInfo.id)}
                            />
                            {progressInfo.error && (
                              <Button
                                style={{
                                  backgroundColor: 'transparent',
                                  borderColor: 'transparent',
                                }}
                                className="refresh"
                              >
                                <FaSync
                                  onClick={() => refreshFile(progressInfo.id)}
                                />
                              </Button>
                            )}

                            {progressInfo.percentage ? (
                              <ProgressBar
                                animated
                                now={progressInfo.percentage}
                                label={`${progressInfo.percentage}%`}
                                key={'image-' + index}
                              />
                            ) : (
                              <Button
                                style={{
                                  backgroundColor: 'transparent',
                                  borderColor: 'transparent',
                                }}
                                className="refresh"
                              >
                                <FaHourglassStart />
                              </Button>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default UploadFiles;

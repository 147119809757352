import { get } from './Axios';

export const logoutHandler = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('isSubscribed');
  localStorage.removeItem('customerName');
  localStorage.removeItem('cid');
  localStorage.removeItem('condSetting');
  localStorage.removeItem('marketSetting');
  window.open('/login', '_self');
};

export const sendProcessingModel = async ({ productId, tempId }) => {
  const targetId = productId ? productId : localStorage.getItem(tempId);
  if (targetId) {
    localStorage.removeItem(`pending-${tempId}`);
    localStorage.removeItem(`${tempId}`);
    return get(`/product/process-model/${targetId}`);
  }
};

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './AddPassword.css';
import { trigger, on } from '../../services/event';
import Axios from '../../services/Axios';
import {
  Confirmer,
  ErrorToaster,
  SuccessToaster,
} from '../../services/SweetAlerts';

const validateExtension = () => {
  return window && window.HammoqExtension;
};
class Passwords extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      users: [],
      website: '',
      Ebay: true,
      Poshmark: true,
      Mercari: true,
      others: false,
      listOnFacebook: false,
      fbUser: null,
      HammoqExtension: undefined,
    };
    this.setFacebookUser.bind(this);
  }

  setFacebookUser = ({ detail }) => {
    const user = detail.user;
    this.setState({ fbUser: user });
  };

  componentDidMount = () => {
    const extenstion = validateExtension();
    if (extenstion) {
      this.setState({
        HammoqExtension: true,
      });
      trigger('fb:getUser', {});
      on('fb:setUser', this.setFacebookUser);
    }
    Axios.get('/password').then(({ data }) => {
      if (data.passwords) this.setState({ users: data.passwords });
    });
    // .catch(() => {
    //   ErrorToaster.fire({ text: 'Error in getting password.' });
    // });

    Axios.get('/password/getstatus').then(({ data }) => {
      this.setState({ Ebay: !data.Ebay });
      this.setState({ Poshmark: !data.Poshmark });
      this.setState({ Mercari: !data.Mercari });
    });
    // .catch(() => {
    //   ErrorToaster.fire({ text: 'Error in getitng password status' });
    // });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    if (value === 'Others') {
      this.setState({ others: true });
    } else {
      if (value === 'Ebay' || value === 'Poshmark' || value === 'Mercari') {
        this.setState({ others: false });
      }
      this.setState({ [name]: value });
    }
  };

  handleSubmit = (e) => {
    const { website, username, password } = this.state;
    e.preventDefault();

    if (website !== '' && username !== '' && password !== '') {
      Axios.post('/password', {
        website: website,
        username: username,
        password: password,
      })
        .then((response) => {
          this.setState({ [website]: false });
          this.setState(
            {
              users: response.data.passwords,
            },
            () => {
              this.setState({
                username: '',
                password: '',
                website: '',
              });
            }
          );
          SuccessToaster.fire({ text: 'Login has added' });
        })
        .catch((error) => {
          this.setState({ isSubmitting: true });
          const errorMessage = error?.response?.data?.message;
          ErrorToaster.fire({
            text: errorMessage || 'Error in adding marketplace.',
          });
        });
    } else {
      Confirmer.fire({ text: 'Fill up the details.' });
    }
  };

  handleDelete = async (id) => {
    try {
      Confirmer.fire({
        text: 'Are You Sure you want to delete?',
        showCloseButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Axios.delete(`/password/${id}`);
          this.componentDidMount();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const {
      website,
      username,
      password,
      users,
      Poshmark,
      Mercari,
      fbUser,
      listOnFacebook,
      others,
    } = this.state;
    return (
      <div>
        <div className="container">
          <div>
            <div>
              <h5 className="text-center mt-4 mb-4 heading">
                Log in Information
              </h5>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="card p-4 mb-4">
                  <h6 className="mb-3  sub-heading">
                    Please enter the information for the sites you want us to
                    automate for you
                  </h6>
                  <select
                    className="form-control body-text"
                    name="website"
                    value={website}
                    onChange={this.handleChange}
                  >
                    <option disabled>Please select a site to list on</option>
                    {/* {Ebay ? (<option defaultValue="Ebay">Ebay</option>) : null} */}
                    {Poshmark && this.state.HammoqExtension ? (
                      <option defaultValue="Poshmark">Poshmark</option>
                    ) : null}
                    {Mercari ? (
                      <option defaultValue="Mercari">Mercari</option>
                    ) : null}
                    {/* {HammoqExtension && fbUser ? (
                      <option defaultValue="Facebook">Facebook</option>
                    ) : null} */}
                    <option defaultValue="other">Others</option>
                  </select>
                  <br />
                  {listOnFacebook ? (
                    <div>
                      <div
                        style={{
                          width: '386px',
                          backgroundColor: 'transparent',
                          cursor: 'pointer',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        <table
                          className="uiGrid _51mz _5h0i _xvp _88va _5f0n"
                          style={{
                            borderRadius: '20px',
                            height: '40px',
                            backgroundColor: '#1877f2',
                            tableLayout: 'fixed',
                            width: '100%',
                            border: '0',
                            borderCollapse: 'collapse',
                            borderSpacing: '0',
                          }}
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody
                            style={{
                              display: 'table-row-group',
                              verticalAlign: 'middle',
                              borderColor: 'inherit',
                            }}
                          >
                            <tr className="_51mx">
                              <td className="_51m-" style={{ width: '40px' }}>
                                <div className="_5h0j">
                                  <span className="_5h0k">
                                    <img
                                      src="https://static.xx.fbcdn.net/rsrc.php/v3/y7/r/sKRYKszttLX.png"
                                      alt=""
                                      width="24"
                                      height="24"
                                    />
                                  </span>
                                </div>
                              </td>
                              <td className="_51m-">
                                <div className="_8jam">
                                  <div className="_5h0o _8kto">
                                    List as {fbUser.name}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div
                        className="switch-accounts"
                        style={{ padding: '5px' }}
                      >
                        Or{' '}
                        <a
                          href="https://facebook.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          switch accounts
                        </a>{' '}
                        then reload page
                      </div>
                    </div>
                  ) : (
                    <div>
                      {' '}
                      {others ? (
                        <>
                          <input
                            type="text"
                            placeholder="Website"
                            className="form-control mt-3"
                            name="website"
                            defaultValue=""
                            onChange={this.handleChange}
                          />
                          <br />
                        </>
                      ) : null}
                      <input
                        type="text"
                        placeholder="Username"
                        className="form-control mt-3 body-text"
                        name="username"
                        value={username}
                        onChange={this.handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        placeholder="Password"
                        className="form-control mt-3"
                        name="password"
                        value={password}
                        onChange={this.handleChange}
                      />
                    </div>
                  )}

                  <br />
                  <div style={{ display: 'flex' }}>
                    <button
                      className="btn btn-danger mt-3"
                      onClick={this.handleSubmit}
                    >
                      Add
                    </button>
                    <div style={{ flexGrow: '1' }} />
                    <Link to={`/accounts/ebayAccounts`}>
                      <button className="btn btn-danger ml-2 mt-3">
                        Go To API Accounts
                      </button>
                    </Link>
                    {/* <small className="ml-2">
                      Note: For “Hammoq Lite” only eBay, Poshmark, and Mercari
                      are supported
                    </small> */}
                  </div>
                </div>
                <p>
                  To add Ebay account via oAuth, Please{' '}
                  <Link to="/accounts/ebayAccounts">Go To API Accounts</Link>
                </p>
              </div>
              <div className="col-12 col-md-6">
                {users &&
                  users.map((user, idx) => {
                    return (
                      <div className="card  p-4 mb-4" key={idx}>
                        <h6 className="sub-heading">{user.website}</h6>
                        <input
                          type="text"
                          placeholder="Ebay username"
                          className="form-control  mt-3"
                          name="username"
                          value={user.username}
                          disabled
                        />
                        <br />
                        <input
                          type="text"
                          placeholder="Ebay Password"
                          className="form-control mt-3"
                          value={user.password}
                          name="password"
                          disabled
                        />
                        <br />
                        <div>
                          <Link to={`/editpasswords/${user.website}`}>
                            <button className="btn btn-success body-text custom-edit-btn mt-3 btn-sm">
                              Edit
                            </button>
                          </Link>
                          <button
                            className="btn btn-danger body-text custom-edit-btn mt-3 ml-3 btn-sm"
                            onClick={() => {
                              this.handleDelete(user._id);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div style={{ marginBottom: '60px' }}></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Passwords;

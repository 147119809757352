import { AdvanceListing } from '@hammoq-inc/hammoq-recycledcomponents-package';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Axios, {
  assetsThumbnailURL,
  assetsURL,
  baseURL,
  get,
  post,
  put,
} from '../../services/Axios';
import SweetAlerts from '../../services/SweetAlerts';

const EditForm = ({ match }) => {
  const history = useHistory();
  const { id } = match.params;
  const { minimumProducts, currentPage, totalPage, rowsPerPage, prodStatus } =
    useSelector((state) => state);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const goToPreviousPath = () => {
    history.goBack();
  };

  const getProducts = async (page, size, search, prodStatus) => {
    const response = await Axios.get(`/product/type/${prodStatus}`, {
      params: {
        page,
        size,
        search,
      },
    });

    return response.data.data.filter((status) => status.status !== false);
  };

  const unRecognizedIdHandler = async (currentId, currentProduct) => {
    try {
      const newProducts = await getProducts(
        currentPage ? currentPage + 1 : 1,
        rowsPerPage || 500,
        '',
        prodStatus !== '' ? prodStatus : currentProduct.prodStatus
      );

      if (newProducts.length === 0) {
        SweetAlerts.ErrorToaster.fire('There is no more products');
        return;
      }

      dispatch({
        type: 'UPDATE_PRODUCTS',
        products: newProducts,
        currentPage: currentPage ? currentPage + 1 : 1,
        totalPage: totalPage,
        rowsPerPage: rowsPerPage || 500,
        prodStatus: prodStatus !== '' ? prodStatus : currentProduct.prodStatus,
      });

      const updatedIndex = newProducts.findIndex(
        (n) => n._id.toString() === currentId
      );

      let id = '';

      if (updatedIndex !== -1) {
        const newProduct = newProducts[updatedIndex + 1];

        id = newProduct._id.toString();
      } else {
        const newProduct = newProducts[0];
        id = newProduct._id.toString();
      }

      const route = '/edit/' + id;
      history.push(route);
    } catch (error) {
      SweetAlerts.ErrorToaster.fire('Something went wrong.');
      console.log('error', error);
      return;
    }
  };

  const nextHandler = (product) => {
    const { id: currentId } = match.params;

    const currentIndex = minimumProducts.findIndex((pId) => pId === currentId);

    // check the edge case
    if (currentIndex === -1 || currentIndex === minimumProducts.length - 1) {
      return unRecognizedIdHandler(currentId, product);
    }

    // get the next one
    else history.push(`/edit/${minimumProducts[currentIndex + 1]}`);
  };

  const getUpdatedBalance = async () => {
    //after deduct charges for marketplace  update balance in header
    await Axios.get('/clientdetails/headerinfo')
      .then(({ data }) => {
        if (data.balance) {
          dispatch({ type: 'UPDATE_TOTALBALANCE', totalBalance: data.balance });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <IconButton aria-label="close" onClick={goToPreviousPath}>
          {/*RETURN BACK TO GOBACK FUNCTION.*/}
          <KeyboardBackspaceIcon />
        </IconButton>
      </div>
      {!loading && (
        <AdvanceListing
          assetsURL={assetsURL}
          assetsThumbnailURL={assetsThumbnailURL}
          baseURL={baseURL}
          Axios={Axios}
          get={get}
          post={post}
          put={put}
          SweetAlerts={SweetAlerts}
          productid={id}
          isTemplate={false}
          clientid={localStorage.getItem('cid')}
          onNext={nextHandler}
          getUpdatedData={getUpdatedBalance}
          hasClientSaveOptions={true}
          canApproveListing={true}
        />
      )}
    </>
  );
};

export default EditForm;

import React, { useState, useEffect } from 'react';
import './MessagesContentArea.css';
import MessagesDisplayArea from './MessagesDisplayArea';
import MessagesTextArea from './MessagesTextArea';

const MessagesContentArea = (props) => {
  const [refreshDisplayArea, setRefreshDisplayArea] = useState(false);
  const [clientId, setClientId] = useState('');
  const [agentsAllocatedToClient, setAgentsAllocatedToClient] = useState([]);
  const [currentImage, setCurrentImage] = useState('');

  const refresh = () => {
    setRefreshDisplayArea(true);
  };

  useEffect(() => {
    if (currentImage) {
      props.setCurrentImage(currentImage);
      setCurrentImage('');
    }
  }, [currentImage]);

  return (
    <div className="messages-content-area">
      <MessagesDisplayArea
        refreshDisplayArea={refreshDisplayArea}
        setRefreshDisplayArea={setRefreshDisplayArea}
        clientId={clientId}
        setClientId={setClientId}
        agentsAllocatedToClient={agentsAllocatedToClient}
        setAgentsAllocatedToClient={setAgentsAllocatedToClient}
        setCurrentImage={setCurrentImage}
      />
      <MessagesTextArea
        setRefreshDisplayArea={setRefreshDisplayArea}
        clientId={clientId}
        agentsAllocatedToClient={agentsAllocatedToClient}
        refresh={refresh}
      />
    </div>
  );
};

export default MessagesContentArea;

const CANCELLATION_REASONS = {
  LOW_QUALITY: 'Quality of listings are too low',
  HIGH_PRICE: 'Service price is too high',
  STOPPED_LISTING: 'Stopped reselling',
  SERVICE_NOT_GOOD: "Don't need listing and crosslisting services",
  ANOTHER_SOLUTION_FOUND: 'Found another solution that works better for me',
  MISSING_FEATURES: "Doesn't have the features I want",
};

const initialState = {
  uplading: false,
  extension: !!(window && window.HammoqExtension),
  fbUser: undefined,
  photoroom: false,
  CANCELLATION_REASONS,
  maintenance: [],

  minimumProducts: [],
  currentPage: 0,
  totalPage: 0,
  rowsPerPage: 0,
  prodStatus: '',
  totalBalance: 0,
};

export default initialState;

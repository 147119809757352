import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const BulkArea = ({
  onBulkUpload,
  templateId,
  templates,
  handleChangesTemplate,
  isRemoveDefaultImageBG,
  onBackgroundRemove,
}) => {
  const photoroom = useSelector((state) => state.photoroom);
  return (
    <div className="col-12 px-1">
      <div className="input-group mb-3">
        <div className="custom-file">
          <input
            id="bulk"
            type="file"
            accept="image/*"
            className="custom-file-input"
            multiple
            onChange={(e) => {
              onBulkUpload(e);
            }}
          ></input>
          <label className="custom-file-label" htmlFor="inputGroupFile01">
            Bulk Upload Images
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-12 px-1 ml-3">
          <select
            value={templateId}
            id="chooseTemplate"
            onChange={handleChangesTemplate}
          >
            {/* className="form-control" */}
            <option value="">Choose Template</option>
            {templates &&
              templates.map((template) => {
                return (
                  <option value={template._id} key={template._id}>
                    {template.name}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      {photoroom && (
        <div className="row_rightSide4">
          <div className="marketPlacesTitle">Advanced Options:</div>
          <div className="marketPlacesEntry">
            <div className="form-check" key="test">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isRemoveDefaultImageBG}
                onChange={(e) => {
                  onBackgroundRemove(e);
                }}
              />
              <label className="form-check-label">
                Remove default image background
              </label>
            </div>
          </div>
          <div
            className="custom-file-container"
            style={{ textAlign: 'center' }}
          >
            <a href="https://photoroom.com" target="_blank">
              <h6 style={{ color: '#13156D', display: 'inline-block' }}>
                Background Removed by
              </h6>
              <img
                src={photoroom}
                style={{ height: '40px', display: 'inline-block' }}
              ></img>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

BulkArea.propTypes = {
  onBulkUpload: PropTypes.func.isRequired,
  isRemoveDefaultImageBG: PropTypes.bool,
  onBackgroundRemove: PropTypes.func,
};

export default BulkArea;

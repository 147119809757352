import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import ReCAPTCHA from 'react-google-recaptcha';
import { get, post } from '../../services/Axios';
import { ErrorToaster, SuccessToaster } from '../../services/SweetAlerts';
import Modal from 'react-bootstrap/Modal';

export default function AlertDialog({ open, handleClose }) {
  const googleRecaptchaKey = '6LeB67kaAAAAAD6pFtXtvzLRqE6VXuyT6uCfIzAq';
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [paymentMethodId, setPaymentMethodId] = useState(undefined);
  const [savedCards, setSavedCards] = useState([]);
  useEffect(() => {
    getSavedCards();
  }, []);

  const handleModalClose = () => {
    setAmount(null);
    setCaptchaValue(null);
    handleClose();
  };

  const callback = (value) => {
    setCaptchaValue(value);
  };

  const getSavedCards = async () => {
    try {
      let response = await get('/subscription/payment-methods');
      if (!response.error) {
        setSavedCards(response.data);
      }
    } catch (err) {
      ErrorToaster.fire({ title: 'Error in fetching card details...' });
    }
  };

  const updatePayment = async (amount) => {
    setLoading(true);
    let body = {
      amount: amount,
      paymentMethodId,
    };

    const response = await post('/payment/payment', body);
    setLoading(false);
    if (response.error) {
      ErrorToaster.fire({ title: response.error });
    } else {
      SuccessToaster.fire({ title: response.msg });
      window.location.reload();
    }
  };

  // specifying verify callback
  const handleUpdatePayment = () => {
    updatePayment(amount);
  };

  return (
    <div id="test" key="PaymentAlert-02">
      <Modal show={open} onHide={handleModalClose} size="lg" centered>
        <Modal.Header id="alert-dialog-title">
          Add amount to your Hammoq account
        </Modal.Header>
        <Modal.Body>
          <div>
            {savedCards.length > 0 &&
              savedCards.map((card) => (
                <div key="Payment-Dialog-01" className="row">
                  <div className="col-2">
                    <input
                      type="radio"
                      name="stripeCard"
                      value={card.id}
                      onChange={(e) => {
                        setPaymentMethodId(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-8">
                    <p>
                      {card.card.brand} **** **** **** {card.card.last4}{' '}
                      {card.card.exp_month}/{String(card.card.exp_year)[2]}
                      {String(card.card.exp_year)[3]}
                    </p>
                  </div>
                </div>
              ))}
          </div>
          <div id="alert-dialog-description">
            <small>Please choose an amount (minimum 100$) to continue:</small>
            <div className="row mt-2">
              <div className="col">
                <Button
                  variant="outlined"
                  color={amount == 100 ? 'secondary' : 'primary'}
                  onClick={() => setAmount(100)}
                >
                  $ 100.00
                </Button>
              </div>
              <div className="col">
                <Button
                  variant="outlined"
                  color={amount == 500 ? 'secondary' : 'primary'}
                  onClick={() => setAmount(500)}
                >
                  $ 500.00
                </Button>
              </div>
              <div className="col">
                <Input
                  variant="outlined"
                  onChange={(event) => {
                    setAmount(parseInt(event.target.value, 10));
                  }}
                  type="number"
                  placeholder="Enter Amount"
                ></Input>
              </div>
            </div>
            <div>
              <small style={{ color: amount < 100 ? 'red' : 'black' }}>
                Minimum amount you can add is 100$
              </small>
            </div>
            {amount != null && (
              <div className="row d-flex justify-content-center">
                {' '}
                <ReCAPTCHA sitekey={googleRecaptchaKey} onChange={callback} />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModalClose} color="primary">
            Close
          </Button>
          <Button
            onClick={handleUpdatePayment}
            color="primary"
            disabled={captchaValue == null || amount < 100 ? true : loading}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

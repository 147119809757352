import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Rules from './rules.js';

const Shipping = (props) => {
  const openLink = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="account_details">
      <div className="account_details_header" style={{ marginBottom: '10px' }}>
        Shipping - Domestic
      </div>

      <div>
        How would you like shipping?*
        <button
          type="button"
          class="btn btn-link"
          onClick={() => openLink('https://bit.ly/3f10ktg')}
        >
          Click here for more details
        </button>
      </div>

      <div>
        <label>
          <input
            type="checkbox"
            value="Free"
            checked={props.freeShipping}
            onChange={(e) => {
              props.setFreeShipping(e.target.checked);
            }}
          ></input>
          {' Free Shipping'}
        </label>
      </div>
      <label>
        <input
          type="checkbox"
          value="Flat"
          checked={props.flatShippingRulesStatus}
          onChange={(e) => {
            props.setFlatShippingRulesStatus(e.target.checked);
          }}
        ></input>
        {' Flat Shipping'}
      </label>

      <div className="r2_q4_inner_align">
        <div className="rules__arrange"> Rules </div>
        {props.flatShippingRules &&
          props.flatShippingRules.map((item, index) => (
            <Rules
              onChange={(e) => props.handleRulesChange(e, index)}
              item={props.flatShippingRules[index]}
            />
          ))}
        <label className="save_btn_align">
          <input
            type="button"
            value="Add Rule"
            onClick={(e) => props.addRule()}
            className="save_button"
          ></input>
        </label>
      </div>
      <br />
      <label>
        <input
          type="checkbox"
          value="Calculated"
          checked={props.calculatedShipping}
          onChange={(e) => {
            props.setCalculatedShipping(e.target.checked);
          }}
        ></input>
        {
          ' Calculated Shipping (You weigh the item and supply weight & dimensions. If not given, we will default to flat or free)'
        }
      </label>
      <br />
      <label>
        <input
          type="checkbox"
          value="option1"
          checked={props.isReturnAccept}
          onChange={(e) => {
            props.setIsReturnAccept(e.target.checked);
          }}
        ></input>
        {' Returns Accepted'}
      </label>
      <Row>
        <Col>
          <label>
            Items must be returned with in
            <select
              disabled={!props.isReturnAccept}
              className="input_tag_dom"
              value={props.returnedWithin}
              onChange={(e) => {
                props.setReturnedWithin(e.target.value);
              }}
            >
              <option value=""></option>
              <option value={14}>14 Days</option>
              <option value={30}>30 Days</option>
              <option value={60}>60 Days</option>
            </select>
          </label>
        </Col>
        <Col>
          <label>
            Refund must be given as
            <select
              disabled={!props.isReturnAccept}
              className="input_tag_dom"
              value={props.refundAs}
              onChange={(e) => {
                props.setRefundAs(e.target.value);
              }}
            >
              <option value=""></option>
              <option value="MoneyBack">Money Back</option>
              <option value="MoneyBackOrReplacement">
                Money Back Or Replacement
              </option>
            </select>
          </label>
        </Col>
        <Col>
          <label>
            Return shipping will be paid by
            <select
              disabled={!props.isReturnAccept}
              className="input_tag_dom"
              value={props.returnShipBy}
              onChange={(e) => {
                props.setReturnShipBy(e.target.value);
              }}
            >
              <option value=""></option>
              <option value="Buyer">Buyer</option>
              <option value="Seller">Seller</option>
            </select>
          </label>
        </Col>
      </Row>
    </div>
  );
};

export default Shipping;

import React, { useState } from 'react';
import socket from '../services/socket';
import { useHistory } from 'react-router-dom';
export const ClientMessagesContext = React.createContext({
  contextUnreadMessagesCount: null,
});

const ClientMessagesProvider = (props) => {
  const [contextUnreadMessagesCount, setContextUnreadMessagesCount] =
    useState(0);
  const [contextClientId, setContextClientId] = useState('');

  return (
    <ClientMessagesContext.Provider
      value={{
        contextUnreadMessagesCount,
        setContextClientId,
      }}
    >
      {props.children}
    </ClientMessagesContext.Provider>
  );
};

export default ClientMessagesProvider;

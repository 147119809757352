import React from 'react';
import PropTypes from 'prop-types';

const isEmpty = (value) => {
  if (value === null || value === '' || value === undefined) return true;
  return false;
};

const StatusColumn = ({ product }) => {
  const notListedToAnyOtherMarketplace = (product) => {
    if (
      !isEmpty(product.ebay?.url) ||
      !isEmpty(product.poshmark?.url) ||
      !isEmpty(product.mercari?.url) ||
      !isEmpty(product.facebook?.url) ||
      !isEmpty(product.delist?.url) ||
      !isEmpty(product.shopify?.url) ||
      (product.others && product.others.length > 0)
    )
      return false;

    return true;
  };

  if (
    product.prodStatus === 'inventory' &&
    notListedToAnyOtherMarketplace(product)
  ) {
    return (
      <div>
        <small>Listed</small>
      </div>
    );
  }

  return (
    <>
      {product.ebay && product.ebay.check ? (
        <div>
          <small>
            {!product.ebay.url || product.ebay.url === '' ? (
              <p className="text-danger">Ebay - Not yet listed</p>
            ) : product.ebay.url === 'd' ? (
              'Ebay - Listing under review'
            ) : (
              <a href={product.ebay.url} target="_blank" rel="noreferrer">
                Ebay - Listed
              </a>
            )}
          </small>
        </div>
      ) : null}
      {product.poshmark && product.poshmark.check ? (
        <div>
          <small>
            {product.poshmark.listed === false &&
            product.poshmark.check === true ? (
              <p className="text-danger">Poshmark - Not yet listed</p>
            ) : product.poshmark.listed === true ? (
              <a href={product.poshmark.url} target="_blank" rel="noreferrer">
                {' '}
                Poshmark - Listed
              </a>
            ) : (
              <p className="text-danger">Poshmark - Not yet listed</p>
            )}
          </small>
        </div>
      ) : null}
      {product.mercari && product.mercari.check ? (
        <div>
          <small>
            {!product.mercari.url || product.mercari.url === '' ? (
              <p className="text-danger">Mercari - Not yet listed</p>
            ) : product.mercari.url === 'd' ? (
              'Mercari - Listing under review'
            ) : (
              <a href={product.mercari.url} target="_blank" rel="noreferrer">
                {' '}
                Mercari - Listed
              </a>
            )}
          </small>
        </div>
      ) : null}
      {product.delist && product.delist.check ? (
        <div>
          <small>
            {' '}
            {product.delist.check === true
              ? 'Delist - We will delist the product once it is sold'
              : ''}
          </small>
        </div>
      ) : null}

      {product.facebook && product.facebook.check ? (
        <div>
          <small>
            {!product.facebook.url ? (
              <p className="text-danger">Facebook - Not yet listed</p>
            ) : product.facebook.url === 'd' ? (
              'Facebook - Listing under review'
            ) : (
              <a href={product.facebook.url} target="_blank" rel="noreferrer">
                Facebook - Listed
              </a>
            )}
          </small>
        </div>
      ) : null}
      {/* shopify */}
      {product.shopify && product.shopify.check ? (
        <div>
          <small>
            {!product.shopify.listed ? (
              <p className="text-danger">Shopify - Not yet listed</p>
            ) : product.shopify.url === 'd' ? (
              'Shopify - Listing under review'
            ) : (
              <a href={product.shopify.url} target="_blank" rel="noreferrer">
                Shopify - Listed
              </a>
            )}
          </small>
        </div>
      ) : null}
      {product.others
        ? JSON.parse(product.others).map((items, idx) => {
            if (items.status) {
              return (
                <div key={idx}>
                  <small>
                    {items.url === '' ? (
                      <p className="text-danger">
                        {items.name} - Not yet listed
                      </p>
                    ) : (
                      <a href={items.url} target="_blank" rel="noreferrer">
                        {' '}
                        {items.name} - Listed
                      </a>
                    )}
                  </small>
                </div>
              );
            }
          })
        : null}
    </>
  );
};

StatusColumn.propTypes = {
  product: PropTypes.object.isRequired,
};

export default StatusColumn;

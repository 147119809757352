import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Login = () => {
  const history = useHistory();

  const navigateTo = (to) => {
    history.push(to);
  };

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('isSubscribed');
    localStorage.removeItem('customerName');
    localStorage.removeItem('cid');
    localStorage.removeItem('condSetting');
    localStorage.removeItem('marketSetting');
    navigateTo('/signin');
  }, []);
  return <div></div>;
};

export default Login;

import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import './Bestoffer.css';

const Bestoffer = (props) => {
  useEffect(() => {
    props.setCurrentModalInValid(false);
    if ((props.country && props.zipCode && props.location) == '') {
      props.setCurrentModalInValid(true);
    } else {
      props.setCurrentModalInValid(false);
    }
  }, [props.country, props.zipCode, props.location]);

  return (
    <div className="Best_offer">
      <div className="Best_offer_header">Listing Settings </div>
      <div className="Best_offer_form">
        <Row>
          <div>
            Enable best offer{' '}
            <label class="switch">
              <input
                type="checkbox"
                checked={props.bestOffer}
                onChange={(e) => {
                  props.setBestOffer(!props.bestOffer);
                  if (!props.bestOffer === false) {
                    props.setOfferAccept('');
                    props.setOfferDecline('');
                    props.setIsOfferAccept(false);
                    props.setIsOfferDecline(false);
                  }
                }}
              />
              <span class="slider round"></span>
            </label>
          </div>
          <Row>
            {props.bestOffer ? (
              <div style={{ marginTop: '20px', marginLeft: '5px' }}>
                <input
                  type="checkbox"
                  checked={props.isOfferAccept}
                  onChange={(e) => {
                    props.setIsOfferAccept(e.target.checked);
                    if (!e.target.checked) props.setOfferAccept(' ');
                  }}
                />
                <label style={{ marginLeft: '5px' }}>
                  Automatically accept offers of atleast
                </label>
                <input
                  type="text"
                  style={{ width: '60px', marginLeft: '5px' }}
                  value={props.offerAccept}
                  onChange={(e) => {
                    if (props.isOfferAccept && props.bestOffer) {
                      if (Math.sign(e.target.value) === 1) {
                        props.setOfferAccept(e.target.value);
                      } else props.setOfferAccept(' ');
                    }
                  }}
                ></input>{' '}
                %
              </div>
            ) : null}
            {props.bestOffer ? (
              <div style={{ marginTop: '20px', marginLeft: '5px' }}>
                <input
                  type="checkbox"
                  checked={props.isOfferDecline}
                  onChange={(e) => {
                    props.setIsOfferDecline(e.target.checked);
                    if (!e.target.checked) props.setOfferDecline('');
                  }}
                />
                <label style={{ marginLeft: '5px' }}>
                  Automatically decline offers lower than
                </label>
                <input
                  type="text"
                  style={{ width: '60px', marginLeft: '5px' }}
                  value={props.offerDecline}
                  onChange={(e) => {
                    if (props.isOfferDecline && props.bestOffer) {
                      if (Math.sign(e.target.value) === 1) {
                        props.setOfferDecline(e.target.value);
                      } else props.setOfferDecline('');
                    }
                  }}
                ></input>{' '}
                %
              </div>
            ) : null}
          </Row>
        </Row>

        <Row>
          <Col>
            <label>
              Country*
              <input
                type="text"
                value={props.country}
                className="automatic__input"
                onChange={(e) => {
                  props.setCountry(e.target.value);
                }}
              />
            </label>
          </Col>
          <Col>
            <label>
              Zip code*
              <input
                type="number"
                min={0}
                value={props.zipCode}
                className="automatic__input"
                onChange={(e) => {
                  if (Math.sign(e.target.value) === 1) {
                    props.setZipCode(e.target.value);
                  } else props.setZipCode('');
                }}
              />
            </label>
          </Col>
          <Col>
            <label>
              Location*
              <input
                type="text"
                value={props.location}
                className="automatic__input"
                onChange={(e) => {
                  props.setLocation(e.target.value);
                }}
              />
            </label>
          </Col>
          <Col></Col>
        </Row>

        <Row>
          Use Ebay managed payments
          <label class="switch">
            <input
              type="checkbox"
              checked={props.enableEbayAutoPay}
              onChange={(e) => {
                props.setEnableEbayAutoPay(e.target.checked);
              }}
            />
            <span class="slider round"></span>
          </label>
        </Row>

        <Row>
          Use Mercari Smart Pricing?
          <label class="switch">
            <input
              type="checkbox"
              checked={props.mercariSmartPricing}
              onChange={(e) => {
                props.setMercariSmartPricing(e.target.checked);
              }}
            />
            <span class="slider round"></span>
          </label>
        </Row>
      </div>
    </div>
  );
};

export default Bestoffer;

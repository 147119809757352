import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  withRouter,
  Switch,
} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import 'font-awesome/css/font-awesome.min.css';
import './App.css';

import {
  AddPayment,
  Editdomain,
  Passwords,
  Settings,
  ResetPassword,
  Login,
  Logout,
  Signup,
  Searchcart,
  Templates,
  Transactions,
  TemplateForm,
  forgotpassword,
  Charts,
  AcceptUrl,
  Accounting,
  Subscription,
  Messages,
  ListingSettingsPortal,
} from './Pages';

import {
  RuleEngine,
  TitleGenerator,
} from '@hammoq-inc/hammoq-recycledcomponents-package';

import Header from './Components/header/Header';
import FilesUpload from './Components/FilesUpload/FilesUpload';
import BasicForm from './Pages/Basic/BasicForm';
import EditForm from './Pages/EditForm/EditForm';
import Accounts from './Pages/Accounts/Accounts';
import ClientMessagesProvider from '../src/ContextProviders/ClientMessagesProvider';

import { createBrowserHistory } from 'history';
import tokenChecker from './services/interceptor';
import { get } from './services/Axios';
import { useDispatch } from 'react-redux';
const history = createBrowserHistory();
tokenChecker.setupInterceptors(history);

const Routes = withRouter((props) => {
  const uploading = useSelector((state) => state.uploading);
  const alertMessage =
    'Image are being uploaded in the background. Please Note! Dont clear cache or leave Hammoq till images are finished uploaded.';
  const { pathname } = props.history.location;
  return (
    <div>
      {!['/signup', '/signin', '/forgotpassword', '/health'].includes(
        pathname
      ) && (
        <div className="fixed-top">
          <ClientMessagesProvider>
            <Header />
            <FilesUpload />
            <div style={{ margin: '5px' }}>
              <Alert show={!!uploading} variant={'primary'} dismissible>
                {alertMessage}
              </Alert>
            </div>
          </ClientMessagesProvider>
        </div>
      )}
      <div>
        {localStorage.getItem('token') ? (
          <div className="container-fluid px-5 py-5 admin my-5">
            <Switch>
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/passwords" component={Passwords} />
              <Route exact path="/charts" component={Charts} />
              <Route exact path="/resetpassword" component={ResetPassword} />
              <Route
                exact
                path="/editpasswords/:website"
                component={Editdomain}
              />
              <Route exact path="/addpayment" component={AddPayment} />
              <Route exact path="/templates" component={Templates} />
              <Route exact path="/subscription" component={Subscription} />
              <Route
                exact
                path="/template/:templateid"
                component={TemplateForm}
              />
              <Route exact path="/basic" component={BasicForm} />
              <Route exact path="/edit/:id" component={EditForm} />
              <Route
                exact
                path="/listingsettings"
                component={ListingSettingsPortal}
              />
              <Route exact path="/products" component={Searchcart} />
              <Route exact path="/subscription" component={Subscription} />
              <Route
                exact
                path="/products/:prodStatus"
                component={Searchcart}
              />
              <Route exact path="/transactions" component={Transactions} />
              <Route path="/accounts" component={Accounts} />
              <Route path="/accounting" component={Accounting} />
              <Route exact path="/setting" component={Settings} />
              <Route exact path="/client/ebay/:id" component={AcceptUrl} />
              <Route exact path="/messages" component={Messages} />
              <Route exact path="/rule_engine" component={RuleEngine} />
              <Route exact path="/title_rule" component={TitleGenerator} />
              <Route
                path="/"
                component={() => <Redirect to="/products/submitted" />}
              />
            </Switch>
          </div>
        ) : (
          <div className="container-fluid admin my-3">
            <Switch>
              <Route path="/health">
                <h3>I Am Healthy</h3>
              </Route>
              <Route exact path="/signin" component={Login} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/forgotpassword" component={forgotpassword} />
              <Route path="/" component={() => <Redirect to="/signin" />} />
            </Switch>
          </div>
        )}
      </div>
    </div>
  );
});

function App() {
  return (
    <div>
      <Router>
        <Routes />
      </Router>
    </div>
  );
}
//
export default App;

import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import './Wizardbar.css';
import Carousel from './Carousel';

const Wizardbar = (props) => {
  const BackButton = () => {
    return (
      <Button
        variant="secondary"
        className="onboarding_modal_back_button"
        onClick={() =>
          props.selectedDot > 0
            ? props.setSelectedDot((selectedDot) => selectedDot - 1)
            : null
        }
      >
        Back
      </Button>
    );
  };
  const NextButton = () => {
    return (
      <Button
        variant="primary"
        className="onboarding_modal_next_button"
        disabled={props.currentModalInValid}
        onClick={() =>
          props.selectedDot < props.numberOfDots - 1
            ? props.setSelectedDot((selectedDot) => selectedDot + 1)
            : null
        }
      >
        Next
      </Button>
    );
  };
  const SubmitButton = () => {
    return (
      <Button
        variant="primary"
        className="onboarding_modal_next_button"
        disabled={props.currentModalInValid}
        onClick={() => {
          props.setSubmit(true);
          return props.selectedDot < props.numberOfDots - 1
            ? props.setSelectedDot((selectedDot) => selectedDot + 1)
            : null;
        }}
      >
        Submit
      </Button>
    );
  };
  const InvalidMessage = () => {
    return (
      <div style={{ fontSize: 12, fontStyle: 'italic' }}>
        Please fill all required* fields
      </div>
    );
  };
  return (
    <div className="onboarding_wizard_bar">
      <Carousel
        numberOfDots={props.numberOfDots}
        selectedDot={props.selectedDot}
      />
      <Row>
        <Col>{props.currentModalInValid ? <InvalidMessage /> : null}</Col>
        <Col>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div className="onboarding_buttons" style={{}}>
              {props.selectedDot > 0 ? <BackButton /> : null}
              {props.selectedDot == 3 ? <SubmitButton /> : <NextButton />}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Wizardbar;

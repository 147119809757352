import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImagesList from './ImagesList';
import BulkArea from './BulkArea';
import BetaUSerArea from './BetaUserArea';
import ImageDialog from './ImageDialog';

const ImagesSection = ({
  images,
  onImageRemove,
  assetsURL,
  dragulaDecorator,
  imgRef,
  onBulkUpload,
  templateId,
  templates,
  handleChangesTemplate,
  processML,
  onChange,
  classifier,
  handleChange,
  isRemoveDefaultImageBG,
  onBackgroundRemove,
  showAdvanceOption,
}) => {
  const [current, setCurrent] = useState({ show: false, src: undefined });
  const getSrc = (_event, image) => {
    if (isRemoveDefaultImageBG && image && image.id) {
      const classes = isRemoveDefaultImageBG ? 'bg_' : '';
      const url = `${assetsURL}${classes}${image.id}`;
      return url;
    }
    if (image.img && image.img.length) return image.img;
  };

  const onImageClick = (event, image) => {
    const src = getSrc(event, image);
    setCurrent({ show: true, src });
  };
  return (
    <div className="col col-md-6" ref={imgRef}>
      <div className="row">
        <div className="row" ref={dragulaDecorator}>
          {/* Images Section */}
          <ImageDialog
            src={current.src}
            show={current.show}
            close={() => setCurrent({ show: false, src: undefined })}
          />
          <ImagesList
            images={images}
            onImageClick={onImageClick}
            onImageRemove={onImageRemove}
            assetsURL={assetsURL}
            handleChange={handleChange}
            isRemoveDefaultImageBG={isRemoveDefaultImageBG}
          />
        </div>

        {/* Bulk Area */}
        <BulkArea
          onBulkUpload={onBulkUpload}
          templateId={templateId}
          templates={templates}
          handleChangesTemplate={handleChangesTemplate}
          isRemoveDefaultImageBG={isRemoveDefaultImageBG}
          onBackgroundRemove={onBackgroundRemove}
        />

        {/* Beta User Area */}
        <BetaUSerArea
          processML={processML}
          onChange={onChange}
          classifier={classifier}
        />
      </div>
    </div>
  );
};

ImagesSection.propTypes = {
  images: PropTypes.array.isRequired,
  onImageRemove: PropTypes.func.isRequired,
  assetsURL: PropTypes.string.isRequired,
  dragulaDecorator: PropTypes.func.isRequired,
  onBulkUpload: PropTypes.func.isRequired,
  isUserABetaUser: PropTypes.bool.isRequired,
  processML: PropTypes.bool,
  onChange: PropTypes.func,
  classifier: PropTypes.string,
  handleChange: PropTypes.func,
  isRemoveDefaultImageBG: PropTypes.bool,
  onBackgroundRemove: PropTypes.func,
};

export default ImagesSection;

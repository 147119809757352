import { Grid } from '@material-ui/core';
import React from 'react';
import CrossListProductCard from './CrossListProductCard';
import SearchCartProductCard from './ProductCard';

interface SearchCardGridListProps {
  // properties
  productStatus: string;
  products: any[];
  crossListedProducts: any[];
  showCrossListProducts: boolean;
  search: string;
  rowsPerPage: number;

  // functions
  onDateSort: () => void;
  onEditProduct: (id: string) => void;
  onDuplicateProduct: (id: string) => void;
  onDeleteProduct: (id: string) => void;
}

const SearchCartGridList: React.FC<SearchCardGridListProps> = ({
  products,
  crossListedProducts,
  productStatus,
  showCrossListProducts,
  search,

  onEditProduct,
  onDuplicateProduct,
  onDeleteProduct,
}) => {
  let content = null;

  if (showCrossListProducts) {
    content = crossListedProducts.map((product) => (
      <Grid item lg={3} xl={2} xs={6} md={4} key={product._id}>
        <CrossListProductCard product={product} />
      </Grid>
    ));
  } else {
    content = products?.map((product) => (
      <Grid item lg={3} xl={2} xs={6} md={4} key={product._id}>
        <SearchCartProductCard
          product={product}
          onEditProduct={onEditProduct}
          onDuplicateProduct={onDuplicateProduct}
          onDeleteProduct={onDeleteProduct}
        />
      </Grid>
    ));

    if (content?.length === 0) {
      content =
        search !== ''
          ? `No product found for search "${search}"`
          : productStatus == 'submitted'
          ? 'No products submitted for listings, please submit some'
          : productStatus == 'draft'
          ? 'No product is drafted yet.'
          : productStatus == 'conflicted'
          ? 'No conflict reported.'
          : productStatus == 'approved_drafts'
          ? 'No draft is approved yet.'
          : 'No Product in inventory yet.';
    }
  }

  return (
    <Grid container spacing={2}>
      {content}
    </Grid>
  );
};
export default SearchCartGridList;

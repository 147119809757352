import React, { useState, useEffect } from 'react';
import Axios, { assetsURL, baseURL, get, post } from '../../services/Axios';
import { on, off, trigger } from '../../services/event';
import { ErrorToaster } from '../../services/SweetAlerts';
import './MessagesTextArea.css';
import Grid from '@material-ui/core/Grid';

const MessagesTextArea = (props) => {
  const [message, setMessage] = useState('');
  const [uploadedFile, setUploadedFile] = useState([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [sending, setSending] = useState(false);

  const showFile = ({ detail }) => {
    const url = assetsURL + detail.data.id;
    setUploadedFile((oldFiles) => [...oldFiles, url || '']);
    setUploadingFile(false);
  };

  useEffect(() => {
    on('image:complete', showFile);
    return () => {
      off('image:complete', showFile);
    };
  }, [props]);

  const sendMessage = async () => {
    if (uploadingFile) return;
    if (uploadedFile.length > 0) {
      setSending(true);

      uploadedFile.forEach(async (file) => {
        await Axios.post('/messagesNeo/sendMessage', {
          message: { messageType: 'File', messageBody: file.trim() },
          fromUserId: props.clientId,
          toUserIds: [...props.agentsAllocatedToClient],
        }).then(() => {
          setSending(false);
        });
      });
    }

    if (message.trim().length > 0) {
      setSending(true);

      await Axios.post('/messagesNeo/sendMessage', {
        message: { messageType: 'Text', messageBody: message.trim() },
        fromUserId: props.clientId,
        toUserIds: [...props.agentsAllocatedToClient],
      }).then(() => {
        setSending(false);
      });
    }
    setMessage('');
    setUploadedFile([]);
    props.refresh();
    return;
  };
  const signImage = async ({ fileName, body }) => {
    const query = { fileName };
    const data = body
      ? await post(`${baseURL}/media/sign`, body)
      : await get(`${baseURL}/media/sign`, query);
    if (data.error) {
      ErrorToaster.fire({
        title: data.error,
      });
      return data;
    }
    return data;
  };

  const uploadImage = async (file) => {
    const data = await signImage({ fileName: file.name });
    if (data.presignedURL) {
      trigger('image:append', { data, file });
    }
    if (data.error && data.error.includes('Network')) {
      ErrorToaster.fire({
        title: 'No internet connection! unable to upload images',
      });
    }
    return data;
  };

  const onChangeHandler = async (event) => {
    // setMessage("");
    setUploadingFile(true);
    Object.values(event.target.files).forEach(async (file) => {
      const data = new FormData();
      data.append('file', file);
      let res = await Axios.post('/messagesNeo/uploadFile', data);
      setUploadedFile((oldFiles) => [...oldFiles, res?.data?.publicUrl || '']);
      setUploadingFile(false);
    });
  };

  return (
    <div className="message-textarea-container">
      <div className="message-textarea">
        <textarea
          className="message-textarea-input"
          placeholder="Message HAMMOQ support"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          disabled={sending}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              event.stopPropagation();
              sendMessage();
            }
          }}
        />
      </div>
      <Grid container>
        <Grid item xs={12}>
          <div className="message-textarea-controls">
            <span className="message-icons-right">
              <button
                className="send-message-button"
                disabled={sending}
                onClick={sendMessage}
              >
                <i
                  className="fas fa-paper-plane"
                  style={{ margin: '5px', color: 'white' }}
                ></i>
              </button>
            </span>
            <span className="message-icons">
              <button
                className="message-attachment-button"
                style={{ marginTop: '10px', marginLeft: '10px' }}
                disabled={uploadingFile}
              >
                <span style={{ position: 'relative' }}>
                  <input
                    type="file"
                    name="file"
                    multiple
                    accept="image/*"
                    onChange={onChangeHandler}
                    onClick={(event) => (event.target.value = '')}
                    style={{ opacity: 0, position: 'absolute' }}
                  />
                  <i
                    className="fas fa-paperclip"
                    style={{ margin: '5px', color: 'white' }}
                  />
                </span>
              </button>
              {uploadedFile.length > 0
                ? uploadedFile.map((file, index) => {
                    return (
                      <img
                        alt="uploadedPhoto"
                        src={file}
                        height={35}
                        width={35}
                        style={{ marginLeft: '10px' }}
                      ></img>
                    );
                  })
                : null}
              {uploadingFile === true ? (
                <i
                  className="fa fa-upload"
                  aria-hidden="true"
                  style={{ marginLeft: '10px', color: '#8E7DBE' }}
                />
              ) : null}
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MessagesTextArea;

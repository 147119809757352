import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from '../../services/Axios';
import Alert from '@material-ui/lab/Alert';

const Maintenance = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.maintenance);

  useEffect(() => {
    const saved = JSON.parse(localStorage.getItem('hmq-maintain-alerts')) || [];
    const getAlert = async () => {
      const alert = await get('/maintenance/alert');
      console.log('alert', alert);
      if (!alert) return;
      if (alert.error) {
        dispatch({
          type: 'DELETE_MAINTENANCE_ALERT',
          error: alert.error,
        });
      } else {
        const found = saved.find((o) => o._id === alert._id && o.seen);
        if (found) return;
        localStorage.setItem('hmq-maintain-alerts', JSON.stringify([alert]));
        dispatch({
          type: 'SET_MAINTENANCE_ALERT',
          maintenance: alert,
        });
      }
    };
    getAlert();
  }, [dispatch]);

  const handleAlertClose = () => {
    const saved = JSON.parse(localStorage.getItem('hmq-maintain-alerts')) || [];

    const alerts = saved.map((savedAlert) => {
      if (savedAlert._id === alert._id) {
        savedAlert.seen = true;
      }
      return savedAlert;
    });
    localStorage.setItem('hmq-maintain-alerts', JSON.stringify(alerts));
    dispatch({ type: 'DELETE_MAINTENANCE_ALERT' });
  };

  return (
    <div
      className="alert-msg"
      style={{
        visibility: alert.maintenanceMsg ? 'visible' : 'hidden',
      }}
    >
      {alert.maintenanceMsg ? (
        <Alert
          key={`maintain-alert-${alert._id}`}
          severity="warning"
          variant="outlined"
          onClose={handleAlertClose}
        >
          {alert.maintenanceMsg}
        </Alert>
      ) : null}
    </div>
  );
};

export default Maintenance;

import React from 'react';
import { useState, useEffect } from 'react';
import './Rules.css';

const Rules = (props) => {
  const [childItemType, setChildItemType] = useState(props.item.itemType);
  const [childItemPrice, setChildItemPrice] = useState(props.item.itemPrice);
  const [childItemShipService, setChildItemShipService] = useState(
    props.item.itemShipServ
  );

  useEffect(() => {
    setChildItemType(props.item.itemType);
    setChildItemPrice(props.item.itemPrice);
    setChildItemShipService(props.item.itemShipServ);
  }, [props.item.itemType, props.item.itemPrice, props.item.itemShipServ]);

  const handleChange = (e) => {
    props.onChange(e);
  };

  return (
    <>
      <div className="r2_q4_sub_align">
        <div className="items__align_col">
          <div className="text_allign">Item Type</div>
          <div className="rules_align">
            <div>If</div>
            <label>
              <input
                name="itemType"
                type="text"
                value={childItemType}
                className="r2_q4_inner_align_input"
                onInput={handleChange}
                onChange={(e) => setChildItemType(e.target.value)}
              ></input>
            </label>
          </div>
        </div>
        <div className="items__align_col">
          <div className="text_allign">Price</div>
          <div className="rules_align">
            <div>Then</div>
            <label>
              <input
                name="itemPrice"
                type="number"
                min={0}
                value={childItemPrice}
                className="r2_q4_inner_align_input"
                onInput={handleChange}
                onChange={(e) => setChildItemPrice(e.target.value)}
              ></input>
            </label>
          </div>
        </div>
        <div className="items__align_col">
          <div className="text_allign">Shipping service</div>
          <label className="rules_align">
            <input
              name="itemShipServ"
              type="text"
              value={childItemShipService}
              className="r2_q4_inner_align_input"
              onInput={handleChange}
              onChange={(e) => setChildItemShipService(e.target.value)}
            ></input>
          </label>
        </div>
      </div>
    </>
  );
};

export default Rules;

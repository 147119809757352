import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import EbayAccounts from './EbayAccounts';
import Alert from 'react-bootstrap/Alert';
import GmailAccount from './components/GmailAccount/GmailAccount';
import ConnectShopifyAccount from './components/ShopifyAccount/ConnectShopifyAccount';

//services
import { checkBetaUser } from '../../services/checkBetaUser';

const Accounts = () => {
  const [message, setMessage] = useState({
    show: false,
    variant: 'primary',
    msg: '',
  });

  const [isUserABetaUser, setIsUserABetaUser] = useState(false);

  const checkIsABetaUser = () => {
    try {
      checkBetaUser().then((res) => {
        setIsUserABetaUser(res);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkIsABetaUser();
  }, []);

  return (
    <>
      <Alert
        show={message.show}
        variant={message.variant}
        onClose={() => setMessage({ ...message, show: false })}
        dismissible
      >
        {message.msg}
      </Alert>

      <div style={{ margin: '25px 10px' }}>
        <Route path="/accounts/ebayAccounts">
          <EbayAccounts setMessage={setMessage} />
        </Route>
      </div>

      <GmailAccount />
      <ConnectShopifyAccount />
    </>
  );
};

export default Accounts;

import axios from 'axios';
import { baseURL } from './Axios';
import db from './database';
import { on, trigger } from './event';
import defaults from './defaults';
import { ErrorToaster } from './SweetAlerts';
import { sendProcessingModel } from './methods';
window.pendingImages = [];
const current = [];
const queue = [];
const limit = 24;

const signImage = async (id) => {
  try {
    const query = { id };
    const response = await axios.get(`${baseURL}/media/sign`, {
      params: query,
    });
    return response.data;
  } catch (error) {
    if (error.message.includes('Network')) {
      ErrorToaster.fire({
        title: 'No internet connection! unable to upload pending images',
      });
    }
    return { error: error.message };
  }
};
const removeFile = async (data, params) => {
  // Deleting from the prevent array
  for (let i = 0; i < window.pendingImages.length; i++) {
    if (window.pendingImages[i].id === data.id) {
      window.pendingImages.splice(i, 1);
    }
  }

  // Deleting from the current uploading array
  for (let i = 0; i < current.length; i++) {
    if (current[i].id === data.id) {
      current.splice(i, 1);
    }
  }

  if (params && params.cache) return true;

  // Delete the image
  const docId = data.docId ? data.docId : 'image:' + data.id;
  const doc = await db.get(docId);
  doc._deleted = true;

  return db.put(doc);
};

const removeBackground = async (file, data) => {
  try {
    const url = defaults.PHOTO_ROOM_URL;
    let extension = data.id.match(/\.(\w+$)/);
    extension = extension && extension.length > 1 ? extension[1] : 'png';
    const imageId = `bg_${data.id.substring(
      0,
      data.id.lastIndexOf(extension)
    )}${extension}`;

    const form = new FormData();
    form.append('image_file', file);

    const config = {
      method: 'POST',
      headers: {
        'x-api-key': defaults.PHOTO_ROOM_API_KEY,
      },
      body: form,
    };
    const response = await fetch(url, config);
    const blob = await response.blob();
    const newFile = new File([blob], imageId, {
      type: data.contentType || 'image/png',
    });

    const image = await signImage(imageId);
    if (!image.error) {
      const uploadUrl = image.presignedURL;
      const uploadConfig = {
        headers: {
          'Content-Type': data.contentType || 'image/png',
        },
      };
      const result = await axios.put(uploadUrl, newFile, uploadConfig);
      return result;
    }
    return image;
  } catch (error) {
    console.error(error);
  }
};

const uploadFile = async (file, data) => {
  try {
    // Set axios configs
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        trigger('image:progress', { data, percentage });
      },
      headers: {
        'Content-Type': data.contentType,
      },
    };

    // Deleting from the queue array
    for (var i = 0; i < queue.length; i++) {
      if (queue[i].id === data.id) {
        queue.splice(i, 1);
      }
    }

    // Tranfser Image
    const response = await axios.put(data.presignedURL, file, config);
    trigger('image:complete', { file, data });
    removeFile(data);

    // detect if we're finished for that product [tempId]
    await detectCompletion(data);

    // Load more from queue
    return loadMore();
  } catch (error) {
    console.error('something went wrong uploading image', error.message);
    if (error.message && error.message.includes('Network')) {
      trigger('image:error', { data });
    }

    removeFile(data, { cache: true });

    // Load more from queue
    return loadMore();
  }
};

const appendImage = async (file, data, isDefault) => {
  data.isDefault = isDefault;
  const key = 'image:' + data.id;
  let image;
  try {
    image = await db.get(key, { include_docs: true });
  } catch (error) {
    // console.log("error happened retrieving image from pouchdb");
  }

  if (!image) {
    const doc = {
      tempId: data.tempId,
      _id: 'image:' + data.id,
      ...data,
      _attachments: {
        [data.id]: {
          content_type: data.contentType,
          data: file,
        },
      },
    };
    db.put(doc)
      .then(function (result) {
        // Add image to pendingImages. Check current uploading size if maximum length put image in queue
        const pendingImage = window.pendingImages.find((o) => o.id === data.id);
        if (!pendingImage) {
          window.pendingImages.push({
            progress: 0,
            id: data.id,
          });
        }

        if (current.length && current.length >= limit) {
          queue.push({ data, id: data.id, file });
        } else {
          current.push({ data, id: data.id, file });
          uploadFile(file, data);

          if (isDefault) removeBackground(file, data);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }
};

const signUpload = (image, index = 0) => {
  const attachmentId = image.id.replace('image:', '');
  const file = new File(
    [image.doc._attachments[attachmentId].data],
    attachmentId,
    { type: image.doc.contentType }
  );

  setTimeout(async () => {
    let data = {
      id: attachmentId,
      docId: image.id,
    };
    const updated = await signImage(attachmentId);
    if (!updated.error) {
      data.presignedURL = image.doc.presignedURL;
      data.contentType = image.doc.contentType;
    } else {
      data.presignedURL = image.doc.presignedURL;
      data.contentType = image.doc.contentType;
    }

    trigger('image:restart', { data });

    // Add image to pendingImages. Check current uploading size if maximum length put image in queue
    const pendingImage = window.pendingImages.find((o) => o.id === data.id);
    if (!pendingImage) {
      window.pendingImages.push({
        progress: 0,
        id: data.id,
      });
    }

    if (current.length && current.length >= limit) {
      queue.push({ data, id: data.id, file });
    } else {
      current.push({ data, id: data.id, file });
      uploadFile(file, data);
      removeBackground(file, data);
    }
  }, index * 2000);
};

const restartFile = async (id) => {
  const docId = `image:${id}`;
  const doc = await db.get(docId, {
    attachments: true,
    binary: true,
    include_docs: true,
  });
  if (doc) {
    return signUpload({ doc, id: docId });
  }

  // uploadFile(params.file, params.data);
};

const loadMore = () => {
  for (let i = 0; queue.length; i++) {
    const pending = queue[i];
    const found = current.find((o) => o.id === pending.id);
    if (!found) {
      current.push(pending);
      uploadFile(pending.file, pending.data);
      removeBackground(pending.file, pending.data);
      return;
    }
  }
};

const detectCompletion = async ({ tempId }) => {
  // detecting if all images were uploaded - then triggering ML processing
  // eslint-disable-next-line no-debugger
  const documents = await db.find({
    selector: {
      tempId,
    },
  });
  const pending = localStorage.getItem(`pending-${tempId}`);
  if (documents.docs && !documents.docs.length && !pending) {
    localStorage.setItem(`pending-${tempId}`, true);
    return sendProcessingModel({ tempId });
  }
};
const init = async () => {
  const images = await db.allDocs({
    attachments: true,
    binary: true,
    include_docs: true,
  });

  if (!images.rows) {
    return;
  }
  images.rows.map(async (image, index) => {
    if (image.id.includes('image')) return signUpload(image, index);
  });
};

init();
on('image:append', ({ detail }) =>
  appendImage(detail.file, detail.data, detail.isDefault)
);
on('image:remove', ({ detail }) => {
  removeFile(detail.data);
  // Load more from queue
  return loadMore();
});
on('image:refresh', ({ detail }) => {
  restartFile(detail.id);
});
window.onbeforeunload = function () {
  if (window.pendingImages && Object.keys(window.pendingImages).length !== 0) {
    return 'Your images are not completely uploaded...';
  }
};

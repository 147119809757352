import React, { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import ImportShopifyModal from '../ImportShopify/ImportShopifyModal';
import Axios from '../../../services/Axios';

const productHeaders = [
  { label: 'Handle', key: 'handle' },
  { label: 'Title', key: 'title' },
  { label: 'Body (HTML)', key: 'shortDescription' },
  { label: 'Vendor', key: 'vendor' },
  { label: 'Standardized Product Type', key: 'standardized_product_type' },
  { label: 'Hammoq Category', key: 'ebayCategoryField' },
  { label: 'Published', key: 'prodStatus' },
  { label: 'option1 Name', key: 'option1Name' },
  { label: 'option1 Value', key: 'option1Value' },
  { label: 'option2 Name', key: 'option2Name' },
  { label: 'option2 Value', key: 'option2Value' },
  { label: 'option3 Name', key: 'option3Name' },
  { label: 'option3 Value', key: 'option3Value' },
  { label: 'Variant SKU', key: 'sku' },
  { label: 'SEO Title', key: 'title' },
  { label: 'SEO Description', key: 'shortDescription' },
  { label: 'Short Description', key: 'shortDescription' },
  { label: 'Variant Inventory Qty', key: 'quantity' },
  { label: 'Variant Grams', key: 'weightOZ' },
  { label: 'Variant Price', key: 'price' },
  { label: 'Variant Compare At Price', key: 'compPriceIncreaseValue' },
  { label: 'Cost per item', key: 'costOfGoods' },
  { label: 'Status', key: 'status' },
  { label: 'Image Src', key: 'default_Image' },
];

interface SearchCartActionBarProps {
  productStatus: string;
  inventoryCount: number;
  draftCount: number;
  approvedDraftCount: number;
  submittedCount: number;
  conflictedDraftCount: number;
  csvLinkEl: any;
  productData: any;

  // handler
  onProductFilter: (status: string) => void;
  onDownloadProduct: () => void;
}

const SearchCartActionBar: React.FC<SearchCartActionBarProps> = ({
  productStatus,
  inventoryCount,
  draftCount,
  approvedDraftCount,
  submittedCount,
  conflictedDraftCount,
  csvLinkEl,
  productData,

  onProductFilter,
  onDownloadProduct,
}) => {
  const [showImportModal, setShowImportModal] = React.useState(false);
  const [hasShopify, setHasShopify] = React.useState(true);

  const handleImportModalOpen = (show: boolean) => {
    setShowImportModal(show);
  };

  useEffect(() => {
    const getShopify = async () => {
      try {
        await Axios.get('/shopify/oAuth');
        setHasShopify(true);
      } catch (error) {
        setHasShopify(false);
      }
    };
    getShopify();
  }, []);

  return (
    <div style={{ justifyContent: 'space-evenly' }}>
      <button
        type="button"
        id="btn-inventory"
        className={
          productStatus === 'inventory'
            ? 'btn btn btn-success d-inline mr-3 mb-3'
            : 'btn btn-primary d-inline mr-3 mb-3'
        }
        onClick={() => {
          onProductFilter('inventory');
        }}
      >
        {inventoryCount} - Inventory
      </button>
      <button
        id="btn-draft"
        className={
          productStatus === 'draft'
            ? 'btn btn btn-success d-inline mr-3 mb-3'
            : 'btn btn-primary d-inline mr-3 mb-3'
        }
        onClick={() => {
          onProductFilter('draft');
        }}
      >
        {draftCount} - Drafts
      </button>
      <button
        id="btn-approved-draft"
        className={
          productStatus === 'approved_drafts'
            ? 'btn btn btn-success d-inline mr-3 mb-3'
            : 'btn btn-primary d-inline mr-3 mb-3'
        }
        onClick={() => {
          onProductFilter('approved_drafts');
        }}
      >
        {approvedDraftCount} - Approved Drafts
      </button>
      <button
        id="btn-submitted"
        className={
          productStatus === 'submitted'
            ? 'btn btn btn-success d-inline mr-3 mb-3'
            : 'btn btn-primary d-inline mr-3 mb-3'
        }
        onClick={() => {
          onProductFilter('submitted');
        }}
      >
        {submittedCount} - Submitted
      </button>
      <button
        id="btn-conflicts"
        className={
          productStatus == 'conflicted'
            ? 'btn btn btn-danger d-inline mr-3 mb-3'
            : 'btn btn-outline-danger d-inline mr-3 mb-3'
        }
        onClick={() => {
          onProductFilter('conflicted');
        }}
      >
        {conflictedDraftCount} - Conflicts
      </button>
      <button
        id="btn-export-shopify-csv"
        className={'btn btn-primary d-inline mr-3 mb-3'}
        onClick={onDownloadProduct}
      >
        Export as Shopify CSV
      </button>
      <CSVLink
        headers={productHeaders}
        filename="Product Export.csv"
        data={productData}
        ref={csvLinkEl}
      />

      {/* shopify import products*/}
      {hasShopify ? (
        <button
          id="btn-import-shopify-products"
          className={'btn btn-primary d-inline mr-3 mb-3'}
          onClick={() => {
            handleImportModalOpen(true);
          }}
        >
          Import Shopify Products
        </button>
      ) : null}
      <ImportShopifyModal
        showModal={showImportModal}
        onShowModal={handleImportModalOpen}
      />
    </div>
  );
};

export default SearchCartActionBar;

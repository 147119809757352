/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { BsLink45Deg } from 'react-icons/bs';

import { assetsURL, assetsThumbnailURL } from '../../../services/Axios';
import StatusColumn from '../common/StatusColumn';
import { getProductTitle } from '../helpers';

interface SearchCartListProps {
  // properties
  productStatus: string;
  products: any[];
  crossListedProducts: any[];
  showCrossListProducts: boolean;
  search: string;
  rowsPerPage: number;
  page: number;

  // functions
  onDateSort: () => void;
  onEditProduct: (id: string) => void;
  onDuplicateProduct: (id: string) => void;
  onDeleteProduct: (id: string) => void;
}

const SearchCartList: React.FC<SearchCartListProps> = ({
  products,
  crossListedProducts,
  productStatus,
  showCrossListProducts,
  search,
  rowsPerPage,
  page,

  onDateSort,
  onEditProduct,
  onDuplicateProduct,
  onDeleteProduct,
}) => {
  const buildValidURL = (url: string): string => {
    if (url.indexOf('https') === -1 && url.indexOf('http') === -1) {
      return `https://${url}`;
    }

    return url;
  };

  return (
    <table className="table">
      <thead>
        {productStatus == 'crossListedProducts' ? (
          <tr>
            <th>No</th>
            <th>Title</th>
            <th>Crosslisted From</th>
            <th>Crosslisted To</th>
          </tr>
        ) : (
          <tr>
            <th scope="col" className="imageIt">
              No
            </th>
            <th scope="col" className="imageIt">
              Photos
            </th>
            <th scope="col">TITLE</th>
            <th scope="col" className="sku">
              SKU
            </th>
            <th scope="col" className="price">
              PRICE
            </th>
            <th scope="col" className="status">
              STATUS
            </th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col">
              <button
                className="btn btn-sm btn-without-bot"
                onClick={onDateSort}
              >
                <i className="fa fa-sort" aria-hidden="true"></i>
                <b>DATE</b>
              </button>
            </th>
            <th scope="col" className="notes">
              NOTES
            </th>
          </tr>
        )}
      </thead>
      <tbody>
        {!showCrossListProducts ? (
          <>
            {products && products.length > 0 ? (
              products.map((product, idx) => {
                product.images = product.images || {};
                const thumbnailPath = product.images.default_image
                  ? product.images.default_image.substring(
                      0,
                      product.images.default_image.indexOf(':')
                    ) !== 'http' &&
                    product.images.default_image.substring(
                      0,
                      product.images.default_image.indexOf(':')
                    ) !== 'https'
                    ? assetsThumbnailURL + product.images.default_image
                    : product.images.default_image
                  : '';
                const fullSizePath = product.images.default_image
                  ? product.images.default_image.substring(
                      0,
                      product.images.default_image.indexOf(':')
                    ) !== 'http' &&
                    product.images.default_image.substring(
                      0,
                      product.images.default_image.indexOf(':')
                    ) !== 'https'
                    ? assetsURL + product.images.default_image
                    : product.images.default_image
                  : '';
                return (
                  <tr key={`productsList${idx}`}>
                    <td>{idx + 1 + (page - 1) * rowsPerPage}</td>
                    <td>
                      <img
                        src={thumbnailPath}
                        className="product-img"
                        onError={(e: any) => {
                          if (e.target.src !== fullSizePath) {
                            e.target.src = fullSizePath;
                          }
                          e.target.onerror = null;
                        }}
                        alt="Default pic Missing"
                      />
                    </td>
                    <td>{getProductTitle(product)}</td>
                    <td>{product.sku}</td>
                    <td>
                      <div>{product.price}</div>
                    </td>
                    <td>
                      <StatusColumn product={product} />
                    </td>
                    <td>
                      <a
                        role="button"
                        onClick={() => onEditProduct(product._id)}
                        tabIndex={0}
                      >
                        <button className="btn btn-primary d-inline btn-sm">
                          Edit/List
                        </button>
                      </a>
                    </td>
                    <td>
                      {productStatus !== 'conflicted' ? (
                        <button
                          className="btn btn-primary d-inline btn-sm"
                          onClick={() => onDuplicateProduct(product._id)}
                        >
                          Duplicate
                        </button>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      <button
                        className="btn btn-danger d-inline btn-sm"
                        onClick={() => onDeleteProduct(product._id)}
                      >
                        Delete
                      </button>
                    </td>
                    <td>
                      <small>{product.date.split('T')[0]}</small>
                    </td>
                    <td>
                      <small>{product.note}</small>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  style={{
                    display: 'block',
                    fontSize: '1em',
                    marginTop: '1.33em',
                    marginBottom: '1.33em',
                    marginLeft: '0',
                    marginRight: '0',
                    fontWeight: 'bold',
                  }}
                >
                  {search !== ''
                    ? `No product found for search "${search}"`
                    : productStatus == 'submitted'
                    ? 'No products submitted for listings, please submit some'
                    : productStatus == 'draft'
                    ? 'No product is drafted yet.'
                    : productStatus == 'conflicted'
                    ? 'No conflict reported.'
                    : productStatus == 'approved_drafts'
                    ? 'No draft is approved yet.'
                    : 'No Product in inventory yet.'}
                </td>
              </tr>
            )}
          </>
        ) : (
          <>
            {crossListedProducts.map((product, idx) => {
              return (
                <tr key={product._id}>
                  <td>{idx + 1}</td>
                  <td>{product.title}</td>
                  <td>
                    {product.crossListedFrom.name}
                    <a
                      href={buildValidURL(product.crossListedFrom.url)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsLink45Deg />
                    </a>
                  </td>
                  <td>
                    <ul>
                      {product.crossListedTo.map((to: any, index: number) => {
                        return (
                          <li key={`to ${index}`}>
                            {to.name}
                            <a
                              href={buildValidURL(to.url)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <BsLink45Deg />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </td>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
};

export default SearchCartList;

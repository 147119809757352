/* eslint-disable @typescript-eslint/no-empty-function, jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Dragula from 'react-dragula';
import { nanoid } from 'nanoid';
import ebayConditions from '../../Components/ebayConditions';
import { trigger, on, off } from '../../services/event';
import { sendProcessingModel } from '../../services/methods';
import * as _ from 'lodash';

import {
  ErrorToaster,
  SuccessToaster,
  Confirmer,
} from '../../services/SweetAlerts';
import Axios, { get, baseURL, assetsURL, post } from '../../services/Axios';
import ButtonGroup from './ButtonGroup';
import './BasicForm.css';
import './drag.css';
import Maintenance from '../../Components/maintenance/Maintenance';
import LoadingSpinner from '../utils/loader';
import PaymentAlert from '../../Components/paymentAlert/PaymentAlert';
import OnboardingModal from '../OnboardingModal/Onboardingmodal';
import ImagesSection from './ImagesSection';

let imagesSchema = [
  { key: 'default_image', label: 'Default', img: '' },
  { key: 'brand_image', label: 'Brand', img: '' },
  { key: 'model_image', label: 'Model', img: '' },
  { key: 'side1_image', label: 'Side', img: '' },
  { key: 'side2_image', label: 'Side', img: '' },
  { key: 'front_image', label: 'Front', img: '' },
  { key: 'back_image', label: 'Back', img: '' },
  { key: 'condition1_image', label: 'Condition', img: '' },
  { key: 'condition2_image', label: 'Condition', img: '' },
  { key: 'condition3_image', label: 'Condition', img: '' },
  { key: 'condition4_image', label: 'Condition', img: '' },
  { key: 'condition5_image', label: 'Image Tag', img: '' },
];
class BasicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormInProgress: false,
      test: false,
      drake: undefined,
      classifier: undefined,
      websites: ['Poshmark', 'Mercari', 'other'],
      savedCards: [],
      username: '',
      password: '',
      website: '',
      showModal: false,
      loading: false,
      submitted: false,
      changed: false,
      offeredRate: {},
      itemCondition: 'New',
      sku: '',
      price: 0,
      costOfGoods: 0,
      brand: '',
      model: '',
      note: '',
      shortDescription: '',
      quantity: 0,
      weightOZ: 0,
      weightLB: 0,
      waist: 0,
      inseam: 0,
      rise: 0,
      progress: 10,
      category: '',
      isSubmitting: false,
      images: [
        { key: 'default_image', label: 'Default', img: '', dataIndex: 0 },
        { key: 'brand_image', label: 'Brand', img: '', dataIndex: 1 },
        { key: 'model_image', label: 'Model', img: '', dataIndex: 2 },
        { key: 'side1_image', label: 'Side', img: '', dataIndex: 3 },
        { key: 'side2_image', label: 'Side', img: '', dataIndex: 4 },
        { key: 'front_image', label: 'Front', img: '', dataIndex: 5 },
        { key: 'back_image', label: 'Back', img: '', dataIndex: 6 },
        { key: 'condition1_image', label: 'Condition', img: '', dataIndex: 7 },
        { key: 'condition2_image', label: 'Condition', img: '', dataIndex: 8 },
        { key: 'condition3_image', label: 'Condition', img: '', dataIndex: 9 },
        { key: 'condition4_image', label: 'Condition', img: '', dataIndex: 10 },
        { key: 'condition5_image', label: 'Image Tag', img: '', dataIndex: 11 },
      ],
      bal: 0,
      rates: {},
      img: [],
      cid: '',
      open: false,
      templates: [],
      templateId: '',
      extraFieldsTemplate: {},
      productId: '',
      marketPlaces: [],
      otherFieldActive: false,
      hasMarketPlaces: false,
      isMarketPlaceSelected: false,
      delist: false,
      loadOnboarding: false,
      delistDisabled: true,
      isUserABetaUser: true,
      isDescriptionShowOnList: false,
      tempId: nanoid(),
      showAdvanceListOption: true,
      isdefaultImageBGRemove: false,
      processML: this.getLocalMlOption(),
      ebay: {},
      color: '',
    };
    this.baseState = this.state;
    this.uploadImage = this.uploadImage.bind(this);
    this.uploadImages = this.uploadImages.bind(this);
    this.signImage = this.signImage.bind(this);
    this.fetchBasicListingDetails.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.imgRef = React.createRef();
    this.handleMarketplaceChange = this.handleMarketplaceChange.bind(this);
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    document.getElementById('template').onclick = async function (e) {
      if (!prevState.isFormInProgress) {
        history.replace('/templates');
      } else {
        const result = await Confirmer.fire({
          text: 'You have unsaved changes, are you sure you want to leave?',
          showConfirmButton: true,
          showCancelButton: true,
        });
        result.isConfirmed ? history.replace('/templates') : '';
      }
    };
  };

  dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      let options = {};
      const drake = Dragula([componentBackingInstance], options);
      drake.on('drop', this.onDrop);
    }
  };

  componentDidMount = async () => {
    const { extension } = this.props;
    let marketList = localStorage.getItem('marketSetting');
    if (marketList !== null) marketList = marketList.split(',');

    try {
      let allMarketplaces = await get('/marketplaces');
      if (extension) {
        // TODO- Don't directly push, instead check if it does't exist, create on in backend
        const poshmarkExist = allMarketplaces.find(
          (mp) => mp.website.toLowerCase() == 'facebook'
        );
        const facebookExist = allMarketplaces.find(
          (mp) => mp.website.toLowerCase() == 'poshmark'
        );
        if (!facebookExist) {
          allMarketplaces.push({
            website: 'Facebook',
            Status: false,
            username: 'FacebookUserFromExtension',
          });
        }
        if (!poshmarkExist) {
          allMarketplaces.push({
            website: 'Poshmark',
            Status: false,
            username: 'FacebookUserFromExtension',
          });
        }
      }

      //check if the user has a shopify account
      const shopify = await get('/shopify/oAuth/');
      if (!shopify.error && shopify.shop) {
        allMarketplaces.push({
          website: 'Shopify',
          Status: false,
          username: shopify.shop,
        });
      }

      this.setState({
        marketPlaces: allMarketplaces.map((mp) => {
          return { ...mp, Status: false };
        }),
        hasMarketPlaces: allMarketplaces.length > 0,
      });
    } catch (err) {
      console.log(err);
    }

    Axios.get('/template/getTemplate')
      .then((data) => {
        this.setState({ templates: data.data.templates });
      })
      .catch((err) => {
        console.log(err);
      });

    this.fetchBasicListingDetails();

    Axios.get('/payment/rates')
      .then((res) => {
        this.setState({ rates: res.data });
      })
      .catch((err) => {
        console.log(err);
      });

    let condSetting = localStorage.getItem('condSetting');
    if (condSetting !== null) {
      this.setState({ itemCondition: condSetting });
    }

    on('image:remove', ({ detail }) => {
      this.removeImg({ id: detail.data.id });
    });
    // this.unregisterLeaveHook = this.props.router.setRouteLeaveHook(this.route, this.routerWillLeave.bind(this));
  };

  componentDidMount() {
    off('image:remove', ({ detail }) => {
      this.removeImg({ id: detail.data.id });
    });
  }

  componentWillUnmount() {
    const { submitted, uploaded, images } = this.state;
    const shouldDelete = uploaded && !submitted;
    const stored = images.filter((o) => o.id && o.id !== '');
    if (shouldDelete) {
      stored.map((image) =>
        trigger('image:remove', { data: { id: image.id } })
      );
    }
  }

  //drops when balance below 0.90 dollar
  fetchBasicListingDetails = async () => {
    await Axios.get('/clientdetails/basiclisting')
      .then(async ({ data }) => {
        if (!data.isSubscribed && !data.subscribedByAdmin) {
          Confirmer.fire({
            text: 'You are not subscribed, Kindly Subscribe To Hammoq Listing Services To Continue.',
          }).then((result) => {
            result.isConfirmed ? window.open('/subscription', '_self') : '';
          });
        } else if (parseInt(data.balance) < 0.9 && data.savedCards.length > 0) {
          this.setState({ open: true });
        } else if (
          parseInt(data.balance) < 0.9 &&
          data.savedCards.length == 0
        ) {
          Confirmer.fire({
            text: 'Low Payment and No card added, Please add a card and then add payment..',
          }).then((result) => {
            result.isConfirmed ? window.open('/subscription', '_self') : '';
          });
        } else if (data.showOnboarding === true) {
          this.setState({ loadOnboarding: true });
        } else if (parseInt(data.balance) < 2) {
          Confirmer.fire({
            title: 'Uh oh!',
            text: 'Note, your balance is low, Please recharge your account',
            width: '50rem',
          });
        }
        this.setState({
          bal: parseInt(data.balance),
          savedCards: data.savedCards,
          cid: data._id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  uploadImage = async (file, isDefault) => {
    const data = await this.signImage({ fileName: file.name });
    if (data.presignedURL) {
      trigger('image:append', {
        data,
        file,
        isDefault,
      });
    }

    if (data.error && data.error.includes('Network')) {
      ErrorToaster.fire({
        text: 'No internet connection! unable to upload images',
      });
    }
    return data;
  };

  uploadImages = async (files, hasDefault) => {
    const fileNames = [];
    for (var i = 0; i < files.length; i++) {
      fileNames.push(files[i].name);
    }

    const data = await this.signImage({ body: { fileNames } });
    const response = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      setTimeout(() => {
        if (data[i] && data[i].presignedURL) {
          // using photoroom if it's first image in loop and the images have the default image
          trigger('image:append', {
            data: data[i],
            file,
            isDefault: i === 0 && hasDefault,
          });
        }
      }, i * 1000);
      if (data[i]) response.push(data[i]);
    }
    return response;
  };

  /**
   * Post-drop handler for <ImagesSection> drag-n-drop re-ordering. Updates
   * state to reflect the new order of images.
   * @param {HTMLElement} el
   * @returns void
   */
  onDrop = (el) => {
    const { images } = this.state;

    if (!el) return;

    const elements = this.imgRef.current.children[0].children[0].children;
    const newOrderByKey = Array.from(elements).reduce((keys, el) => {
      const key = el.getAttribute('imgkey');
      // Exclude non-image elements. At the time of writing, there was a
      // hidden element at the same level that is used for modal dialogs.
      if (key) keys.push(key);
      return keys;
    }, []);

    // Set dataIndex properties
    const reordredImages = [...images].map((img) => {
      const index = newOrderByKey.findIndex((key) => key === img.key);

      if (index !== -1) img.dataIndex = index;
      else
        console.error(
          `While reordering images post-drag-n-drop: image key "${img.key}" was not found in keys derived from elements.`,
          '`imgKey` property of elements',
          newOrderByKey,
          'Image',
          img
        );

      return img;
    });

    // Re-index array based on dataIndex
    reordredImages.sort((a, b) => a.dataIndex - b.dataIndex);

    this.setState({ images: reordredImages });
  };

  signImage = async ({ fileName, body }) => {
    const tempId = this.state.tempId;
    const query = { fileName };
    const response = body
      ? await post(`${baseURL}/media/sign`, body)
      : await get(`${baseURL}/media/sign`, query);

    // Save Temp ProductId For Upload References

    const data = Array.isArray(response)
      ? response.map((o) => ({ tempId, ...o }))
      : response;

    data.tempId = tempId;
    if (data.error) {
      ErrorToaster.fire({
        text: data.error,
      });
      return data;
    }
    return data;
  };

  change = (e) => {
    let value = e.target.value;

    //force +ve input values if min is greater than -1
    let min = e.target.getAttribute('min');
    if (min && min !== null && min > -1 && value !== null) {
      value = !isNaN(value) ? Math.abs(value) : value;
    }

    this.setState({ [e.target.name]: value, changed: true });
    this.setState({ isFormInProgress: true });
  };

  setCategory = (str) => {
    var { category } = this.state;

    if (category === str) this.setState({ category: '' });
    else this.setState({ category: str });
  };

  onSubmit = async () => {
    //e.preventDefault();
    const {
      images,
      cid,
      itemCondition,
      sku,
      brand,
      model,
      quantity,
      price,
      costOfGoods,
      category,
      waist,
      inseam,
      rise,
      weightLB,
      weightOZ,
      marketPlaces,
      hasMarketPlaces,
      tempId,
      note,
      shortDescription,
      delist,
      rates,
      bal,
      savedCards,
      isDescriptionShowOnList,
      isdefaultImageBGRemove,
      templateId,
      extraFieldsTemplate,
      ebay,
      color,
    } = this.state;
    const { setPhotoroom } = this.props;
    var { isMarketPlaceSelected } = this.state;

    this.setState({ isFormInProgress: false });

    var marketList = '';
    var cnt = 0;
    const defaultImage = images.find((o) => o.key === 'default_image');

    let productWebsites = {
      ebay: {
        check: false,
        title: 'Ebay',
        ebayUserName: '',
      },
      //custom marketplace
      shopify: {
        id: null,
        check: false,
        title: 'Shopify',
        metaFields: [],
        shopifyUserName: '',
      },
    };
    let otherProductWebsites = [];

    marketPlaces.map((mp) => {
      if (mp.Status) {
        cnt++;
        isMarketPlaceSelected = true;
        marketList += mp.website + ',';
      }
      const defaultMarketPlaces = ['poshmark', 'mercari', 'facebook'];
      if (defaultMarketPlaces.includes(mp.website.toLowerCase())) {
        productWebsites[mp.website.toLowerCase()] = {
          title: mp.website,
          check: mp.Status,
        };
      } else if (mp.website.toLowerCase() == 'ebay' && mp.Status) {
        productWebsites['ebay'] = {
          check: true,
          title: 'Ebay',
          ebayUserName: mp.username,
        };
        productWebsites['ebay'] = { ...productWebsites['ebay'], ...ebay };
      } else if (mp.website.toLowerCase() == 'shopify' && mp.Status) {
        productWebsites['shopify'] = {
          id: null,
          check: true,
          title: mp.website,
          metaFields: [],
          shopifyUserName: mp.username,
        };
      } else {
        otherProductWebsites.push({
          name: mp.website,
          status: mp.Status,
          url: '',
        });
      }
    });

    if (delist) {
      marketList += 'delist';
    }

    if (
      localStorage.getItem('isSubscribed') === false ||
      localStorage.getItem('isSubscribed') === 'false'
    )
      return Confirmer.fire({
        text: 'You are not subscribed, Please Subscribe To Hammoq Listing Services To Continue.123',
      });

    if (!defaultImage.id || !defaultImage.img || defaultImage.img === '')
      return ErrorToaster.fire({ title: 'The Default Image Is Required' });
    if (itemCondition === 'Select Condition *' || itemCondition === '')
      return ErrorToaster.fire({ title: 'A Condition Is Required' });
    if (!hasMarketPlaces) {
      return this.setState({ showModal: true });
    }
    if (!isMarketPlaceSelected)
      return ErrorToaster.fire({
        title: 'Please select at least one marketplace',
      });
    if (quantity < 0)
      return ErrorToaster.fire({ title: 'Quantity cannot be negative' });
    if (price < 0)
      return ErrorToaster.fire({ title: 'Price cannot be negative' });

    if (price && price < 0) {
      // eslint-disable-next-line no-const-assign
      price = 0;
    }

    if (quantity && quantity < 0) {
      // eslint-disable-next-line no-const-assign
      quantity = 1;
    }

    var rate1 = 0,
      rate2 = 0,
      rate3 = 0;
    var total = 0;

    rate1 = (rates.list / 100) * 1;
    rate2 = (rates.crossList / 100) * (cnt - 1);

    if (delist == true) rate3 = (rates.deList / 100) * (cnt - 1);

    total = rate1 + rate2 + rate3;

    if (bal - total < 0) {
      if (savedCards.length > 0) {
        this.setState({ open: true });
        return ErrorToaster.fire({ title: 'Insufficient Balance.' });
      } else {
        Confirmer.fire({
          text: 'Low Payment and No Card Added, Please add a card and then add to your balance.',
        }).then((result) => {
          result.isConfirmed ? window.open('/subscription', '_self') : '';
        });
      }
    }
    const data = {
      sku,
      quantity: quantity || 1,
      price,
      brand,
      model,
      note,
      shortDescription,
      waist,
      inseam,
      category,
      rise,
      weightLB,
      weightOZ,
      costOfGoods,
      rate1,
      rate2,
      rate3,
      // others: JSON.stringify(otherSites),
      condition_name: itemCondition,
      activity: 'Basic',
      action: 'Move',
      prodStatus: 'submitted',
      status: true,
      listed: false,
      ...productWebsites,
      others: JSON.stringify(otherProductWebsites),

      delist: {
        check: delist,
      },
      classifier: this.state.processML ? 'dispatcher' : undefined,
      isDescriptionShowOnList,
      color,
    };

    if (typeof data.classifier !== 'string' || !data.classifier) {
      delete data.classifier;
    }

    let hasImages = false;
    images
      .filter((image) => !!image.id)
      .map(async (image) => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!image.id) {
          data[image.key] = image.id;
          hasImages = true;
        }
      });

    data.imagesMap = images.map((o) => ({
      index: o.dataIndex,
      key: o.key,
    }));

    if (templateId != '') {
      for (let field in extraFieldsTemplate) {
        data[field] = extraFieldsTemplate[field];
      }
    }

    if (data.default_image && isdefaultImageBGRemove) {
      data.default_image = `bg_${data.default_image}`;
    }

    if (!hasImages) {
      return ErrorToaster.fire({
        title: 'You need to submit at least one image',
      });
    }

    this.setState({ isSubmitting: true });

    const result = await post('/product/create', data);
    setPhotoroom(false);
    if (result.error) {
      this.setState({ isSubmitting: false });
      ErrorToaster.fire({
        title: result.error,
      });
    } else {
      const message =
        window.pendingImages && window.pendingImages.length
          ? 'Product was successfully uploaded. You can add more products while images being uploaded!'
          : 'Product was successfully uploaded.';

      localStorage.setItem('condSetting', itemCondition);
      localStorage.setItem('marketSetting', marketList);
      const productId = result._id;

      const pending = localStorage.getItem(`pending-${tempId}`);
      if (pending) {
        // Case 1: Image upload is done it's pending to call processModel endpoint
        sendProcessingModel({ productId, tempId });
      } else {
        // Case 2: Image upload is not done yet, we set the value of productId for images side to find and use to trigger ML
        localStorage.setItem(tempId, `${result._id}`);
      }
      this.baseState['itemCondition'] = itemCondition;
      this.baseState['marketPlaces'] = marketPlaces;
      this.baseState['hasMarketPlaces'] = hasMarketPlaces;
      this.baseState['delist'] = delist;
      this.baseState['rates'] = rates;
      this.baseState['bal'] = bal;
      this.baseState['savedCards'] = savedCards;
      this.baseState['isDescriptionShowOnList'] = isDescriptionShowOnList;
      this.baseState['tempId'] = nanoid();
      this.baseState['isUserABetaUser'] = this.state.isUserABetaUser;
      this.baseState['processML'] = this.getLocalMlOption();

      this.setState({
        ...this.baseState,
        images: _.cloneDeep(imagesSchema),
        submitted: true,
      });
      SuccessToaster.fire({
        title: message,
      });
      this.props.history.push('/basic');
    }
  };

  handleSubmit = (e) => {
    const { website, websites, username, password } = this.state;
    e.preventDefault();
    if (website !== '' && username !== '' && password !== '') {
      if (
        this.state.marketPlaces.filter((val) => val.Name === website).length > 0
      ) {
        return Confirmer.fire(`The ${website} website is already been added.`);
      }
      this.setState({ loading: true });

      Axios.post('/password', {
        website: website,
        username: username,
        password: password,
      })
        .then(() => {
          const sites = websites.filter((o) => o !== website);
          const obj = [
            ...this.state.marketPlaces,
            { website, Status: false, username: username },
          ];
          this.setState({
            loading: false,
            marketPlaces: obj,
            hasMarketPlaces: true,
            username: '',
            password: '',
            website: '',
            websites: [...sites],
            showModal: !!sites.length,
          });
          SuccessToaster.fire({ title: `${website} Added` });
          return;
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    } else {
      ErrorToaster.fire({ text: 'Complete The Product Details.' });
    }
  };

  onChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'classifier') {
      this.setState({ processML: checked });
      this.setLocalMlOption(checked);
    }
    _.set(this, name, value);
  };
  handleChange = async (event, image, index) => {
    const { images } = this.state;

    try {
      if (!event.target.files[0].type.includes('image')) {
        ErrorToaster.fire({
          text: 'select valid image.',
        });
        return false;
      }
      this.setState({ isSubmitting: true, changed: true, uploaded: true });

      const isDefault = index === 0;

      image.img = URL.createObjectURL(event.target.files[0]);
      const data = await this.uploadImage(event.target.files[0], isDefault);
      image.id = data.id;
      this.setState({ images });

      this.setState({ isSubmitting: false });
    } catch (error) {
      console.log(error);
    }
  };

  handleBulkUpload = async (e) => {
    const { images } = this.state;
    let iterator = 0;
    let hasExtra = [];
    const files = [...e.target.files].filter((s) => {
      const isImage = s.type.includes('image');
      if (!isImage) {
        hasExtra.push(true);
      }
      iterator++;
      return isImage;
    });

    let idx = images.findIndex((image) => image.img === '' || !image.img);
    idx = idx === -1 ? 0 : idx;

    const targets = [];
    const length = files.length + idx;
    const finalLength = 12 - idx;
    let count = length > 12 ? finalLength : files.length;

    if (hasExtra.length) {
      ErrorToaster.fire({
        text: 'select only valid images.',
      });
    }

    if (hasExtra.length === iterator) {
      return false;
    }
    this.setState({ isSubmitting: true, changed: true, uploaded: true });
    if (length > 12) {
      ErrorToaster.fire({
        icon: 'warning',
        text: 'Maximum files to be uploaded is 12! uploading only first 12 images',
      });
    }
    for (let i = 0; i < count; i++) {
      if (idx > -1 && images[i + idx]) {
        images[i + idx].img = URL.createObjectURL(files[i]);
        this.setState({ images });
      }

      if (length > 12) {
        targets.push(files[i]);
      }
    }
    const params = length > 12 ? targets : files;
    // if index equals zero that means that the first image is the default and should use photoroom
    const response = await this.uploadImages(params, idx === 0);
    response.map((data, i) => {
      images[i + idx].id = data.id;
    });
    this.setState({ images: [...images] });
    this.setState({ isSubmitting: false });
  };

  handleChangesTemplate = (e) => {
    this.setTemplate(e.target.value);
  };

  setTemplate = (id) => {
    this.setState({ templateId: id });
    const fields = [
      'category',
      'quantity',
      'sku',
      'brand',
      'model',
      'waist',
      'inseam',
      'price',
      'costOfGoods',
      'rise',
      'weightLB',
      'weightOZ',
      'note',
      'condition_name',
      'shortDescription',
      'color',
    ];
    const extraFields = [
      'ebayCategoryField',
      'extraDescriptions',
      'lotSize',
      'gender',
      'mrp',
      'msrp',
      'upc',
      'zipcode',
      'title',
      'packageLength',
      'packageWidth',
      'packageHeight',
      'categorySecondary',
      'storeCategoryOne',
      'storeCategoryTwo',
      'givingWorksCharityID',
      'givingWorksCharityName',
      'givingWorksDonationPercentage',
      'ebayConditionDescription',
      'ebayCampaign',
      'extraMeasures',
      'extraDescriptions',
      'ebayCategoryField',
      'domesticShippingCost',
      'internationalShippingCost',
      'domesticShippingEachAdditional',
      'domesticShippingSurcharge',
      'internationalShippingEachAdditional',
      'internationalShippingSurcharge',
      'domesticShippingFreeShippingActive',
      'internationalShippingFreeShippingActive',
      'otherCosts',
      'profit',
      'promotedListingActive',
      'promotedListingPercentage',
      'listingDuration',
      'listingFormatType',
      'domesticShippingService',
      'internationalShippingService',
      'keywords',
      'customDesc',
    ];

    const ebayRelatedFields = [
      'ebayCategoryID',
      'ebayDomesticShippingService',
      'ebayInternationalShippingService',
    ];

    const { templates } = this.state;
    templates.forEach((template) => {
      if (template._id == id) {
        template = template.data;
        fields.forEach((field) => {
          if (template[field] !== undefined) {
            if (field == 'condition_name') {
              this.setState({ itemCondition: template.condition_name });
            } else if (field == 'shortDescription') {
              let decodedString = decodeURIComponent(template[field]);
              this.setState({ [field]: decodedString });
            } else {
              this.setState({ [field]: template[field] });
            }
          } else {
            if (field == 'condition_name') {
              this.setState({ itemCondition: 'New' });
            } else {
              this.setState({ [field]: '' });
            }
          }
        });
        let extraFieldData = {};
        extraFields.forEach((field) => {
          if (template[field] !== undefined) {
            extraFieldData[field] = template[field];
          }
        });

        const ebayRelatedFieldsData = {};
        ebayRelatedFields.forEach((field) => {
          ebayRelatedFieldsData[field] = template['ebay'][field];
        });
        this.setState({ ebay: ebayRelatedFieldsData });
        this.setState({ extraFieldsTemplate: extraFieldData });
      }
    });

    if (id == '') {
      fields.forEach((field) => {
        if (field == 'condition_name') {
          this.setState({ itemCondition: 'New' });
        } else {
          this.setState({ [field]: '' });
        }
      });
      this.setState({ extraFieldsTemplate: {} });
    }
  };

  removeImg = ({ id, idx }) => {
    const { images } = this.state;
    if (id) {
      for (let i = 0; i < images.length; i++) {
        if (images[i].id === id) {
          images[i].img = '';
          images[i].id = undefined;
        }
      }
    } else if (typeof idx !== 'undefined' && images[idx]) {
      trigger('image:remove', { data: { id: images[idx].id } });
      images[idx].img = '';
      images[idx].id = undefined;
    }
    this.setState({
      images,
      isdefaultImageBGRemove: false,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  updatePayment = async (amount, stripeId) => {
    const { cid } = this.state;
    let body = { customer_id: cid, amount: amount, stripeId: stripeId };
    this.setState({ open: false });
    await Axios.post('/payment/payment', body)
      .then(({ data }) => {
        if (data.success) {
          SuccessToaster.fire(data.msg);
          window.open('/basic', '_self');
        } else {
          ErrorToaster.fire({ text: 'A Credit Card Is Not Added' });
          window.open('/subscription', '_self');
        }
      })
      .catch((err) => console.log(err));
  };

  setLocalMlOption = (option) => {
    localStorage.setItem('process-ml', !!option);
  };

  getLocalMlOption = () => {
    let option = localStorage.getItem('process-ml');
    return option === 'true';
  };

  handleMarketplaceChange = (e, website, username) => {
    const { checked } = e.target;
    var { marketPlaces, delist } = this.state;

    if (website.toLowerCase() == 'ebay') {
      marketPlaces = marketPlaces.map((mp) => {
        if (mp.website.toLowerCase() == 'ebay') {
          return { ...mp, Status: checked && mp.username == username };
        }
        return mp;
      });
    } else {
      const idx = marketPlaces.findIndex(
        (mp) => mp.website.toLowerCase() == website.toLowerCase()
      );

      if (idx !== -1) {
        marketPlaces[idx].Status = checked;
      }
    }
    const activeMarketPlaces = marketPlaces.filter((mp) => mp.Status).length;
    this.setState({
      marketPlaces,
      delistDisabled: activeMarketPlaces <= 1,
      delist: delist && activeMarketPlaces > 1,
    });
  };

  handleMarketPlacePopUp = (e) => {
    e.target.name === 'website' &&
      e.target.value === 'other' &&
      this.setState({ otherFieldActive: true });

    e.target.name !== 'website' &&
      e.target.name !== 'otherwebsite' &&
      this.setState({ [e.target.name]: e.target.value });
    (e.target.name === 'website' || e.target.name === 'otherwebsite') &&
      this.setState({ website: e.target.value });
  };

  async confirm() {
    if (!this.submitted) {
      await Confirmer.fire({
        icon: 'warning',
        text: 'Are you sure you want to leave without submitting? All data will be removed',
        showCancelButton: true,
      });
      return false;
    }
  }

  handleBackgroundRemove = (e) => {
    this.setState({ isdefaultImageBGRemove: e.target.checked });
  };

  render() {
    const {
      website,
      username,
      password,
      images,
      isSubmitting,
      marketPlaces,
      otherFieldActive,
      hasMarketPlaces,
      delist,
      itemCondition,
      sku,
      brand,
      weightLB,
      weightOZ,
      price,
      quantity,
      model,
      note,
      shortDescription,
      waist,
      inseam,
      rise,
      open,
      savedCards,
      showModal,
      websites,
      isDescriptionShowOnList,
      isdefaultImageBGRemove,
      showAdvanceListOption,
      costOfGoods,
    } = this.state;

    return (
      <div className="basicListingContainer">
        <Maintenance />
        <br></br>
        <PaymentAlert
          open={open}
          handleClose={this.handleClose}
          updatePayment={this.updatePayment}
          savedCards={savedCards}
        />

        <Link to="/products/submitted">
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </Link>
        {this.state.loadOnboarding === true ? <OnboardingModal /> : null}
        <div className="row">
          <div
            className={`modal fade bd-example-modal-sm ${
              showModal ? 'show' : ''
            }`}
            style={showModal ? { display: 'block' } : {}}
            id="addTemplateModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="addTemplateModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm  modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title" id="addTemplateModalLabel">
                    Logins
                  </h6>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.setState({ showModal: false })}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="col-12 py-2">
                  <div className="card p-3 mb-3">
                    {this.state.loading ? (
                      <div className="center">
                        <LoadingSpinner asOverlay />
                      </div>
                    ) : null}
                    <h6 className="mb-3 sub-heading">
                      Please enter the logins for each site you want to delist
                      or list to
                    </h6>
                    <select
                      className="form-control body-text"
                      name="website"
                      value={website}
                      onChange={this.handleMarketPlacePopUp}
                    >
                      <option>Select Site</option>
                      {websites.map((o) => (
                        <option key={`select-site-${o}`} value={o}>
                          {o}
                        </option>
                      ))}
                    </select>
                    {otherFieldActive ? (
                      <>
                        <input
                          type="text"
                          placeholder="Website"
                          className="form-control mt-3"
                          name="otherwebsite"
                          value={website}
                          onChange={this.handleMarketPlacePopUp}
                        />
                      </>
                    ) : null}
                    <input
                      type="text"
                      placeholder="Username"
                      className="form-control mt-3 body-text"
                      name="username"
                      value={username}
                      onChange={this.handleMarketPlacePopUp}
                    />
                    <input
                      type="text"
                      placeholder="Password"
                      className="form-control mt-3"
                      name="password"
                      value={password}
                      onChange={this.handleMarketPlacePopUp}
                    />
                    <div className="text-center">
                      <button
                        className="btn btn-danger mt-3 text-center"
                        onClick={this.handleSubmit}
                      >
                        Add
                      </button>
                      <br />
                      <br />
                      <small className="ml-2">
                        You can always add more logins under settings in the
                        future
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ImagesSection
            images={images}
            onImageRemove={this.removeImg}
            assetsURL={assetsURL}
            dragulaDecorator={this.dragulaDecorator}
            imgRef={this.imgRef}
            templates={this.state.templates}
            handleChangesTemplate={this.handleChangesTemplate}
            templateId={this.state.templateId}
            onBulkUpload={this.handleBulkUpload}
            isUserABetaUser={this.state.isUserABetaUser}
            processML={this.state.processML}
            onChange={this.onChange}
            classifier={this.classifier}
            handleChange={this.handleChange}
            isRemoveDefaultImageBG={isdefaultImageBGRemove}
            onBackgroundRemove={this.handleBackgroundRemove}
            showAdvanceOption={showAdvanceListOption}
          />

          <div className="col-sm">
            <div className="rightSideContainer">
              <div className="rs_productDetails">
                <div className="rs_header">Product Details: </div>
                <div
                  className="row"
                  style={{ marginLeft: '2%', marginRight: '2%' }}
                >
                  <div className="col-sm">
                    <select
                      className="custom-select"
                      value={itemCondition}
                      name="itemCondition"
                      onChange={(e) => this.change(e)}
                      required
                    >
                      {ebayConditions.map((condition, index) => (
                        <option
                          key={`ebay-condition-${index}`}
                          value={condition.condition_name}
                          title={condition.condition_description}
                        >
                          {condition.condition_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm">
                    <input
                      type="text"
                      onChange={(e) => this.change(e)}
                      value={sku}
                      name="sku"
                      placeholder="SKU"
                      className="form-control"
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{ marginLeft: '2%', marginRight: '2%' }}
                >
                  <div className="col-sm">
                    <input
                      type="text"
                      onChange={(e) => this.change(e)}
                      value={brand}
                      name="brand"
                      placeholder="Brand"
                      className="form-control"
                    />
                  </div>
                  <div className="col-sm">
                    <input
                      type="text"
                      onChange={(e) => this.change(e)}
                      value={model}
                      name="model"
                      placeholder="Model"
                      className="form-control"
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{ marginLeft: '2%', marginRight: '2%' }}
                >
                  <div className="col-sm">
                    <input
                      type="number"
                      onChange={(e) => this.change(e)}
                      value={quantity === 0 ? '' : quantity}
                      name="quantity"
                      placeholder="Quantity(1)"
                      min="1"
                      className="form-control"
                    />
                  </div>
                  <div className="col-sm">
                    <input
                      type="number"
                      onChange={(e) => this.change(e)}
                      value={price === 0 ? '' : price}
                      name="price"
                      placeholder="Selling Price"
                      min="0"
                      className="form-control"
                    />
                  </div>
                  <div className="col-sm">
                    <input
                      type="number"
                      name="costOfGoods"
                      onChange={(e) => this.change(e)}
                      value={costOfGoods === 0 ? '' : costOfGoods}
                      placeholder="Cost of Goods"
                      className="form-control"
                      min="0"
                      step="0.01"
                    />
                  </div>
                </div>
                <div className="btn-group row_gender">
                  <ButtonGroup
                    category="Unisex"
                    selectedCategory={this.state.category}
                    setCategory={this.setCategory}
                  />
                  <ButtonGroup
                    category="Unisex Kids"
                    selectedCategory={this.state.category}
                    setCategory={this.setCategory}
                  />
                  <ButtonGroup
                    category="Men"
                    selectedCategory={this.state.category}
                    setCategory={this.setCategory}
                  />
                  <ButtonGroup
                    category="Women"
                    selectedCategory={this.state.category}
                    setCategory={this.setCategory}
                  />
                  <ButtonGroup
                    category="Girls"
                    selectedCategory={this.state.category}
                    setCategory={this.setCategory}
                  />
                  <ButtonGroup
                    category="Boys"
                    selectedCategory={this.state.category}
                    setCategory={this.setCategory}
                  />
                </div>
                <div
                  className="row"
                  style={{ marginLeft: '2%', marginRight: '2%' }}
                >
                  <div className="col-sm measurement-padding">
                    <input
                      type="text"
                      onChange={(e) => this.change(e)}
                      value={waist === 0 ? '' : waist}
                      name="waist"
                      placeholder="Waist"
                      className="form-control"
                      min="0"
                    />
                  </div>
                  <div className="col-sm measurement-padding">
                    <input
                      type="text"
                      onChange={(e) => this.change(e)}
                      value={inseam === 0 ? '' : inseam}
                      name="inseam"
                      placeholder="Inseam"
                      className="form-control"
                      min="0"
                    />
                  </div>
                  <div className="col-sm measurement-padding">
                    <input
                      type="text"
                      onChange={(e) => this.change(e)}
                      value={rise === 0 ? '' : rise}
                      name="rise"
                      placeholder="Rise"
                      className="form-control"
                      min="0"
                    />
                  </div>
                  <div className="col-sm measurement-padding">
                    <input
                      type="text"
                      onChange={(e) => this.change(e)}
                      value={weightLB === 0 ? '' : weightLB}
                      name="weightLB"
                      placeholder="W (LB)"
                      min="0"
                      className="form-control"
                    />
                  </div>
                  <div className="col-sm measurement-padding">
                    <input
                      type="text"
                      onChange={(e) => this.change(e)}
                      value={weightOZ === 0 ? '' : weightOZ}
                      name="weightOZ"
                      placeholder="W (OZ)"
                      min="0"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row_rightSide3">
                  <textarea
                    type="text"
                    onChange={(e) => this.change(e)}
                    value={note}
                    rows="4"
                    name="note"
                    placeholder="Notes For VA"
                    className="form-control"
                  ></textarea>
                </div>
                <div className="row_rightSide6">
                  <textarea
                    type="text"
                    onChange={(e) => this.change(e)}
                    value={shortDescription}
                    rows="4"
                    name="shortDescription"
                    placeholder="Short Description"
                    className="form-control"
                  ></textarea>
                  <div className="option_div form-group">
                    <input
                      type="checkbox"
                      name="isDescriptionShowOnList"
                      checked={isDescriptionShowOnList || false}
                      onChange={(e) =>
                        this.setState({
                          isDescriptionShowOnList: !isDescriptionShowOnList,
                        })
                      }
                    ></input>{' '}
                    <label className="form-check-label checkbox-label">
                      Include this text in my listing description
                    </label>
                  </div>
                </div>
              </div>
              <div className="row_rightSide4">
                <div className="marketPlacesTitle">Listing Selection:</div>
                <div className="marketPlacesEntry">
                  {hasMarketPlaces
                    ? marketPlaces.map((o, i) => {
                        return (
                          <div className="form-check" key={`marketplaces-${i}`}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name={`${o.website} ${o.username}`}
                              checked={o.Status || false}
                              onChange={(e) => {
                                this.handleMarketplaceChange(
                                  e,
                                  o.website,
                                  o.username
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="marketPlace"
                            >
                              List on {`${o.website} (${o.username})`}
                            </label>
                          </div>
                        );
                      })
                    : null}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      disabled={this.state.delistDisabled}
                      checked={delist || false}
                      onChange={() => this.setState({ delist: !delist })}
                      id="delist"
                    />
                    <label className="form-check-label" htmlFor="delist">
                      Delist Once Item Is Sold?
                    </label>
                  </div>
                </div>
              </div>

              <div className="row_rightSide5">
                <button
                  type="button"
                  onClick={() => this.onSubmit()}
                  className="btn btn-success controlButtons"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
                <input
                  type="button"
                  value="Cancel"
                  onClick={() => this.props.history.push('/products')}
                  className="btn btn-danger controlButtons"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  uploading: state.uploading,
  extension: state.extension,
  photoroom: state.photoroom,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setPhotoroom: (value) =>
      dispatch({ type: 'SET_PHOTOROOM', photoroom: value }),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BasicForm)
);

import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const ProductChangeTrigger = ({
  products,
  currentPage,
  totalPage,
  rowsPerPage,
  prodStatus,
}) => {
  const dispatch = useDispatch();

  const updateProducts = useCallback(
    (items, curPage, tot, _rowsPerPage, _prodStatus) => {
      dispatch({
        type: 'SET_PRODUCTS',
        products: items,
        currentPage: curPage,
        totalPage: tot,
        rowsPerPage: _rowsPerPage,
        prodStatus: _prodStatus,
      });
    },
    []
  );

  useEffect(() => {
    if (products)
      updateProducts(products, currentPage, totalPage, rowsPerPage, prodStatus);
  }, [
    products,
    updateProducts,
    currentPage,
    totalPage,
    rowsPerPage,
    prodStatus,
  ]);

  return null;
};

ProductChangeTrigger.propTypes = {
  products: PropTypes.array.isRequired,
};

export default ProductChangeTrigger;

import { IconButton } from '@material-ui/core';
import ListViewIcon from '@material-ui/icons/List';
import GridViewIcon from '@material-ui/icons/ViewComfy';
import React, { Component } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import { LISTING_VIEW } from '../../../src/constants';
import Pagination from '../../Components/pagination/Pagination';
import Axios, { assetsURL } from '../../services/Axios';
import {
  Confirmer,
  ErrorToaster,
  SuccessToaster,
} from '../../services/SweetAlerts';
import LoadingSpinner from '../utils/loader';
import SearchCartActionBar from './common/ActionBar';
import SearchCartGrid from './Grid';
import SearchCartList from './List';
import ProductChangeTrigger from './ProductChangeTrigger';
import './searchcartmin.css';

const $ = window.$;
const DEFAULT_PAGE_SIZE_LIST = 10;
const DEFAULT_PAGE_SIZE_GRID = 10;

const shopifyCategories = [
  'Apparel & Accessories > Shoes',
  'Media > Music & Sound Recordings > Music CDs',
  'Media > Books > Audiobooks',
  'Sporting Goods > Outdoor Recreation > Cycling > Bicycles',
];

class Searchcart extends Component {
  constructor() {
    super();
    this.state = {
      products: [],
      rates: {},
      search: '',
      searchedProducts: [],
      bal: 0,
      others: [],
      othersstate: [],
      loading: false,
      datesort: false,
      counter: 2,
      page: 1,
      rowsPerPage: DEFAULT_PAGE_SIZE_LIST,
      clientdetails: [],
      totalPage: '',
      filteredProducts: [],
      pop_open: false,
      inventoryCount: 0,
      draftCount: 0,
      approvedDraftCount: 0,
      submittedCount: 0,
      prodStatus: 'submitted',
      showCrosslistedProducts: false,
      crosslistedproducts: [],
      isCrossListedProductsFetched: false,
      crossListedCount: 0,
      productData: [],
      // design
      listingView: LISTING_VIEW.LIST,
    };
    this.csvLinkEl = React.createRef();
  }

  componentDidMount = () => {
    const prodStatus = this.props.match.params.prodStatus;
    this.setState({
      prodStatus,
      loading: true,
    });

    Axios.get('/payment/rates')
      .then((res) => {
        this.setState({ rates: res.data });
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({ prodStatus: 'submitted' }, () => {
      this.getProducts();
    });

    Axios.get('/clientdetails')
      .then(({ data }) => {
        this.setState({ bal: data.balance, clientdetails: data });
      })
      .catch((error) => {
        console.log(error);
      });

    this.getCrossListedProducts();
  };

  handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      sessionStorage.removeItem('scrollPosition');
    }
  };

  downloadProduct = async () => {
    this.setState({ loading: true });
    try {
      const pdata = await this.getAllProducts();
      let finalData = [];
      this.setState({ loading: false });
      let i = 0;
      Object.entries(pdata).forEach(([key, value]) => {
        Object.entries(value).forEach(([subkey, subvalue]) => {
          if ('brand_image' in subvalue) {
            Object.entries(subvalue).forEach(([imagekey, imagevalue]) => {
              finalData[i] = imagevalue[0];
              i++;
            });
          } else {
            finalData[i] = subvalue;
            i++;
          }
        });
      });
      this.setState({ productData: finalData }, () => {
        setTimeout(() => {
          this.csvLinkEl.current.link.click();
        });
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      const alertString = error?.response?.data?.err
        ? error.response.data.err
        : 'There is issue while exporting product';

      return ErrorToaster.fire({
        title: alertString,
        timer: 3000,
      });
    }
  };
  getAllProducts = async () => {
    const res = await Axios.get(`/product/type/all?getFieldsForShopify=true`);
    const prodArr = res.data.data.map((product) => {
      let handle = product.title
        ? product.title.replace(' ', '_').toLowerCase()
        : '';
      let extraDescriptions = [],
        ebayCategoryField = [],
        prodNewArr = {};
      let option1Name = '',
        option1Value = '',
        option2Name = '',
        option2Value = '',
        option3Name = '',
        option3Value = '',
        categoryName = '',
        defaultImage = '',
        standardized_product_type = '';

      if (
        typeof product.shortDescription !== 'undefined' &&
        product.shortDescription
      ) {
        product.shortDescription = decodeURIComponent(product.shortDescription);
      } else {
        product.shortDescription = '';
      }

      if (
        typeof product.images !== 'undefined' &&
        product.images.default_image &&
        typeof product.images.default_image !== 'undefined' &&
        product.images.default_image != null
      ) {
        defaultImage = assetsURL + product.images.default_image;
      }
      if (typeof product.images !== 'undefined') {
        Object.entries(product.images).forEach(([key, value]) => {
          if (key != 'default_image') {
            prodNewArr[key] = [
              { handle: handle, default_Image: assetsURL + value },
            ];
          }
        });
      }

      if (product.ebayCategoryField) {
        ebayCategoryField = JSON.parse(product.ebayCategoryField);
        categoryName = ebayCategoryField['categoryName'];

        shopifyCategories.forEach((shopifyCategory) => {
          if (
            shopifyCategory.indexOf(ebayCategoryField['categoryName']) !== -1
          ) {
            standardized_product_type = shopifyCategory;
          }
        });
      }

      if (product.extraDescriptions) {
        extraDescriptions = JSON.parse(product.extraDescriptions);
        extraDescriptions.forEach((description) => {
          if (
            description.aspectRequired &&
            description.aspectUsage === 'RECOMMENDED'
          ) {
            if (option1Name == '' && option1Value == '') {
              option1Name = description.key;
              option1Value = description.value;
              return;
            } else if (option2Name == '' && option2Value == '') {
              option2Name = description.key;
              option2Value = description.value;
              return;
            } else if (option3Name == '' && option3Value == '') {
              option3Name = description.key;
              option3Value = description.value;
              return;
            }
          } else if (
            !description.aspectRequired &&
            description.aspectUsage === 'RECOMMENDED'
          ) {
            if (option1Name == '' && option1Value == '') {
              option1Name = description.key;
              option1Value = description.value;
              return;
            } else if (option2Name == '' && option2Value == '') {
              option2Name = description.key;
              option2Value = description.value;
              return;
            } else if (option3Name == '' && option3Value == '') {
              option3Name = description.key;
              option3Value = description.value;
              return;
            }
          } else if (
            !description.aspectRequired &&
            description.aspectUsage === 'OPTIONAL'
          ) {
            if (option1Name == '' && option1Value == '') {
              option1Name = description.key;
              option1Value = description.value;
              return;
            } else if (option2Name == '' && option2Value == '') {
              option2Name = description.key;
              option2Value = description.value;
              return;
            } else if (option3Name == '' && option3Value == '') {
              option3Name = description.key;
              option3Value = description.value;
              return;
            }
          }
        });
      }
      return [
        {
          ...product,
          handle: handle,
          shortDescription: product.shortDescription,
          extraDescriptions: extraDescriptions,
          option1Name: option1Name,
          option1Value: option1Value,
          option2Name: option2Name,
          option2Value: option2Value,
          option3Name: option3Name,
          option3Value: option3Value,
          ebayCategoryField: categoryName,
          default_Image: defaultImage,
          standardized_product_type: standardized_product_type,
        },
        prodNewArr,
      ];
    });
    return prodArr;
  };

  getProducts = async () => {
    const module = JSON.parse(localStorage.getItem('products'));
    this.setState({
      loading: true,
      showCrosslistedProducts: false,
      rowsPerPage: module && module.size ? module.size : 10,
    });

    Axios.get(`/product/type/${this.state.prodStatus}`, {
      params: {
        page: module && module.page ? module.page : 1,
        size: module && module.size ? module.size : 10,
        search: this.state.search ? this.state.search : '',
      },
    })
      .then(({ data }) => {
        this.setState({
          products:
            data?.data?.filter((status) => status.status !== false) || [],
          totalPage: parseInt(data.pages),
          page: parseInt(data.currPage),
          inventoryCount: data.count.inventoryCount,
          draftCount: data.count.draftCount,
          approvedDraftCount: data.count.approvedDraftCount,
          submittedCount: data.count.submittedCount,
          conflictedDraftCount: data.count.conflictedDraftCount,
        });
        if (
          !data.data ||
          (data.count.inventoryCount === 0 &&
            data.count.draftCount === 0 &&
            data.count.approvedDraftCount === 0 &&
            data.count.submittedCount === 0 &&
            (this.state.search === '' || this.state.search == null))
        ) {
          ErrorToaster.fire({
            title: 'No Products, Please Add One....',
            timer: 2000,
          });
          setTimeout(() => this.props.history.push('/basic'), 2000);
        }
        this.setState({ loading: false });
        this.handleScrollPosition();
      })
      .catch((error) => {
        this.setState({ loading: false });
        ErrorToaster.fire({
          title: 'No Products, Please Add One....',
          timer: 2000,
        });
        setTimeout(() => this.props.history.push('/basic'), 2000);
      });
  };

  duplicateHandler = async (itemId) => {
    try {
      this.setState({ loading: true });
      await Axios.post(`/product/duplicate/${itemId}`, {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      });

      this.getProducts();
      SuccessToaster.fire({
        title: 'Duplicate Product Has Been Created.',
        timer: 3000,
      });
    } catch (error) {
      const alertString = error?.response?.data?.err
        ? error.response.data.err
        : 'Error in duplicating product';

      return ErrorToaster.fire({
        title: alertString,
        timer: 3000,
      });
    }
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ search: value, rowsPerPage: 10, page: 1 }, async () => {
      this.getProducts();
    });
  };

  handleDelete = async (itemId) => {
    const result = await Confirmer.fire({
      text: 'Are you Sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });
    if (result.isConfirmed) {
      const data = { status: false };
      try {
        this.setState({ loading: true });
        await Axios.put(`/product/status/${itemId}`, data, {
          headers: { 'Content-Type': 'application/json' },
        }).catch((error) => {
          console.log(error);
        });
        this.setState({ loading: false });
        this.getProducts();
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleChangePage = async (newPage) => {
    if (newPage > 0 && newPage <= this.state.totalPage) {
      this.setState({ page: newPage }, async () => {
        if (this.state.showCrosslistedProducts) this.getCrossListedProducts();
        else this.getProducts();
      });
    }
    localStorage.setItem(
      'products',
      JSON.stringify({ page: newPage, size: this.state.rowsPerPage })
    );
  };

  handleChangeRowsPerPage = async (val) => {
    localStorage.setItem('products', JSON.stringify({ page: 1, size: val }));
    this.setState({ rowsPerPage: val, page: 1 }, async () => {
      this.getProducts();
    });
  };

  loadProducts = async () => {
    if (this.state.prodStatus == 'crossListedProducts') {
      this.getCrossListedProducts();
      return;
    }

    await Axios.get(`/product/type/${this.state.prodStatus}`, {
      params: {
        page: this.state.page,
        size: this.state.rowsPerPage,
        search: this.state.search,
      },
    })
      .then(({ data }) => {
        this.setState({
          products:
            data?.data?.filter((status) => status.status !== false) || [],
          totalPage: parseInt(data.pages),
          page: parseInt(data.currPage),
          inventoryCount: data.count.inventoryCount,
          draftCount: data.count.draftCount,
          approvedDraftCount: data.count.approvedDraftCount,
          submittedCount: data.count.submittedCount,
          conflictedDraftCount: data.count.conflictedDraftCount,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleCrossListedProducts = () => {
    this.setState({
      showCrosslistedProducts: true,
      prodStatus: 'crossListedProducts',
    });
    if (!this.state.isCrossListedProductsFetched) {
      this.getCrossListedProducts();
    }
  };
  getCrossListedProducts = async () => {
    try {
      this.setState({ loading: true });
      // const {clientid} = this.props.match.params;
      const crosslistedproductResponse = await Axios.get(
        `/product/crosslistedproduct/`,
        {
          params: {
            page: this.state.page,
            size: this.state.rowsPerPage,
            search: this.state.search,
          },
        }
      );

      this.setState({
        crosslistedproducts: crosslistedproductResponse?.data?.products || [],
        isCrossListedProductsFetched: true,
        totalPage: crosslistedproductResponse?.data?.totalPages || 1,
        crossListedCount: crosslistedproductResponse?.data?.totalProducts || 0,
        loading: false,
      });
    } catch (err) {
      this.setState({ loading: false });
      ErrorToaster.fire('Error in getting crosslisted products');
    }
  };

  handleRequestClose() {
    this.setState({ pop_open: false });
  }

  datesort = () => {
    let { products, searchedProducts, filteredProducts } = this.state;
    if (products.length > 0)
      products = products.reverse().map((product, idx) => {
        return product;
      });
    if (searchedProducts.length > 0)
      searchedProducts = searchedProducts.reverse().map((product, idx) => {
        return product;
      });
    if (filteredProducts.length > 0)
      filteredProducts = filteredProducts.reverse().map((product, idx) => {
        return product;
      });
    this.setState({ products, searchedProducts, filteredProducts });
  };
  handleProductFilter = (newState) => {
    localStorage.setItem('products', JSON.stringify({ page: 1, size: 10 }));
    this.setState({ prodStatus: newState }, () => {
      this.getProducts();
    });
  };

  editProduct = (id) => {
    sessionStorage.setItem('scrollPosition', window.pageYOffset);
    this.props.history.push(`/edit/${id}`);
  };

  handleViewListingChange = (value) => {
    this.setState({ listingView: value });
  };

  render() {
    const { uploading } = this.props;
    const {
      products,
      rates,
      search,
      rowsPerPage,
      page,
      totalPage,
      filteredProducts,
      productData,
      listingView,
    } = this.state;
    const newproducts = filteredProducts.length ? filteredProducts : products;

    return (
      <div>
        <div className="cartIt" style={{ minHeight: '75vh' }}>
          <div className="row">
            <ProductChangeTrigger
              products={newproducts}
              currentPage={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
            />

            <div
              className="col-6 d-flex"
              style={uploading ? { paddingTop: '24px' } : {}}
            >
              <DebounceInput
                type="text"
                id="searcheverything"
                className="form-control col-12 ml-2"
                value={search}
                debounceTimeout={300}
                onChange={this.handleSearchChange}
                placeholder="Search"
              />
            </div>
          </div>
          <div
            className="modal fade  bd-example-modal-sm"
            id="addTemplateModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="addTemplateModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm  modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title" id="addTemplateModalLabel">
                    Rates for listing are -
                  </h6>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="col">
                  <br />
                  First site Listing: ${rates.list / 100}
                  <br /> <br />
                  Other site Listing: ${rates.crossList / 100} <br /> <br />
                  Delisting Listing: ${rates.deList / 100} <br /> <br />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="btn btn-secondary d-flex col-2 col-lg-1 ml-auto justify-content-center mr-4 mb-2"
              style={{ position: 'absolute', top: '75px', right: '0px' }}
              onClick={() => $('#addTemplateModal').modal('show')}
            >
              Rates
            </div>
          </div>
          <div className="product__info">
            <SearchCartActionBar
              productStatus={this.state.prodStatus}
              inventoryCount={this.state.inventoryCount}
              draftCount={this.state.draftCount}
              approvedDraftCount={this.state.approvedDraftCount}
              submittedCount={this.state.submittedCount}
              conflictedDraftCount={this.state.conflictedDraftCount}
              csvLinkEl={this.csvLinkEl}
              productData={productData}
              // handlers
              onProductFilter={this.handleProductFilter}
              onDownloadProduct={this.downloadProduct}
            />

            <div>
              <IconButton
                color="secondary"
                style={{ marginRight: 10 }}
                aria-label="Grid View"
                onClick={() =>
                  this.handleViewListingChange(
                    listingView === LISTING_VIEW.LIST
                      ? LISTING_VIEW.GRID
                      : LISTING_VIEW.LIST
                  )
                }
              >
                {listingView === LISTING_VIEW.LIST ? (
                  <GridViewIcon fontSize="large" />
                ) : (
                  <ListViewIcon fontSize="large" />
                )}
              </IconButton>
              <button
                id="btn-cross-listed"
                onClick={this.handleCrossListedProducts}
                className={
                  this.state.showCrosslistedProducts
                    ? 'btn btn btn-success'
                    : 'btn btn-primary'
                }
              >
                {this.state.crossListedCount} - Cross Listed
              </button>
            </div>
          </div>
          {!this.state.loading ? (
            listingView === LISTING_VIEW.LIST ? (
              <SearchCartList
                products={newproducts}
                crossListedProducts={this.state.crosslistedproducts}
                productStatus={this.state.prodStatus}
                showCrossListProducts={this.state.showCrosslistedProducts}
                search={this.state.search}
                rowsPerPage={rowsPerPage}
                page={page}
                // handlers
                onDateSort={this.datesort}
                onEditProduct={this.editProduct}
                onDuplicateProduct={this.duplicateHandler}
                onDeleteProduct={this.handleDelete}
              />
            ) : (
              <SearchCartGrid
                products={newproducts}
                crossListedProducts={this.state.crosslistedproducts}
                productStatus={this.state.prodStatus}
                showCrossListProducts={this.state.showCrosslistedProducts}
                search={this.state.search}
                rowsPerPage={rowsPerPage}
                // handlers
                onDateSort={this.datesort}
                onEditProduct={this.editProduct}
                onDuplicateProduct={this.duplicateHandler}
                onDeleteProduct={this.handleDelete}
              />
            )
          ) : (
            <div className="center">
              <LoadingSpinner asOverlay />
            </div>
          )}
        </div>
        <div className="w-100 mx-auto py-2">
          <Pagination
            curPage={page}
            totalPage={totalPage}
            rowPerPage={rowsPerPage}
            onRowNumberChange={this.handleChangeRowsPerPage}
            previous={this.handleChangePage}
            next={this.handleChangePage}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  uploading: state.uploading,
});

export default connect(mapStateToProps)(Searchcart);

import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  inputFile: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    opacity: 0,
  },
};

const ImageItem = ({
  image,
  index,
  assetsURL,
  onImageClick,
  onImageRemove,
  handleChange,
  isRemoveDefaultImageBG,
}) => {
  return (
    <div className="col-4 col-md-3 px-1" data-index={index} imgkey={image.key}>
      <div className="card mb-3">
        <div className="card-body body-text text-center align-middle d-flex align-items-center justify-content-center p-2 px-1">
          {image.img ? (
            <div className="container p-0 m-0">
              <img
                src={
                  isRemoveDefaultImageBG && index === 0
                    ? `${assetsURL + 'bg_' + image.id}`
                    : image.img && image.img.length
                    ? image.img
                    : image.id && image.id.length
                    ? assetsURL + image.id
                    : undefined
                }
                style={{ width: '100%', height: '90px' }}
                onClick={(event) => onImageClick(event, image)}
                alt=""
              />
              <button
                type="button"
                className="btn2 close mr-auto"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => onImageRemove({ idx: index })}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          ) : (
            <div
              style={{
                width: '80px',
                height: '90px',
                margin: '0px!important',
              }}
              className="d-flex align-items-center justify-content-center"
            >
              <label>
                <div className="fas fa-plus"></div> <br />
                {image.label}
              </label>

              <input
                type="file"
                style={styles.inputFile}
                name={image.key}
                accept="image/*"
                onChange={(event) => handleChange(event, image, index)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ImageItem.propTypes = {
  image: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  assetsURL: PropTypes.string.isRequired,
  onImageClick: PropTypes.func.isRequired,
  onImageRemove: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isdefaultImageBGRemove: PropTypes.bool,
};

export default ImageItem;

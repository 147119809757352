const ImageDialog = ({ src, show, close }) => (
  <div
    className={`modal bd-example-modal-sm`}
    style={{ display: show ? 'block' : 'none' }}
    tabIndex={-1}
    role="dialog"
  >
    <div
      className="modal-dialog modal-lg  modal-dialog-centered"
      role="document"
    >
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" onClick={close}>
              ×
            </span>
          </button>
        </div>

        <img src={src} style={{ height: '500px' }} />
      </div>
    </div>
  </div>
);

export default ImageDialog;

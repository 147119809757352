/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './headermin.css';
import Logo from '../images/hammock_dashboard.svg';
import Axios from '../../services/Axios';
import { logoutHandler } from '../../services/methods';
import { ClientMessagesContext } from '../../ContextProviders/ClientMessagesProvider';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Header = (props) => {
  const history = useHistory();
  const { contextUnreadMessagesCount, setContextClientId } = useContext(
    ClientMessagesContext
  );

  const [rates, setRates] = useState({});
  const [open, setOpen] = useState(false);
  const [clientId, setClientId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [clientMessageSeenCount, setClientMessageSeenCount] = useState(0);

  const [clientUnreadMessageCount, setClientUnreadMessageCount] = useState(0);
  const { totalBalance } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    const mountComponent = async () => {
      if (localStorage.getItem('token')) {
        await Axios.get('/clientdetails/headerinfo')
          .then(({ data }) => {
            if (parseInt(data.balance) < 0.9) setOpen(true);
            setClientId(data._id);
            setContextClientId(data._id);
            setCustomerName(data.firstName);
            setClientMessageSeenCount(data.clientMessageSeenCount);
            localStorage.setItem('cid', data._id);
            setClientId(data._id);
            localStorage.setItem('customerName', data.firstName);
            localStorage.setItem(
              'isSubscribed',
              data.isSubscribed || data.subscribedByAdmin
            );
            if (data.balance) {
              dispatch({
                type: 'UPDATE_TOTALBALANCE',
                totalBalance: data.balance,
              });
            }
          })
          .catch((err) => console.log(err));
      }
    };

    mountComponent();
    setClientMessageSeenCount(contextUnreadMessagesCount);
  }, [contextUnreadMessagesCount, props, setContextClientId]);

  const navigateTo = (to) => {
    history.replace(to);
    if (to === '/messages') {
      window.location.reload();
    }
  };

  const getUnreadMessageCount = async () => {
    if (!clientId) return;
    let totalMessagesMessage = await Axios.get(
      `/messagesNeo/messageunreadtotal/${clientId}/client`
    );
    if (
      totalMessagesMessage.data &&
      totalMessagesMessage.data.totalUnreadMessage.length > 0
    )
      setClientUnreadMessageCount(
        totalMessagesMessage.data.totalUnreadMessage[0].messages
      );
  };

  useEffect(() => {
    getUnreadMessageCount();
  }, [clientId]);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark"
      style={{ backgroundColor: '#102747' }}
    >
      {/* <PaymentAlert
          open={this.state.open}
          handleClose={this.handleClose}
          updatePayment={this.updatePayment}
       />*/}

      <a href="#" onClick={() => navigateTo('/')} className="navbar-brand">
        <img src={Logo} alt="hammock" height="40px" />
      </a>
      <h5 className="ml-4 mt-2">
        <i className="text-white">
          Balance: $ {totalBalance ? totalBalance.toFixed(2) : 0}
        </i>
      </h5>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a
              onClick={() => navigateTo('/basic')}
              className="nav-link"
              style={{ color: 'white', cursor: 'pointer' }}
            >
              <i className="fas fa-hand-holding-usd mr-1"></i>
              Basic Listing
            </a>
          </li>
          <li className="nav-item">
            <a
              onClick={() => navigateTo('/messages')}
              className="nav-link"
              style={{ color: 'white', cursor: 'pointer' }}
            >
              <i className="fas fa-envelope mr-1"></i>
              Messages({clientUnreadMessageCount})
            </a>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: 'white', cursor: 'pointer' }}
            >
              <span style={{ color: 'white' }}>
                Reseller Tools <div className="fas fa-caret-down mr-1"></div>
              </span>
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <a
                style={{ color: '#000', cursor: 'pointer' }}
                className="dropdown-item "
                id="template"
                onClick={() => navigateTo('/templates')}
              >
                Templates
              </a>
              <a
                style={{ color: '#000', cursor: 'pointer' }}
                className="dropdown-item "
                onClick={() => navigateTo('/rule_engine')}
              >
                Rule Engine
              </a>
              <a
                style={{ color: '#000', cursor: 'pointer' }}
                className="dropdown-item "
                onClick={() => navigateTo('/title_rule')}
              >
                Title Rule Engine
              </a>
            </div>
          </li>
          <li className="nav-item">
            <a
              onClick={() => navigateTo('/accounting')}
              className="nav-link"
              style={{ color: 'white', cursor: 'pointer' }}
            >
              <i className="fas fa-file-invoice-dollar mr-1"></i>
              Accounting
            </a>
          </li>
          <li className="nav-item">
            <a
              onClick={() => navigateTo('/setting')}
              className="nav-link"
              style={{ color: 'white', cursor: 'pointer' }}
            >
              <i className="fas fa-user-cog mr-1"></i>
              Setting
            </a>
          </li>
          <li className="nav-item">
            <span
              className="nav-link"
              style={{ color: 'white', cursor: 'pointer' }}
            >
              Hi, {customerName}
            </span>
          </li>
          <li className="nav-item">
            <span
              onClick={logoutHandler}
              style={{ color: '#EF5350', cursor: 'pointer' }}
              className="nav-link"
            >
              <div className="fas fa-sign-out-alt mr-1"></div>
              Logout
            </span>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;

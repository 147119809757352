import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Axios from '../../../services/Axios';
import { ErrorToaster, SuccessToaster } from '../../../services/SweetAlerts';
import Calendly from '../../../Components/Calendly';
import { service_endpoint_urls } from '../../../services/endpoint-detection';

export default function SubscriptionModal(props) {
  const stripePromise = loadStripe(service_endpoint_urls.stripeKey);
  return (
    <Elements stripe={stripePromise}>
      <PaymentModal props={props} />
    </Elements>
  );
}

const PaymentModal = ({ props }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [calendlyFire, setCalendlyFire] = useState(false);
  const [clientDetails, setClientDetails] = useState(false);

  const elements = useElements(),
    stripe = useStripe(),
    { onHide, getSubscriptionDetails, setMessage } = props;

  useEffect(() => {
    const getUserInfo = async () => {
      const clientDetails = await Axios.get('/clientdetails');
      setClientDetails(clientDetails.data);
    };
    getUserInfo();
  }, []);

  const handleSubscription = async () => {
    try {
      setLoading(true);
      const card = elements.getElement(CardElement),
        token = await stripe.createToken(card);
      if (token.error) {
        setLoading(false);
        return ErrorToaster.fire({ title: token.error.message });
      } else {
        const tokenId = token.token.id,
          response = await Axios.post('/subscription/', {
            tokenId,
          });
        const { clientSecret } = response.data;
        if (clientSecret) {
          const confirm = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: card,
            },
          });

          if (confirm.error) {
            setMessage({
              msg: confirm.error.message,
              show: true,
              varient: 'danger',
            });
            // eslint-disable-next-line no-empty
          } else {
          }
        } else {
          SuccessToaster.fire({
            title: 'Subscribed successfully!',
            timer: 2000,
          });
          //success
          setMessage({
            show: true,
            msg: 'You are subscribed successfully. Please reload to check the balance.',
            variant: 'success',
          });
          clientDetails.showOnboarding ? setCalendlyFire(true) : null;
        }
        getSubscriptionDetails();
        onHide();
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setMessage({
        msg:
          err?.response?.data?.err ||
          err?.response?.data?.err?.raw?.message ||
          err?.response?.data?.msg ||
          'Something went wrong',
        show: true,
        varient: 'danger',
      });
    }
  };

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://app.sprintful.com/widget/v1.js');
    head.appendChild(script);
  });

  const closeModel = () => {
    setCalendlyFire(false);
    setTimeout(() => history.go(0), 2000);
  };

  const { subscriptionModalDetails } = props;
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Get Subscribed To Hammoq's Services.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This is a subscription of $99 a month. $99 will be added to your
            current account balance for service costs. If the balance is not
            used, it will not be refunded or usable the next month. Any extra
            balance added other than the initial $99 will stay in your account
            and will be accessible the next month.
          </p>
          <hr />
          <CardElement />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <div>
            <small>
              <i>{subscriptionModalDetails.msg}</i>
            </small>
          </div>
          <button
            className="btn btn-primary"
            onClick={handleSubscription}
            disabled={loading}
          >
            {loading ? 'Subscribing' : 'Pay and Subscribe'}
          </button>{' '}
        </Modal.Footer>
      </Modal>
      <Calendly showModel={calendlyFire} closeModel={closeModel} />
    </>
  );
};

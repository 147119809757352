module.exports = [
  {
    condition_ID: '1000',
    condition_name: 'New',
    condition_description:
      'A brand-new, unused, unopened, unworn, undamaged item. Most categories support this condition (as long as condition is an applicable concept).',
  },
  {
    condition_ID: '1500',
    condition_name: 'New other',
    condition_description:
      'A brand-new new, unused item with no signs of wear. Packaging may be missing or opened. The item may be a factory second or have defects.',
  },
  {
    condition_ID: '1750',
    condition_name: 'New with defects',
    condition_description:
      'A brand-new, unused, and unworn item. The item may have cosmetic defects, and/or may contain mismarked tags (e.g., incorrect size tags from the manufacturer). Packaging may be missing or opened. The item may be a new factory second or irregular.',
  },
  {
    condition_ID: '2000',
    condition_name: 'Certified - Refurbished',
    condition_description:
      'The item is in a pristine, like-new condition. It has been professionally inspected, cleaned, and refurbished by the manufacturer or a manufacturer-approved vendor to meet manufacturer specifications. The item will be in new packaging with original or new accessories.',
  },
  {
    condition_ID: '2010',
    condition_name: 'Excellent - Refurbished',
    condition_description:
      "An item that is in like-new condition, backed by a one year warranty. It has been professionally refurbished, inspected, and cleaned to excellent condition by qualified sellers. The item includes original or new accessories and will come in new generic packaging. See the seller's listing for full details.",
  },
  {
    condition_ID: '2020',
    condition_name: 'Very Good - Refurbished',
    condition_description:
      "An item that shows minimal wear and is backed by a one year warranty. It is fully functional and has been professionally refurbished, inspected, and cleaned to very good condition by qualified sellers. The item includes original or new accessories and will come in new generic packaging. See the seller's listing for full details.",
  },
  {
    condition_ID: '2030',
    condition_name: 'Good - Refurbished',
    condition_description:
      "An item that shows moderate wear and is backed by a one year warranty. It is fully functional and has been professionally refurbished, inspected, and cleaned to good condition by qualified sellers. The item includes original or new accessories and will come in a new generic packaging. See the seller's listing for full details.",
  },
  {
    condition_ID: '2500',
    condition_name: 'Seller refurbished',
    condition_description:
      'An item that has been restored to working order by the eBay seller or a third party. This means the item was inspected, cleaned, and repaired to full working order and is in excellent condition. This item may or may not be in original packaging.',
  },
  {
    condition_ID: '2750',
    condition_name: 'Like New',
    condition_description:
      "An item that looks as if it was just taken out of shrink wrap. No visible wear, and all facets of the item are flawless and intact. See the seller's listing for full details and description of any imperfections.",
  },
  {
    condition_ID: '3000',
    condition_name: 'Used',
    condition_description:
      'An item that has been used previously. The item may have some signs of cosmetic wear, but is fully operational and functions as intended. This item may be a floor model or store return that has been used. Most categories support this condition (as long as condition is an applicable concept).',
  },
  {
    condition_ID: '4000',
    condition_name: 'Very Good',
    condition_description:
      '	An item that is used but still in very good condition. No obvious damage to the cover or jewel case. No missing or damaged pages or liner notes. The instructions (if applicable) are included in the box. May have very minimal identifying marks on the inside cover. Very minimal wear and tear.',
  },
  {
    condition_ID: '5000',
    condition_name: 'Good',
    condition_description:
      'An item in used but good condition. May have minor external damage including scuffs, scratches, or cracks but no holes or tears. For books, liner notes, or instructions, the majority of pages have minimal damage or markings and no missing pages.',
  },
  {
    condition_ID: '6000',
    condition_name: 'Acceptable',
    condition_description:
      'An item with obvious or significant wear, but still operational. For books, liner notes, or instructions, the item may have some damage to the cover but the integrity is still intact. Instructions and/or box may be missing. For books, possible writing in margins, etc., but no missing pages or anything that would compromise the legibility or understanding of the text.',
  },
  {
    condition_ID: '7000',
    condition_name: 'For parts or not working',
    condition_description:
      'An item that does not function as intended and is not fully operational. This includes items that are defective in ways that render them difficult to use, items that require service or repair, or items missing essential components. Supported in categories where parts or non-working items are of interest to people who repair or collect related items.',
  },
];

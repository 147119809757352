import React, { useEffect } from 'react';
import './Messages.css';
import MessagesContentArea from './MessagesContentArea';

//Chat Image Gallery
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

const Messages = (props) => {
  const [currentImage, setCurrentImage] = React.useState('');

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImage('');
  };

  const images = [
    {
      original: currentImage,
      thumbnail: currentImage,
    },
  ];

  useEffect(() => {
    if (currentImage) {
      handleClickOpen();
    }
  }, [currentImage]);
  return (
    <div className="client_messages_container">
      <MessagesContentArea setCurrentImage={setCurrentImage} />
      <>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Image Preview'}
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: 20 }}>
              <div
                style={{
                  width: '450px',
                  height: '100%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <ImageGallery
                  items={images}
                  showPlayButton={false}
                  showThumbnails={false}
                />
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </>
    </div>
  );
};

export default Messages;

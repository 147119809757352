import React, { Component } from 'react';
import Axios from '../../services/Axios';
import './signupmin.css';
import Logo from '../../Components/images/logo.png';

import { Link } from 'react-router-dom';
import legalDocuments from '../ClientLegal';
import {
  firstNameValidation,
  lastNameValidation,
  emailValidation,
  phoneNoValidation,
  passwordValidation,
  confirmPasswordValidation,
} from './validation';
import { Confirmer, ErrorToaster } from '../../services/SweetAlerts';

class Signup extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phoneno: '',
      password: '',
      confirmPassword: '',
      referralCode: '',
      isSubmitting: false,
      privacyagreement: false,
      termsofservice: false,
      isList350Items: 0,
      validation: {
        firstName: {
          isValid: false,
          msg: 'First Name is required.',
        },
        lastName: {
          isValid: false,
          msg: 'Last Name is required.',
        },
        email: {
          isValid: false,
          msg: 'Email is required.',
        },
        phoneno: {
          isValid: false,
          msg: 'Phone No is required.',
        },
        password: {
          isValid: false,
          msg: 'Password is required.',
        },
        confirmPassword: {
          isValid: false,
          msg: 'Confirm Password is required.',
        },
      },
    };
    this.onChangeValue = this.onChangeValue.bind(this);
  }
  onChangeValue = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  isFormDataValidCheck = () => {
    let { validation, privacyagreement, termsofservice } = this.state;
    return (
      validation.firstName.isValid &&
      validation.lastName.isValid &&
      validation.email.isValid &&
      validation.phoneno.isValid &&
      validation.password.isValid &&
      validation.confirmPassword.isValid &&
      privacyagreement &&
      termsofservice
    );
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    let fieldValidation = {
      isValid: false,
      msg: '',
    };
    let { validation } = this.state;
    switch (name) {
      case 'firstName':
        fieldValidation = firstNameValidation(value);
        break;
      case 'lastName':
        fieldValidation = lastNameValidation(value);
        break;
      case 'email':
        fieldValidation = emailValidation(value);
        break;
      case 'phoneno':
        fieldValidation = phoneNoValidation(value);
        break;
      case 'password':
        fieldValidation = passwordValidation(value);
        break;
      case 'confirmPassword':
        fieldValidation = confirmPasswordValidation(this.state.password, value);
        break;
      default:
        break;
    }
    validation = { ...validation, [name]: fieldValidation };
    this.setState({
      [name]: value,
      validation,
    });
  };

  handleToggleCheckbox = (e) => {
    const { name } = e.target;
    this.setState({ [name]: !this.state[name] });
  };

  handleSubmit = (e) => {
    this.setState({ isSubmitting: true });
    e.preventDefault();
    const {
      privacyagreement,
      termsofservice,
      password,
      confirmPassword,
      firstName,
      lastName,
      email,
      phoneno,
      referralCode,
      isList350Items,
    } = this.state;

    const body = {
      privacyagreement,
      termsofservice,
      password,
      confirmPassword,
      firstName,
      lastName,
      email,
      phoneno,
      referralCode,
      isList350Items,
    };
    Axios.post('/signup', body)
      .then((response) => {
        if (response.err) {
          this.setState({ isSubmitting: false });
          ErrorToaster.fire({ text: response.err });
        }
        localStorage.setItem('token', response.data.token);
        window.open('/subscription', '_self');
        this.setState({ isSubmitting: false });
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        if (error.response.data.err) {
          this.setState({ isSubmitting: false });
          return ErrorToaster.fire({ text: error.response.data.err });
        }
        Confirmer.fire({ text: "Something went wrong. Can't Sign Up" });
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      phoneno,
      password,
      confirmPassword,
      isSubmitting,
      referralCode,
      validation,
    } = this.state;
    const isFormDataValid = this.isFormDataValidCheck();
    return (
      <div className="row col-lg-5 m-auto">
        <form className="formIt">
          <div className="col-lg-10 m-auto">
            <div className="row">
              <img
                src={Logo}
                className="m-auto col-8"
                alt="Alt PlaceHolder"
              ></img>
            </div>
          </div>

          <div className="my-1">
            <Link to="/login">
              <i className="fa fa-arrow-left mt-3" aria-hidden="true">
                {' '}
              </i>
              &nbsp; Login
            </Link>
          </div>

          <div className="my-1 d-flex justify-content-center">
            <h5>Create a new Hammoq account.</h5>
          </div>

          <div className="mb-1">
            <label htmlFor="firstname" className="required">
              FIRST NAME:
            </label>
            <input
              type="text"
              name="firstName"
              value={firstName}
              onChange={this.handleChange}
              className="form-control"
              required
            ></input>
            <small className="validation_error_msg">
              {!validation.firstName.isValid ? (
                validation.firstName.msg
              ) : (
                <br />
              )}
            </small>
          </div>

          <div className="mb-1">
            <label htmlFor="lastname" className="required">
              LAST NAME:
            </label>
            <input
              type="text"
              name="lastName"
              value={lastName}
              onChange={this.handleChange}
              className="form-control"
              required
            ></input>
            <small className="validation_error_msg">
              {!validation.lastName.isValid ? validation.lastName.msg : <br />}
            </small>
          </div>

          <div className="mb-1">
            <label htmlFor="email" className="required">
              EMAIL:
            </label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={this.handleChange}
              className="form-control"
              required
            ></input>
            <small className="validation_error_msg">
              {!validation.email.isValid ? validation.email.msg : <br />}
            </small>
          </div>

          <div className="mb-1">
            <label htmlFor="state" className="required">
              PHONE.NO:
            </label>
            <input
              type="text"
              name="phoneno"
              value={phoneno}
              onChange={this.handleChange}
              className="form-control"
              required
            ></input>

            <small className="validation_error_msg">
              {!validation.phoneno.isValid ? validation.phoneno.msg : <br />}
            </small>
          </div>

          <div className="mb-1">
            <label htmlFor="password" className="required">
              PASSWORD:
            </label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={this.handleChange}
              className="form-control"
              required
            ></input>
            <small className="validation_error_msg">
              {!validation.password.isValid ? validation.password.msg : <br />}
            </small>
          </div>

          <div className="mb-1">
            <label htmlFor="passwordconfirmation" className="required">
              PASSWORD CONFIRMATION:
            </label>
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={this.handleChange}
              className="form-control"
              required
            ></input>
            <small className="validation_error_msg">
              {!validation.confirmPassword.isValid ? (
                validation.confirmPassword.msg
              ) : (
                <br />
              )}
            </small>
          </div>

          <div className="mb-1">
            <label htmlFor="referralCode" className="optional">
              REFERRAL CODE:
            </label>
            <input
              type="text"
              name="referralCode"
              value={referralCode}
              onChange={this.handleChange}
              className="form-control "
              required
            ></input>
            <small className="validation_error_msg">
              <br />
            </small>
          </div>

          <div className="mb-1">
            <a href={legalDocuments.TERMSOFSERVICE} target="_blank">
              Terms Of Service
            </a>
            <div className="form-check">
              <input
                type="checkbox"
                name="termsofservice"
                onChange={this.handleToggleCheckbox}
                className="form-check-input"
              ></input>
              <label htmlFor="accept-terms" className="form-check-label">
                I HAVE READ AND AGREE TO THE TERMS OF SERVICE.
              </label>
            </div>
            <small className="validation_error_msg">
              {!this.state.termsofservice ? (
                'Terms Of Service is required'
              ) : (
                <br />
              )}
            </small>
          </div>

          <div className="mb-1">
            <a href={legalDocuments.PRIVACYAGREEMENT} target="_blank">
              Privacy Agreement
            </a>
            <div className="form-check">
              <input
                type="checkbox"
                name="privacyagreement"
                onChange={this.handleToggleCheckbox}
                className="form-check-input"
              ></input>
              <label htmlFor="accept-terms" className="form-check-label ">
                I HAVE READ AND AGREE TO THE PRIVACY AGREEMENT.
              </label>
            </div>
            <small className="validation_error_msg">
              {!this.state.privacyagreement ? (
                'Privacy Agreement is required'
              ) : (
                <br />
              )}
            </small>
          </div>
          <div className="mb-1">
            Will you list 350 items per month or more?
            <div className="form-check" onChange={this.onChangeValue}>
              <input
                type="radio"
                name="isList350Items"
                value="1"
                className="form-check-input"
              ></input>
              <label
                htmlFor="isList350Items"
                className="form-check-label list-items-label "
              >
                YES
              </label>
              <input
                type="radio"
                name="isList350Items"
                value="0"
                className="form-check-input"
                defaultChecked
              ></input>
              <label
                htmlFor="isList350Items"
                className="form-check-label list-items-label"
              >
                NO
              </label>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            {isSubmitting ? (
              <button className="btn btn-success btn-lg" disabled>
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                />
                Singing Up...
              </button>
            ) : (
              <button
                className="btn btn-primary "
                onClick={this.handleSubmit}
                disabled={!isFormDataValid}
              >
                Sign Up
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default Signup;

import Axios from 'axios';

// Check if user is beta user
// Returns the promise of the request

export const checkBetaUser = async () => {
  // create a promise for the axios request
  const promise = Axios.get('/betaUser');
  // using .then, create a new promise which extracts the data
  const promiseRound = await promise.then(
    (response) => response.data?.isBetaUser
  );

  // return it
  return promiseRound;
};

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Axios from '../../../services/Axios';
import { nanoid } from 'nanoid';

export default function CancelSubscriptionModal(props) {
  const {
    cancelSubscriptionModalDetails: { subscriptionId },
    getSubscriptionDetails,
    onHide,
    setMessage,
  } = props;
  const reasons = useSelector((o) => o.CANCELLATION_REASONS);
  const [reason, setReason] = useState();
  const [suggestion, setSuggestion] = useState();

  const handleCancelAtPeriodEndClick = async () => {
    try {
      const res = await Axios.post('/subscription/cancel', {
        subscriptionId,
        reason,
        suggestion,
      });
      onHide();
      getSubscriptionDetails();
      setMessage({
        variant: 'success',
        show: true,
        msg: 'The request to cancel your subscription has been made successfully.',
      });
    } catch (err) {
      setMessage({
        varient: 'danger',
        show: true,
        msg: 'There is a error when cancelling the subscription, please try again after 2 minutes or please contact customer support.',
      });
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cancel Active Subscription
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex">
          <Col md={6} xs={6} lg={6}>
            <Row style={{ marginBottom: '0px' }}>
              <Col md={12} xs={12} lg={12}>
                <h6>
                  If you proceed, you will lose all of your listings on Hammoq..
                </h6>
                <p>
                  Can you please help us understand why you are canceling so
                  that we may do better in the future?
                </p>
              </Col>
              <Col md={10} xs={10} lg={10}>
                {Object.keys(reasons).map((reason) => {
                  return (
                    <Form.Group
                      key={nanoid(3)}
                      style={{ marginBottom: '0.5rem' }}
                    >
                      <Form.Check
                        type="radio"
                        name="feedback"
                        value={reason}
                        label={reasons[reason]}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </Form.Group>
                  );
                })}

                <br></br>
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={12} lg={12}>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="suggestions"
                  placeholder="  Write some suggestions...."
                  style={{ width: '80%', height: 50 }}
                  onChange={(e) => setSuggestion(e.target.value)}
                />
              </Col>
            </Row>
          </Col>
          <Col
            md={6}
            xs={6}
            lg={6}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              flexDirection: 'row',
              alignContent: 'space-around',
              flexWrap: 'nowrap',
            }}
          >
            <Row>
              <h6>Are you sure you wish to continue?</h6>
              <Row>
                <button className=" btn btn-outline-success" onClick={onHide}>
                  No, Do Not Cancel My Active Subscription.
                </button>
              </Row>
              <Row>
                <button
                  className=" btn btn-outline-danger"
                  onClick={handleCancelAtPeriodEndClick}
                >
                  Yes, Please Cancel My Active Subscription.
                </button>
              </Row>
            </Row>
          </Col>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <small>
          <ul>
            <li>
              By clicking on <b>Yes, Cancel My Active Subscription</b>, your
              subscription will expire at the end of your current period and you
              will not be charged again.
            </li>
            <li>
              Once canceled, you will not be able to use any of our services,
              but you are still able to resubscribe later.
            </li>
          </ul>
        </small>
      </Modal.Footer>
    </Modal>
  );
}

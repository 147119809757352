import { Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { BsLink45Deg } from 'react-icons/bs';

interface CrossListProductCardProps {
  product: any;
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const CrossListProductCard: React.FC<CrossListProductCardProps> = ({
  product,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {product.title}
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="body2" component="p">
              <strong>Crosslisted From</strong>
              <br />
              {product.crossListedFrom.name}
              <a
                href={product.crossListedFrom.url}
                target="_blank"
                rel="noreferrer"
              >
                <BsLink45Deg />
              </a>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" component="p">
              <strong>Crosslisted To</strong>
              <br />
              {product.crossListedTo.map((to: any, index: number) => {
                return (
                  <li key={`to ${index}`}>
                    {to.name}
                    <a href={to.url} target="_blank" rel="noreferrer">
                      <BsLink45Deg />
                    </a>
                  </li>
                );
              })}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CrossListProductCard;

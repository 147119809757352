const defaults = {
  PHOTO_ROOM_API_KEY: '16f344d03a02685bd846cf1bd917a4d5814598a1',
  PHOTO_ROOM_URL: 'https://sdk.photoroom.com/v1/segment',
  ML_OPTIONS: [
    {
      Name: 'ApparelClassifier',
      Status: false,
      type: 'shirt',
      Question: 'Is this upload a shirt?',
    },
    {
      Name: 'ShoeClassifier',
      Status: false,
      type: 'shoe',
      Question: 'Is this upload a shoe?',
    },
  ],
};

export default defaults;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import './index.css';
import './services/images';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initializeDataDog, logger } from './datadog';

initializeDataDog({
  clientToken: 'pub69992f31c107f85cad2352a14add3176',
  applicationId: '2c6611b8-d7af-449e-ab8c-91a00a53ca7d',
  serviceName: 'hammoq-app-client',
  appVersion: process.env.REACT_APP_SHA || 'no-version-local',
  trackSessionAcrossSubdomains: true,
});

logger.info('application starting up');

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { assetsThumbnailURL, assetsURL } from '../../../../services/Axios';
import StatusColumn from '../../common/StatusColumn';
import { getProductTitle } from '../../helpers';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    height: 330,
    position: 'relative',
  },
  media: {
    height: 140,
    width: '100%',
    objectFit: 'contain',
  },
  content: {
    '& p': { margin: '1px !important' },
    paddingTop: 10,
    overflowY: 'auto',
    height: 80,
    marginBottom: 20,
  },
  cutText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  actions: {
    bottom: 5,
    position: 'absolute',
    height: 25,
    width: '100%',
  },
  buttons: {
    fontSize: 12,
  },
});

interface SearchCartProductCardProps {
  product: any;

  onEditProduct: (id: string) => void;
  onDuplicateProduct: (id: string) => void;
  onDeleteProduct: (id: string) => void;
}

const SearchCartProductCard: React.FC<SearchCartProductCardProps> = ({
  product,
  onEditProduct,
  onDuplicateProduct,
  onDeleteProduct,
}) => {
  const classes = useStyles();

  product.images = product.images || {};
  const thumbnailPath = product.images.default_image
    ? product.images.default_image.substring(
        0,
        product.images.default_image.indexOf(':')
      ) !== 'http' &&
      product.images.default_image.substring(
        0,
        product.images.default_image.indexOf(':')
      ) !== 'https'
      ? assetsThumbnailURL + product.images.default_image
      : product.images.default_image
    : '';
  const fullSizePath = product.images.default_image
    ? product.images.default_image.substring(
        0,
        product.images.default_image.indexOf(':')
      ) !== 'http' &&
      product.images.default_image.substring(
        0,
        product.images.default_image.indexOf(':')
      ) !== 'https'
      ? assetsURL + product.images.default_image
      : product.images.default_image
    : '';

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <img
          className={classes.media}
          // image={thumbnailPath}
          src={thumbnailPath}
          onError={(e: any) => {
            if (e.target?.src !== fullSizePath) {
              e.target.src = fullSizePath;
            }
            e.target.onerror = null;
          }}
          alt={getProductTitle(product)}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            component="h2"
            className={classes.cutText}
          >
            {getProductTitle(product) ? getProductTitle(product) : '-'}
          </Typography>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="body2" color="textSecondary" component="p">
                Price : <strong> {product.price} </strong>
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" color="textSecondary" component="p">
                SKU : {product.sku ? product.sku : '-'}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.content}>
            <StatusColumn product={product} />
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.actions}>
        <Box
          width="100%"
          justifyContent="space-between"
          display="flex"
          alignItems="center"
        >
          <Box>
            <Button
              size="small"
              className={classes.buttons}
              color="secondary"
              onClick={() => onDeleteProduct(product._id)}
            >
              Delete
            </Button>
            <Button
              size="small"
              className={classes.buttons}
              color="primary"
              onClick={() => onDuplicateProduct(product._id)}
            >
              Duplicate
            </Button>
          </Box>
          <Box>
            <Button
              size="small"
              className={classes.buttons}
              color="primary"
              onClick={() => onEditProduct(product._id)}
            >
              Edit/List
            </Button>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};

export default SearchCartProductCard;

const validResponse = {
  isValid: true,
  msg: '',
};

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const phoneNoRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
const pwdRegex = /(^[ ]{1,})|([ ]{1,}$)/gm;

const firstNameValidation = (firstName) => {
  if (firstName.trim() == '') {
    return {
      isValid: false,
      msg: 'First Name is required',
    };
  }
  return validResponse;
};

const lastNameValidation = (lastName) => {
  if (lastName.trim() == '') {
    return {
      isValid: false,
      msg: 'Last Name is required',
    };
  }
  return validResponse;
};

const emailValidation = (email) => {
  if (email == '') {
    return {
      isValid: false,
      msg: 'Email is required',
    };
  }
  if (!emailRegex.test(String(email).toLowerCase())) {
    return {
      isValid: false,
      msg: 'Invalid email',
    };
  }
  return validResponse;
};

const phoneNoValidation = (phoneNo) => {
  if (phoneNo == '') {
    return {
      isValid: false,
      msg: 'Phone No is required',
    };
  }
  if (!phoneNoRegex.test(String(phoneNo))) {
    return {
      isValid: false,
      msg: 'Invalid Phone No',
    };
  }
  return validResponse;
};

const passwordValidation = (password) => {
  if (password == '') {
    return {
      isValid: false,
      msg: 'Password is required',
    };
  } else {
    if (pwdRegex.test(String(password).toLowerCase())) {
      return {
        isValid: false,
        msg: 'Only spaces/Outer Spaces are not allowed, Please give valid password',
      };
    }
  }
  if (String(password).length < 8) {
    return {
      isValid: false,
      msg: 'Required length is 8 character',
    };
  }
  return validResponse;
};
const confirmPasswordValidation = (password, confirmPassword) => {
  if (confirmPassword == '') {
    return {
      isValid: false,
      msg: 'Please Enter Your Password Again.',
    };
  }
  if (String(password) !== String(confirmPassword)) {
    return {
      isValid: false,
      msg: 'Your Password Entered Does Not Match.',
    };
  }
  return validResponse;
};

module.exports = {
  firstNameValidation,
  lastNameValidation,
  emailValidation,
  phoneNoValidation,
  passwordValidation,
  confirmPasswordValidation,
};

import React, { useState, useEffect } from 'react';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import Axios from '../../../../services/Axios';
import SweetAlerts from '../../../../services/SweetAlerts';
import './GmailAccount.css';
import Button from '@material-ui/core/Button';

const GmailAccount = (props) => {
  const [loading, setLoading] = useState(false);
  const [accountConnected, setAccountConnected] = useState(false);
  const [connectedEmail, setConnectedEmail] = useState(null);

  const handleLogin = async (googleData) => {
    if (googleData.error) {
      return false;
    }

    setLoading(true);
    await Axios.post('/gmail/auth', {
      code: googleData.code,
      clientId: localStorage.getItem('cid'),
    })
      .then(({ data }) => {
        if (!data.success) {
          setAccountConnected(false);
        } else {
          setConnectedEmail(data.email);
          setAccountConnected(true);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err.data);
        SweetAlerts.ErrorToaster.fire({
          icon: 'error',
          title:
            'Please check if the fowarding address is set according to instructions',
        });
        setLoading(false);
      });
  };

  const logout = async (data) => {
    setLoading(true);
    Axios.delete('/gmail/auth')
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setAccountConnected(false);
          setConnectedEmail(null);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const checkAccess = async () => {
    setLoading(true);
    Axios.get('/gmail/auth')
      .then((response) => {
        const data = response.data;
        if (!data.success) {
          setAccountConnected(false);
        } else {
          setConnectedEmail(data.email);
          setAccountConnected(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    checkAccess();
  }, []);

  return (
    <div className="GmailAccount">
      <span className="title">
        Connect Gmail account to set filters for poshmark and other market
        places
      </span>

      <div className="account">
        <span>
          {loading
            ? 'Fetching account'
            : accountConnected
            ? connectedEmail
            : 'No Account Connected'}
        </span>
        {loading ? (
          'loading..'
        ) : accountConnected ? (
          <button className="delete" onClick={logout}>
            Disconnect
          </button>
        ) : (
          <GoogleLogin
            clientId="793269235910-8ip2m6vuva4f180um0gnq1d9hcltmmti.apps.googleusercontent.com"
            buttonText="Connect Gmail"
            scope="https://www.googleapis.com/auth/gmail.settings.basic https://www.googleapis.com/auth/gmail.settings.sharing"
            accessType="offline"
            onSuccess={handleLogin}
            onFailure={handleLogin}
            responseType="code"
            prompt="consent"
          />
        )}
      </div>
    </div>
  );
};

export default GmailAccount;

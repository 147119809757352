import React, { Component } from 'react';
import './settingsmin.css';
import PaymentAlert from '../../Components/paymentAlert/PaymentAlert';
import { Link } from 'react-router-dom';
import Axios from '../../services/Axios';
import TextField from '@material-ui/core/TextField';
import { Confirmer } from 'src/services/SweetAlerts';

class settings extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      email: '',
      open: false,
      client_id: '',
      savedCards: [],
    };
  }

  logoutHandler = () => {
    localStorage.removeItem('token');
    window.open('/login', '_self');
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  componentDidMount(prevProps) {
    Axios.get('/clientdetails')
      .then(({ data }) => {
        this.setState({
          username: data.firstName,
          client_id: data._id,
          email: data.email,
          savedCards: data.savedCards,
        });
      })
      .catch((err) => console.log(err));
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  updatePayment = async (amount, stripeId) => {
    let body = {
      customer_id: this.state.client_id,
      amount: amount,
      stripeId: stripeId,
    };
    this.setState({ open: false });
    await Axios.post('/payment/payment', body)
      .then(({ data }) => {
        if (data.success) {
          Confirmer.fire(data.msg);
          window.open('/basic', '_self');
        } else {
          Confirmer.fire({ text: 'Credit Card is Not added' });
          window.open('/subscription', '_self');
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { username, email } = this.state;

    return (
      <div className="settingsIt2">
        <PaymentAlert
          open={this.state.open}
          handleClose={this.handleClose}
          updatePayment={this.updatePayment}
          savedCards={this.state.savedCards}
        />
        <div className="row" id="profilephoto2">
          <TextField
            variant="outlined"
            type="text"
            className="profileDataField"
            placeholder="Username"
            disabled
            name="username"
            value={username}
            InputProps={{
              startAdornment: (
                <i className="fa fa-user col-2 mt-2" aria-hidden="true"></i>
              ),
            }}
          ></TextField>
          <TextField
            variant="outlined"
            type="text"
            className="profileDataField"
            placeholder="email"
            disabled
            name="email"
            value={email}
            InputProps={{
              startAdornment: (
                <i className="fa fa-envelope col-2" aria-hidden="true"></i>
              ),
            }}
          ></TextField>
        </div>

        {/* <Link to="/transactions" className="row" id="settingsBackground2">
					<div className="col-3">
						<i className="fa fa-user-circle fa-2x" id=""></i>
					</div>
					<div className="col-6">
						<h6 className="paddingfornames">Transactions</h6>
					</div>
					<div className="col-3">
						<i
							className="fa fa-arrow-circle-right fa-2x t backgroundarrow"
							aria-hidden="true"></i>
					</div>
				</Link> */}

        <Link to="/subscription" className="row" id="settingsBackground2">
          <div className="col-3">
            <i className="fa fa-sync fa-2x" id=""></i>
          </div>
          <div className="col-6">
            <h6 className="paddingfornames">Subscriptions</h6>
          </div>
          <div className="col-3">
            <i
              className="fa fa-arrow-circle-right fa-2x t backgroundarrow"
              aria-hidden="true"
            ></i>
          </div>
        </Link>

        {/* <Link to="/addpayment" className="row" id="settingsBackground2">
					<div className="col-3">
						<i className="fa fa-credit-card fa-2x " id=""></i>
					</div>
					<div className="col-6">
						<h6 className="paddingfornames">Add Credit Card</h6>
					</div>
					<div className="col-3">
						<i className="fa fa-arrow-circle-right fa-2x" aria-hidden="true"></i>
					</div>
				</Link> */}

        {/* <Link to="/addpayment" className="row" id="settingsBackground2">
					<div className="col-3">
						<i className="fa fa-money fa-2x "></i>
					</div>
					<div className="col-6">
						<h6 className="paddingfornames">Add Payment</h6>
					</div>
					<div className="col-3">
						<i className="fa fa-arrow-circle-right fa-2x" aria-hidden="true"></i>
					</div>
				</Link> */}

        <Link to="/resetpassword" className="row" id="settingsBackground2">
          <div className="col-3">
            <i className="fa fa-lock fa-2x " id=""></i>
          </div>
          <div className="col-6 p-0">
            <h6 className="paddingfornames">Change Password</h6>
          </div>
          <div className="col-3">
            <i
              className="fa fa-arrow-circle-right fa-2x"
              aria-hidden="true"
            ></i>
          </div>
        </Link>

        <Link to="/passwords" className="row" id="settingsBackground2">
          <div className="col-3">
            <i
              className="fa fa-envelope-o  fa-2x "
              id=""
              aria-hidden="true"
            ></i>
          </div>
          <div className="col-6">
            <h6 className="paddingfornames">Logins</h6>
          </div>
          <div className="col-3">
            <i
              className="fa fa-arrow-circle-right fa-2x"
              aria-hidden="true"
            ></i>
          </div>
        </Link>
        {/* 
        <Link to="/config" className="row" id="settingsBackground2">
          <div className="col-3">
            <i
              className="fa fa-cogs  fa-2x "
              id=""
              aria-hidden="true"
            ></i>
          </div>
         <div className="col-6">
            <h6 className="paddingfornames">Config</h6>
          </div>
          <div className="col-3">
            <i className="fa fa-arrow-circle-right fa-2x" aria-hidden="true"></i>
          </div>
        </Link>
*/}
        <Link
          to="/accounts/ebayAccounts"
          className="row"
          id="settingsBackground2"
        >
          <div className="col-3">
            <i className="fa fa-at  fa-2x " id="" aria-hidden="true"></i>
          </div>
          <div className="col-6">
            <h6 className="paddingfornames">Accounts</h6>
          </div>
          <div className="col-3">
            <i
              className="fa fa-arrow-circle-right fa-2x"
              aria-hidden="true"
            ></i>
          </div>
        </Link>

        <Link to="/listingsettings" className="row" id="settingsBackground2">
          <div className="col-3">
            <i className="fa fa-list  fa-2x " id="" aria-hidden="true"></i>
          </div>
          <div className="col-6">
            <h6 className="paddingfornames">Listing Settings</h6>
          </div>
          <div className="col-3">
            <i
              className="fa fa-arrow-circle-right fa-2x"
              aria-hidden="true"
            ></i>
          </div>
        </Link>

        <Link to="/rule_engine" className="row" id="settingsBackground2">
          <div className="col-3">
            <i className="fa fa-viruses  fa-2x " id="" aria-hidden="true"></i>
          </div>
          <div className="col-6">
            <h6 className="paddingfornames">Rule Engine</h6>
          </div>
          <div className="col-3">
            <i
              className="fa fa-arrow-circle-right fa-2x"
              aria-hidden="true"
            ></i>
          </div>
        </Link>
        <Link to="/title_rule" className="row" id="settingsBackground2">
          <div className="col-3">
            <i className="fa fa-quote-left fa-2x " id="" aria-hidden="true"></i>
          </div>
          <div className="col-6">
            <h6 className="paddingfornames">Title Rule</h6>
          </div>
          <div className="col-3">
            <i
              className="fa fa-arrow-circle-right fa-2x"
              aria-hidden="true"
            ></i>
          </div>
        </Link>

        <button
          className="row"
          id="settingsBackground2"
          onClick={this.logoutHandler}
        >
          <div className="col-12">
            <h6 className="paddingfornames ">LOGOUT</h6>
          </div>
        </button>
      </div>
    );
  }
}
export default settings;

import React, { Component } from 'react';
import './forgotpassword.css';
import Axios from '../../services/Axios';
import {
  Confirmer,
  ErrorToaster,
  SuccessToaster,
} from '../../services/SweetAlerts';
class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      code: '',
      isSubmitting: false,
      loginError: false,
      codesent: false,
      codecheck: false,
      passup: false,
      displaycode: false,
      displaypass: false,
    };
  }

  handleSubmit = async (e) => {
    this.setState({ isSubmitting: true });
    e.preventDefault();

    if (
      this.state.codesent === true &&
      this.state.codecheck === true &&
      this.state.passup === false
    ) {
      if (this.state.password === '') {
        ErrorToaster.fire({ text: 'Fill in the new password' });
      } else {
        await Axios.post(
          '/forgotpassword/update',
          {
            email: this.state.email,
            newPassword: this.state.password,
          },
          { headers: { 'x-access-token': localStorage.getItem('token') } }
        )
          .then(({ data }) => {
            if (data.assert === true) {
              Confirmer.fire({
                text: data.data,
              });
              this.setState({ passup: true });
              window.open('/products/submitted', '_self');
            } else {
              ErrorToaster.fire({
                text: 'Password updation failed! Try again',
              });
            }
          })
          .catch(() => {
            ErrorToaster.fire({ text: 'Password updation failed! Try again' });
          });
      }
    }

    if (this.state.codesent === true && this.state.codecheck === false) {
      if (this.state.code === '') {
        Confirmer.fire({ text: 'Fill in the Code' });
      } else {
        await Axios.get(
          `/forgotpassword/confirmCode/${this.state.code}/${this.state.email}`
        )
          .then(({ data }) => {
            if (data.assert === true) {
              localStorage.setItem('token', data.token);
              Confirmer.fire({
                text: 'Code verified : Change new password or goto Products page',
              });
              this.setState({ codecheck: true, displaypass: true });
            } else {
              ErrorToaster.fire({ text: 'Wrong code' });
            }
          })
          .catch((err) => {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.err
            ) {
              ErrorToaster.fire({ text: err.response.data.err });
            }
            ErrorToaster.fire({ text: 'Please check entered code.' });
          });
      }
    }

    if (this.state.codesent === false) {
      await Axios.get(`/forgotpassword/${this.state.email}`)
        .then(({ data }) => {
          if (data.assert === true) {
            SuccessToaster.fire({ text: data.data });
            this.setState({ codesent: true, displaycode: true });
          } else {
            Confirmer.fire({ text: 'Invalid Email-id' });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.err) {
            ErrorToaster.fire({ text: err.response.data.err });
          }
          ErrorToaster.fire({ text: 'Please check entered mail.' });
        });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const {
      email,
      password,
      //isSubmitting,
      loginError,
      code,
      displaycode,
      displaypass,
    } = this.state;
    return (
      <div>
        <div className="row login-page">
          <div className="col-12 col-lg-6 m-auto">
            <div className="row mt-5">
              <div className="col-8 m-auto resp-col">
                <div
                  className="card border-0 login-container shadow rounded-1 mt-5 h-100"
                  style={{ backgroundColor: '#4682B4' }}
                >
                  <form className="card-body p-4" onSubmit={this.handleSubmit}>
                    <h5
                      className="card-title text-center h3 py-2"
                      style={{ color: 'white' }}
                    >
                      Forgot Password
                    </h5>
                    {loginError && (
                      <div className="col d-flex justify-content-center">
                        <div className="row">
                          <i
                            className="fa fa-times-circle text-danger"
                            aria-hidden="true"
                            style={{ width: '50' }}
                          ></i>
                          <div style={{ color: 'red' }} className="ml-1">
                            <h5>Incorrect Email or Password</h5>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="input-group input-group-lg mt-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text bg-white border-0 rounded-1-left pr-1"
                          id="inputGroup-sizing-lg"
                        >
                          <div
                            className="fas fa-envelope"
                            aria-hidden="true"
                          ></div>
                        </span>
                      </div>
                      <input
                        type="email"
                        className="form-control border-0 rounded-1-right pl-3 bg-white"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-lg"
                        placeholder="Email"
                        value={email}
                        name="email"
                        onChange={this.handleChange}
                        required
                      ></input>
                    </div>

                    <div
                      id="u"
                      className={
                        displaycode
                          ? 'input-group input-group-lg mt-3 show'
                          : 'input-group input-group-lg mt-3 hide'
                      }
                    >
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text bg-white border-0 rounded-1-left pr-1"
                          id="inputGroup-sizing-lg"
                        >
                          <div
                            className="fas fa-shield-alt"
                            aria-hidden="true"
                          ></div>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control border-0 rounded-1-right pl-3 bg-white"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-lg"
                        placeholder="code"
                        value={code}
                        name="code"
                        onChange={this.handleChange}
                      ></input>
                    </div>

                    <div
                      className={
                        displaypass
                          ? 'input-group input-group-lg mt-3 show'
                          : 'input-group input-group-lg mt-3 hide'
                      }
                    >
                      <div className="input-group-prepend mb-2">
                        <span
                          className="input-group-text bg-white border-0 rounded-1-left pr-1"
                          id="inputGroup-sizing-lg"
                        >
                          <div className="fas fa-lock" aria-hidden="true"></div>
                        </span>
                      </div>
                      <input
                        type="password"
                        className="form-control border-0 rounded-1-right mb-2 align-middle pl-3 bg-white"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-lg"
                        placeholder="New Password"
                        name="password"
                        onChange={this.handleChange}
                        value={password}
                      ></input>
                    </div>

                    {displaycode ? (
                      displaypass ? (
                        <button
                          className="btn btn-warning col-12 col-lg-8 text-white btn-block  m-auto py-2"
                          type="submit"
                        >
                          Update password
                        </button>
                      ) : (
                        <button
                          className="btn btn-success col-12 col-lg-8 text-white btn-block  m-auto py-2"
                          type="submit"
                        >
                          Confirm Code
                        </button>
                      )
                    ) : (
                      <button
                        className="btn btn-info col-5 col-lg-3 text-white btn-block  m-auto py-2"
                        type="submit"
                      >
                        Send
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;

import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const BetaUSerArea = ({ processML, onChange }) => {
  return (
    <div className="row_rightSide4">
      <FormControlLabel
        className="col-6 marketPlacesTitle"
        label="Process Machine Learning"
        control={
          <Checkbox
            name="classifier"
            checked={!!processML}
            onChange={onChange}
          />
        }
      />
    </div>
  );
};

BetaUSerArea.propTypes = {
  processML: PropTypes.bool,
  onChange: PropTypes.func,
};

export default BetaUSerArea;
